import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../shared/services/auth.service";
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { PaymentService } from "../shared/services/payment.service"

import { AngularFireFunctions } from '@angular/fire/functions'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  confirmation: any;
  email: any;
  replyId: string;
  str: any;
  phone: any;
  firstName:'';
  lastName:'';
  userName:'';
  userPassword:'';
  ts: Date;
  created: any;
  now: number;
  state: any;
  

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, 
    private modalService: NgbModal,
    private paymentSvc: PaymentService,
    private functions: AngularFireFunctions,
    private http: HttpClient
  ) { 
    this.ts = new Date();
    // this.now is the timeStamp
    this.created = this.ts.toDateString();
    // this.created is the pretty time
    this.now = Date.now();
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
    console.log( this.str);
  }

  ngOnInit() {
  }
 
  async textMe(userName, userPassword, firstName, lastName, phone){
    this.email = userName;
    console.log(this.email)
    this.phone = phone;
    const fun = this.functions.httpsCallable('textNewRegistrant');
    this.confirmation =  await fun({phone:phone, firstName:firstName, lastName:lastName, email:this.email, class:this.str }).toPromise()
   console.log(this.confirmation)
   Swal.fire(
    'Thank you!',
    'Keep an eye out.  Sending you your things now!',
    'success'
  )
 
    this.email = '';
  }
  certify(email, state){
    const db = firebase.firestore();

    
    this.afAuth.authState.subscribe(user => {
      if(user) {
        if(this.str === 'DMS'){
          const citiesRef = db.collection('DMS');
          citiesRef.where('email', '==', email).get()
          .then(snapshot => {
            if (!snapshot.empty) {
                for (let i = 0; i < snapshot.size; i++) {
                    const data = snapshot.docs[i].data();
                    const id = snapshot.docs[i].id
                    const attended = data.attended
                    
                   if (attended === false){
                    const firePath = db.collection('DMS').doc(id);
                   firePath.update({
                      attended: true,
                      dateAttended: this.now 
                    })

                   }}}})
          let addClass = db.collection('users/').doc(user.uid).update({
            entranceLink: this.str,
            class: this.str,
            certifiedAgentId: user.uid,
          certified: true,
          attended: true,
          LO: 'open',
          dateCreated: this.now,
          state: state 
            
          })
     .then();{
        this.router.navigate(['profile/:'+ user.uid]); 
      }
        }
        else {
        let addClass = db.collection('users/').doc(user.uid).update({
          entranceLink: this.str,
          class: this.str,
          certifiedAgentId: user.uid,
        certified: true,
        attended: true,
        LO: 'open',
        dateCreated: this.now,
        state: state  
       
          
        })
   .then();{
      this.router.navigate(['profile/:'+ user.uid]); 
    }
        }
  
    }})
  }
  skip(){
    this.router.navigate(['skip']); 
  }
}
