import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../shared/services/auth.service";
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {debounceTime} from 'rxjs/operators';
import { PaymentService } from "../shared/services/payment.service"
import { source } from '../../../node_modules_nope/@angular-devkit/schematics/src';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  private _success = new Subject<string>();
  userId: string;
  staticAlertClosed = false;
  successMessage: string;
  closeResult: string;
  itemDoc: Observable<unknown>;
  item: any;
  replyId: string;
  str: any;
  itemRef: Observable<unknown>;
  passedId: string;
  info: any;
  image: any;
  location: any;
  address: any;
  time: any;
  name: any;
  title: any;
  city: any;
  login: boolean;
  userRef: Observable<unknown>;
  userInfo: any;
  stripeId: any;
  userStripe: any;
  id: unknown;
  stripeRef: any;
  tokenId: any;
  isCE: any;
  pretty: any;
 
  


  constructor(
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, 
    private modalService: NgbModal,
    private paymentSvc: PaymentService
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) console.log(user.uid)})
  
    this.login = false;
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.itemRef = this.afs.doc('notes/'+ this.str).valueChanges();
    this.passedId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.passedId);
    this.itemDoc = this.afs.doc('event-requests/'+ this.str).valueChanges();
    this.item = this.itemDoc.subscribe((datas) => { 
      this.info = datas,
      this.isCE = this.info.isCE,
      this.pretty = this.info.date
    })
    this.afAuth.authState.subscribe(user => {
      if (user) {
      this.userRef = this.afs.doc('users/'+ user.uid).valueChanges();
      this.userInfo = this.userRef.subscribe((result) =>{
        this.name = result
       

      })
      this.userRef = this.afs.doc('users/'+ user.uid).valueChanges();
    this.userStripe = this.userRef.subscribe((stripe) =>{
      this.stripeId = stripe
     
    })
      }
    })
  
  }

  ngOnInit(): void {
   
    this.loadStripe();
    setTimeout(() => this.staticAlertClosed = true, 8000);

    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }

  public changeSuccessMessage() {
    this._success.next(`${new Date()} - Message successfully changed.`);
  }
  loggingIn(){
    this.login = true;
  }
  loggedIn(){
    this.login = false;
  }
  registered(){
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.modalService.dismissAll();
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000
        })
        Toast.fire({
          type: 'success',
          title: 'You are Registered!  Dont forget to update your profile later!'
        })
      }
    
      
    })
   
    
  }
  open(content, type) {
    if (type === 'sm') {
        console.log('aici');
        this.modalService.open(content, { size: 'sm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } else {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
}

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
}

loadStripe() {
     
  if(!window.document.getElementById('stripe-script')) {
    var s = window.document.createElement("script");
    s.id = "stripe-script";
    s.type = "text/javascript";
    s.src = "https://checkout.stripe.com/checkout.js";
    window.document.body.appendChild(s);
  }
}

pay(amount) {    
  

  this.afAuth.authState.subscribe(user => {
    if (user) {
    console.log(this.stripeId.stripe_customer_id);
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_4JDXPmP6s7jzvwYSe4gzzp5L00XjXjXS8M',
      image: 'assets/img/certified-agent.png',
      locale: 'auto',
     
      source: source => {
     
        console.log(source)
        // this.paymentSvc.createSource(source)
         this.paymentSvc.processPayment(source)
      },

      // token: token => {
      //   this.paymentSvc.createSource(token)
      //   this.paymentSvc.processPayment(token)
        
      // }
      
      //   const source = token.id
      //   console.log(source)
        // this.paymentSvc.createSource(source)
        // this.paymentSvc.processPayment(token, amount, source)
        // this.stripeRef = this.afs.collection('stripe_customers/'+ user.uid +'/tokens').add({
        //   token: token,
        //   amount: amount * 100
        // })
     
   
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
      //   console.log(token)
      //  return token
      // },
   

    });
    handler.open({
      name: 'theVAloan.org',
      description: 'C.E. Credit Course',
      amount: amount * 100
    });
  }})
   
  
  
  

 


}
 send(token, amount){

 }


}
