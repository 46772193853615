import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { LandingComponent } from './landing/landing.component';
import { EventComponent } from './event/event.component';
import { CalculatorComponent } from './calculator/calculator.component';

import { SearchComponent } from './search/search.component';
import { ForumComponent } from './forum/forum.component';
import { AuthService } from './shared/services/auth.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CertifiedAgentComponent } from './certified-agent/certified-agent.component';
import { FullCalendarModule} from '@fullcalendar/angular';
import { EventsComponent} from './components/basicelements/events/events.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ProfileSetupComponent } from './profile-setup/profile-setup.component';
import { CalcComponent } from './calc/calc.component';
import { InputComponent } from './calc/input/input.component';
import { DashboardComponent } from './calc/dashboard/dashboard.component';
import { StatementComponent } from './calc/statement/statement.component';
import { BarChartComponent } from './calc/bar-chart/bar-chart.component';
import { GridAppComponent } from './calc/grid-app/grid-app.component';

import { DataService } from './calc/data-service';

import { NumericTextBoxModule, SliderModule } from '@syncfusion/ej2-ng-inputs';
import { RadioButtonModule } from '@syncfusion/ej2-ng-buttons';
import { AccumulationChartModule, ChartModule } from '@syncfusion/ej2-ng-charts';
import { DatePickerModule } from '@syncfusion/ej2-ng-calendars';
import { GridModule } from '@syncfusion/ej2-ng-grids';
import { AgentsComponent } from './agents/agents.component';
import { MediaComponent } from './media/media.component';
import { RequestEventComponent } from './request-event/request-event.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NotesComponent } from './notes/notes.component';
import { TestDuhonComponent } from './test-duhon/test-duhon.component';
import { FAQComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { TypographyComponent } from './components/typography/typography.component';
import { AgmCoreModule } from '@agm/core';
import { GeocodeService } from './shared/services/geocode.service';
import { MapsComponent } from './maps/maps.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { SellersFAQComponent } from './sellers-faq/sellers-faq.component';
import { BuyersFAQComponent } from './buyers-faq/buyers-faq.component';
import { CompletedComponent } from './completed/completed.component';
import { RSVPComponent } from './rsvp/rsvp.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions'
import { HttpClientModule } from '@angular/common/http';
import { OnlineComponent } from './online/online.component';
import { PaymentsComponent } from './payments/payments.component';

import { SignaturePadModule } from 'ngx-signaturepad';
import { RegisterComponent } from './register/register.component';
import {NgxPrintModule} from 'ngx-print';
import { CertificateComponent } from './certificate/certificate.component';
import { CertComponent } from './cert/cert.component';
import { ReferralComponent } from './referral/referral.component';
import { EmailComponent } from './email/email.component';
import { SkipComponent } from './skip/skip.component';
import { WelcomeOrgComponent } from './welcome-org/welcome-org.component';

import { PrepropertiesComponent } from './preproperties/preproperties.component';
import { ReferComponent } from './refer/refer.component';






@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
       
        LoginComponent, 
        ProfileComponent,
        LandingComponent,
        EventComponent,
        CalculatorComponent,
       EventsComponent,
        SearchComponent,
        ForumComponent,
        CertifiedAgentComponent,
        CalendarComponent,
        ProfileSetupComponent,
        CalcComponent,
        InputComponent,
        DashboardComponent,
        StatementComponent,
        BarChartComponent,
        GridAppComponent,
        AgentsComponent,
        MediaComponent,
        RequestEventComponent,
        NotesComponent,
        TestDuhonComponent,
        FAQComponent,
        PrivacyComponent,
        TermsofserviceComponent,
        TypographyComponent, 
        MapsComponent, 
        SellersFAQComponent, 
        BuyersFAQComponent,
         CompletedComponent, 
         RSVPComponent, OnlineComponent, PaymentsComponent, RegisterComponent, CertificateComponent, CertComponent, ReferralComponent, EmailComponent, SkipComponent, WelcomeOrgComponent, PrepropertiesComponent, ReferComponent,  
        
         
     
       
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        ComponentsModule,
        ExamplesModule,
        SweetAlert2Module.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
      
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        FullCalendarModule,
        NumericTextBoxModule,
        SliderModule,
        RadioButtonModule,
        AccumulationChartModule,
        DatePickerModule,
        ChartModule,
        GridModule,
        ArchwizardModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBnwgkjE1KaVqjLpbh7-khNssBO4iNSvEM'
          }),
          NgSelectModule, 
          FormsModule,
          PdfViewerModule,
          AutocompleteLibModule,
          AngularFireFunctionsModule,
          HttpClientModule,
          ReactiveFormsModule,
          SignaturePadModule,
          NgxPrintModule,
   
        
     
         
     
       
     

    ],
    exports: [
        TypographyComponent,
        MapsComponent
    ],
    providers: [DataService, GeocodeService],
    bootstrap: [AppComponent]
})
export class AppModule { }
