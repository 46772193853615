import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../shared/services/auth.service";
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {debounceTime} from 'rxjs/operators';
import { PaymentService } from "../shared/services/payment.service"
import { source } from '../../../node_modules_nope/@angular-devkit/schematics/src';
import * as firebase from "firebase";
import { AngularFireFunctions } from '@angular/fire/functions'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})
export class RSVPComponent implements OnInit {
  login: boolean;
  replyId: string;
  str: any;
  itemRef: Observable<unknown>;
  passedId: string;
  itemDoc: Observable<unknown>;
  item: any;
  info: any;
  isCE: any;
  pretty: any;
  userRef: Observable<unknown>;
  userInfo: any;
  name: any;
  userStripe: any;
  stripeId: any;
  locations: any;
  address: any;
  time: any;
  cell: string;
  email: string;
  firstName: string;
  lastName: string;
  rsvpRef: AngularFirestoreCollection<unknown>;
  rsvp: any;
  sendEmail: any;
  postId: any;
  date: any;
  now: number;
  confirmation: any;
  classId: any;
  registered: boolean;
  webinarId: any;


  

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, 
    private modalService: NgbModal,
    private paymentSvc: PaymentService,
    private functions: AngularFireFunctions,
    private http: HttpClient
  ) {
    this.now = Date.now()
    this.afAuth.authState.subscribe(user => {
      if (user) console.log(user.uid)})
  
    this.login = false;
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.itemRef = this.afs.doc('notes/'+ this.str).valueChanges();
    this.passedId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.passedId);
    this.itemDoc = this.afs.doc('event-requests/'+ this.str).valueChanges();
    this.item = this.itemDoc.subscribe((datas) => { 
      this.info = datas,
      // this.isCE = this.info.isCE,
      this.pretty = this.info.date,
      this.locations = this.info.location
      this.address = this.info.address
      this.time = this.info.time,
      this.date = this.info.date
      this.webinarId = this.info.classId
     
    })
    this.afAuth.authState.subscribe(user => {
      if (user) {
      this.userRef = this.afs.doc('users/'+ user.uid).valueChanges();
      this.userInfo = this.userRef.subscribe((result) =>{
        this.name = result
        this.registered = this.name.registered

      })
      this.userRef = this.afs.doc('users/'+ user.uid).valueChanges();
    this.userStripe = this.userRef.subscribe((stripe) =>{
      this.stripeId = stripe
      
     
    })
      }
    })
  
  }

  ngOnInit() {
    this.cell = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
  }
  async sendInvite(cell, email, firstName, lastName){

    console.log(this.webinarId)
    this.cell = cell;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    const myPhone = cell.replace(/\D/g,'');
    console.log(myPhone)
console.log(cell, email, firstName, lastName, this.str)

        //=============================================
       
        //==============================================
this.rsvpRef = this.afs.collection('rsvp/');

this.rsvp = this.rsvpRef.add({
  email: email,
  phone: myPhone,
  firstName: firstName,
  lastName: lastName,
  address: this.address,
  location: this.locations,
  date: this.date,
  time: this.time,
  certified: false,
  string: this.locations + ', ' + this.address + ' on ' + this.date + ' at '+ this.time,
  timestamp: this.now,
  filterId: this.str,
  class: this.str,
  classId: this.webinarId
  
}).then;{
  if (this.registered == true){
    const fun = this.functions.httpsCallable('sendMail');
  this.confirmation =  await fun({dest:email }).toPromise()
 console.log(this.confirmation)
  Swal.fire(
    'Thank you!',
    'Keep an eye out.  We will send your confimation and reminders the day before the event!',
    'success'
  )
  this.cell = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
  }
  
    // this.router.navigate(['/index']);
}


  
  }

}
