import { Component, OnInit, ViewChild  } from '@angular/core';
import { GridAppComponent } from '../calc/grid-app/grid-app.component';
import { BarChartComponent } from '../calc/bar-chart/bar-chart.component';
import { DataService } from '../calc/data-service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

    /** Configurations for the Home page */
    constructor(private data: DataService) {
    }

    @ViewChild('chartSection', {static: false})
    public chart: BarChartComponent;
    @ViewChild('gridSection', {static: false})
    public grid: GridAppComponent;

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
      this.data.grid = this.grid;
      this.data.chart = this.chart;
    }
}