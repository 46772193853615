
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';

import { environment } from 'environments/environment'
import * as firebase from 'firebase';
import * as firebase2 from 'firebase';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
// import { CountiesService } from "../shared/services/counties.service";
// import Swal from 'sweetalert2'
import { firestore } from 'firebase';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { data } from  '../shared/services/counties';
import { AngularFireFunctions } from '@angular/fire/functions'


@Component({
  selector: 'app-welcome-org',
  templateUrl: './welcome-org.component.html',
  styleUrls: ['./welcome-org.component.scss']
})
export class WelcomeOrgComponent implements OnInit {
  ts: Date;
  created: any;
  now: number;
  sendRef: AngularFirestoreCollection<unknown>;
  buyEmail: any;
  buyFirstName: string;
  buyLastName: string;
  buyPhone: any;
  ifirst: any;
  ilast: any;
  iemail: any;
  iphone: any;
  notes: any;
  confirmation: any;
  name: string;
  refName: string;
  refPhone: string;
  refEmail: string;
  BuyerEmail: string;
  BuyerName: string;
  BuyerPhone: string;
  AgentName: string;
  AgentEmail: string;
  AgentPhone: string;
  johnson:boolean;
  otherAgent: boolean;
  agentName: string;
  agentEmail: string;
  agentPhone: string;

  constructor(    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    private http: HttpClient,
    private httpClient: HttpClient,
    private functions: AngularFireFunctions) { 

      this.johnson = false;
      this.otherAgent = false;
    }

  ngOnInit() {
  }
  ref(BuyerName,BuyerEmail,BuyerPhone, notes, AgentName, AgentEmail, AgentPhone  ){
    console.log(BuyerName,BuyerEmail,BuyerPhone, notes, AgentName, AgentEmail, AgentPhone )
  }
  async referJohnson(BuyerName,BuyerEmail,BuyerPhone, notes){

    const referName ='Matt Johnson';
    const referEmail = 'matt@anchoruprealtygroup.com';
    const referPhone = +15045794766;
    this.ts = new Date();
    this.created = this.ts.toDateString();
    this.now = Date.now();
    console.log('ts' + this.ts)
    console.log('created' + this.created)
    console.log('now' + this.now)
  this.sendRef = this.afs.collection('WelcomeOrg');
  this.sendRef.add({
  exactTime: this.now,
  readableTime: this.created,
  timeStamp: this.ts,
  email: BuyerEmail,
  name: BuyerName,
  phone: BuyerPhone,
  referName:referName,
  referEmail: referEmail,
  referPhone: referPhone,
  notes: notes,
  LO: 'open',
  closed: false,
  status: 'New Lead',
  dead: false,
  incubation: false
  
  })
  Swal.fire(
    'Success!',
    'Thank you for your referral. The message has been emailed and texted to David Smith and the VA Team! Let the buyer know that someone will be contacting them shortly.',
    
    'success'
  ).then();{
    const email = 'mduhon@mutualmortgage.com'
    const fun = this.functions.httpsCallable('WelcomeLeadEmail');
    this.confirmation =  await fun({
      dest:email, 
      readableTime: this.created,
  email: BuyerEmail,
  name: BuyerName,
  phone: BuyerPhone,
  referName:referName,
  referEmail: referEmail,
  referPhone: referPhone,
  notes:notes 
    
    }).toPromise()
      this.BuyerEmail = '';
      this.BuyerName = '';
    
      this.BuyerPhone = '';
    
      this.notes ='';
      const fun2 = this.functions.httpsCallable('welcomeText');
      this.confirmation =  await fun2({
        dest:email, 
        readableTime: this.created,
    email: BuyerEmail,
    name: BuyerName,
    phone: BuyerPhone,
    referName:referName,
    referEmail: referEmail,
    referPhone: referPhone,
    notes:notes 
      
      }).toPromise()
  }
  
    //  console.log( buyEmail, buyName, buyPhone, refEmail, refName, refPhone)
   
  }
  async referAgent(BuyerName,BuyerEmail,BuyerPhone, notes, agentName, agentPhone, agentEmail){

    const referName =agentName;
    const referEmail = agentEmail;
    const referPhone = agentPhone;
    const phone = referPhone.replace(/[^0-9]/g, '');
    const e164 = '+1' + phone
    this.ts = new Date();
    this.created = this.ts.toDateString();
    this.now = Date.now();
    console.log('ts' + this.ts)
    console.log('created' + this.created)
    console.log('now' + this.now)
  this.sendRef = this.afs.collection('WelcomeOrg');
  this.sendRef.add({
  exactTime: this.now,
  readableTime: this.created,
  timeStamp: this.ts,
  email: BuyerEmail,
  name: BuyerName,
  phone: BuyerPhone,
  referName:referName,
  referEmail: referEmail,
  referPhone: referPhone,
  notes: notes,
  LO: 'open',
  closed: false,
  status: 'New Lead',
  dead: false,
  incubation: false
  
  })
  Swal.fire(
    'Success!',
    'Thank you for your referral. The message has been emailed and texted to David Smith and the VA Team! Let the buyer know that someone will be contacting them shortly.',
    
    'success'
  ).then();{
    const email = 'mduhon@mutualmortgage.com'
    const fun = this.functions.httpsCallable('WelcomeLeadEmail');
    this.confirmation =  await fun({
      dest:email, 
      readableTime: this.created,
  email: BuyerEmail,
  name: BuyerName,
  phone: BuyerPhone,
  referName:referName,
  referEmail: referEmail,
  referPhone: referPhone,
  notes:notes 
    
    }).toPromise()
      this.BuyerEmail = '';
      this.BuyerName = '';
    this.agentName = '';
    this.agentEmail = '';
    this.agentPhone = '';
      this.BuyerPhone = '';
    
      this.notes ='';
      const fun2 = this.functions.httpsCallable('welcomeText');
      this.confirmation =  await fun2({
        dest:email, 
        readableTime: this.created,
    email: BuyerEmail,
    name: BuyerName,
    phone: BuyerPhone,
    referName:referName,
    referEmail: referEmail,
    referPhone: e164,
    notes:notes 
      
      }).toPromise()
  }
  
    //  console.log( buyEmail, buyName, buyPhone, refEmail, refName, refPhone)
   
  }
  mattJohnson(){
    this.johnson = true;
    this.otherAgent = false;
  }
  notListed(){
    this.johnson = false;
    this.otherAgent = true;
  }
}


