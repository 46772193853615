import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataService } from './data-service';
import { BarChartComponent } from './bar-chart/bar-chart.component';

@Component({
  selector: 'calc-section',
  templateUrl: './calc.component.html',
  encapsulation: ViewEncapsulation.None
})

export class CalcComponent implements OnInit {

    /** Configurations for the Home page */
    constructor(private data: DataService) {
    }

    @ViewChild('dashboardSection', {static: false})
    public dashboard: DashboardComponent;


    @ViewChild('chart-section', {static: false})
    public barChart: BarChartComponent;
    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
      this.data.dashboard = this.dashboard;
    }
}
