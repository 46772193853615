import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GeocodeService } from '../shared/services/geocode.service';
import { Location } from '../shared/services/location-model';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { NgModel } from '@angular/forms';

import { FormsModule } from '@angular/forms';
// import { environment } from 'environments/environment';

import { get } from 'geofirex';
import { toGeoJSON } from 'geofirex'
import { GeoCollectionReference, GeoFirestore, GeoQuery, GeoQuerySnapshot } from 'geofirestore';

import 'firebase/firestore';
// firebase.initializeApp(firebase);
// Init GeoFireX
import * as geofirex from 'geofirex';
// const geo = geofirex.init(firebase);


import { google } from '@google/maps';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, switchMap } from 'rxjs/operators';



declare const google: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {
  address = 'Houston';
  location: Location;
  loading: boolean;
  public customStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]
  geo = geofirex.init(firebase);
  points: Observable<any>;
  radius = new BehaviorSubject(0.5);
  mac: Observable<{}>;

  b: {};
  points2: Observable<{}>;
  d: any;

  constructor(
    private geocodeService: GeocodeService,
    private ref: ChangeDetectorRef,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
    this.addressToCoordinates();
    this.mac = this.afs.collection('agentMap').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        
        const data = a.payload.doc.data();
        this.b = data
        console.log(this.b);
        const id = a.payload.doc.id; 
        return { id, ...data };
      }))
    );

    const collection = this.geo.collection('agentMap')
    
    const center = this.geo.point(29.7604267, -95.3698028);
    const radius = 1000;
    const field = 'position';

    this.points = collection.within(center, radius, field);

    this.points.subscribe(data => {
      this.d = data;
      console.log(this.d)
    })
 

   
    
    

  }
  
  ngOnInit() {
  

  }
  update(v) {
    this.radius.next(v);
  }
 
  trackByFn(_, doc) {
    return doc.id;
  }
 
  // createPoint(lat, lng) {
  //   const collection = this.geo.collection('places')

  //   // Use the convenience method
  //   // collection.setPoint('my-place', lat, lng)

  //   // Or be a little more explicit 
  //   const point = this.geo.point(lat, lng)
  //   collection.setDoc('my-place', { position: point.data })
  // }

  showLocation() {
    this.addressToCoordinates();
  }

  addressToCoordinates() {
    this.loading = true;
    this.geocodeService.geocodeAddress(this.address)
    .subscribe((location: Location) => {
        this.location = location;
        this.loading = false;
        this.ref.detectChanges();  
        console.log(this.location);
      }      
    );     
  }

  // Saving geohash data
  geoSave(lat, lng){
    console.log(lat, lng);
    const cities = this.geo.collection('agentMap');

const point = this.geo.point(lat, lng);
this.afAuth.authState.subscribe(user => {
  if (user) {
cities.add({ 
  name: this.address,
  position: point.data ,
  agent: user.uid
});
}})
  }
 
}
