import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment'
import * as firebase from 'firebase';
import * as firebase2 from 'firebase';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
// import { emailTemplate }  from '../completed/email';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {


  newAgent: AngularFirestoreCollection<unknown>;
  new: any;
  codeRef: AngularFirestoreDocument<any>;
  code: any;
  // template: emailTemplate

  constructor(public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,) {

  
     }

  ngOnInit() {

  }
sendInvite(value){
  
  this.codeRef = this.afs.doc('resources/html');
  this.code = this.codeRef;
  console.log(this.code);
  console.log(value);
  this.newAgent = this.afs.collection('invites/');
  this.new = this.newAgent.add({
    to: value,
    message:{
      subject: "Congratulations!  You are now a certified VA expert!",
      // html: this.template
    }
  })
}
}
