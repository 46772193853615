import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { auth } from 'firebase/app';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})


export class RegisterComponent implements OnInit {
userName;
userPassword;

  firstName: '';
  lastName:'';
  emailAddress: '';
  phone:'';
  state:'';
  license:'';
  replyId: string;
  str: any;
  constructor(public afAuth: AngularFireAuth,private router: Router, public authService: AuthService ,  public afs: AngularFirestore, private route: ActivatedRoute,  // Inject Firestore service
    ) { 
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      console.log( this.str);
  }

  ngOnInit() {
  }
  certify(license){
    const db = firebase.firestore();
    
    this.afAuth.authState.subscribe(user => {
      if(user) {
        let addClass = db.collection('users/').doc(user.uid).update({
          entranceLink: this.str,
          class: this.str,
          certifiedAgentId: user.uid,
        certified: true,
        licence: license
          
        })
   .then();{
      this.router.navigate(['profile/:'+ user.uid]); 
    }
  
  
    }})
  }
  
}
