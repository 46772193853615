import { Component, OnInit, NgZone } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import Swal from 'sweetalert2';
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "../shared/services/auth.service";
import { map } from 'rxjs/operators';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable} from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
 
 
  closeResult: string;
  calendarPlugins = [dayGridPlugin]; 
  link: string;
  user: string;
 
  ts: Date;
  created: any;
  now: number;
  eventCollection: AngularFirestoreCollection<Event>;
  eventsList: any;
  Allevents: any;
  events: any;
  eventCollection2: AngularFirestoreCollection<Event>;
  today: string;
  year: any;
  month: any;
  day: any;
  dates: number;
  maths: any;
  monthRef: string;
  dayRef: string;
  eventCollection3: AngularFirestoreCollection<Event>;

  

  handleDateClick(arg) { // handler method
    alert(arg.dateStr);

  }

 


  
  constructor(private modalService: NgbModal,public afs: AngularFirestore, public ngZone: NgZone, public authService: AuthService,
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    public router: Router,) { 
      this.year = new Date().getFullYear()
     this.month = new Date().getMonth() + 1
     this.day = new Date().getDate()
    
     if (this.month < 10) {
       this.month = "0"+ (new Date().getMonth() + 1)
       console.log(this.month);
     }
    
     if (this.day < 10) {
      this.day = "0"+ new Date().getDate()
      console.log(this.day);
    }
  
     

this.today = this.year + '-' + this.month + '-' + this.day
console.log(this.today);
      this.eventCollection = this.afs.collection<Event>('event-requests');
      this.events = this.eventCollection.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; 
        
//    
          return { id, ...data };
        })))
        this.eventCollection2 = this.afs.collection<Event>('event-requests/', ref => ref.where('date', '>=', this.today).limit(4));
        this.eventsList = this.eventCollection2.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id; 

            return { id, ...data };
          })))
          this.eventCollection3 = this.afs.collection<Event>('event-requests/', ref => ref.where('date', '>', this.today).limit(40));
          this.Allevents = this.eventCollection3.snapshotChanges().pipe(
            map(actions => actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id; 
  
              return { id, ...data };
            })))
  }

  ngOnInit() {
  }

toEvent(id){
  console.log(id)
  this.router.navigate(['event/:'+ id]);
}
  
}


