import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sellers-faq',
  templateUrl: './sellers-faq.component.html',
  styleUrls: ['./sellers-faq.component.scss']
})
export class SellersFAQComponent implements OnInit {
  pdfSrc: string = '/assets/pdf/sellersFAQ.pdf';
  constructor() { }

  ngOnInit() {
  }

}
