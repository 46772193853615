
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';

import { environment } from 'environments/environment'
import * as firebase from 'firebase';
import * as firebase2 from 'firebase';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
// import { CountiesService } from "../shared/services/counties.service";
// import Swal from 'sweetalert2'
import { firestore } from 'firebase';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { data } from  '../shared/services/counties';
import { AngularFireFunctions } from '@angular/fire/functions'

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.scss']
})
export class ReferComponent implements OnInit {
  ts: Date;
  created: any;
  now: number;
  sendRef: AngularFirestoreCollection<unknown>;
  buyEmail: any;
  buyFirstName: string;
  buyLastName: string;
  buyPhone: any;
  ifirst: any;
  ilast: any;
  iemail: any;
  iphone: any;
  notes: any;
  confirmation: any;
  name: string;
  refName: string;
  refPhone: string;
  refEmail: string;
  BuyerEmail: string;
  BuyerName: string;
  BuyerPhone: string;
  AgentName: string;
  AgentEmail: string;
  AgentPhone: string;
  state: string;
  loanAmount: string;
  owner: string;
  incrementRef4: Promise<void>;
  ownerData: any[];
  ownerInfo: any;
  ownerRef: any;
  LO: any;
  loan: any;
  sendToLO: any;
  incrementRef: Promise<void>;
  incrementRef3: Promise<void>;
  incrementRef2: Promise<void>;
  agentUID: any;
  phone: any;


  constructor(    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    private http: HttpClient,
    private httpClient: HttpClient,
    private functions: AngularFireFunctions) {
      // console.log(this.AgentEmail)
      if(this.AgentEmail != undefined){
        this.ownerInfo = this.afs.collection('users',ref =>ref.where('email','==', this.AgentEmail)).valueChanges()
        this.ownerInfo.subscribe((datas) => {
        this.ownerData = datas;
        this.agentUID = this.ownerData[0].uid
        console.log(this.agentUID)
        })
      }
     
     }

  ngOnInit() {
  }
  ref(first, last,email,phone, note, agName, agEmail, agPhone,state, loan){

  


  }
  async refer(first, last,email,phone, note, agName, agEmail, agPhone,state, loan){
    this.loan = +loan.replace(/\D/g,'');
    this.phone = phone.replace(/\D/g,'');
    const date = new Date();  // 2009-11-10
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const yearString = year.toString()
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(+this.loan);
    this.ts = new Date();
    this.created = this.ts.toDateString();
    this.now = Date.now();
    console.log('ts' + this.ts)
    console.log('created' + this.created)
    console.log('now' + this.now)
    const db = firebase.firestore()
    // this.loan = loanAmount.replace(/\D/g,'');
    console.log(first, last,email,phone, note, agName, agEmail, agPhone,state, loan)
    const docRef = db.collection("users").where('email','==',agEmail).get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log( doc.data().LO);
          // this.refer(first, last,email,phone, note, agName, agEmail, agPhone,state, loan, doc.data().uid)
    if(doc.data().uid == undefined){
      console.log('No Onwer', doc.data().uid)
      this.ownerRef = 'No Agent'
      console.log( this.ownerRef)
    }
    else {
 
    this.ownerRef = doc.data().uid

    }
    if (doc.data().LO == undefined){
      this.LO = 'open'
    }
    else {
      this.LO = doc.data().LO
    }
  this.sendRef = this.afs.collection('referrals');
  this.sendRef.add({
  exactTime: this.now,
  readableTime: this.created,
  timeStamp: this.ts,
  email: email,
  firstName: first,
  lastName: last,
  phone: this.phone,
  referName: agName, 
  referEmail: agEmail,
  referPhone:  agPhone,
  state: state,
  loanAmount: this.loan,
  owner: this.ownerRef, 
  notes: this.notes,
  LO: this.LO,
  closed: false,
  status: 'New',
  dead: false,
  incubation: false,
  raymond: true
  
  })

  if(doc.data().LO!= undefined){ 
    this.sendToLO = doc.data().LO
    this.LO = doc.data().LO
    this.afs.collection('users').doc(this.LO).update({
      newLeadCount: increment,
      leadsCount: increment,
      leadValueCount: valueIncrement
    })
    this.incrementRef = this.afs.doc('stats/' + this.sendToLO +'/'+ yearString + '/New').set({
      [month]: increment,
      status: 'New',
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment

    }, { merge: true })
    this.incrementRef = this.afs.doc('stats/Raymond/'+ yearString + '/New').set({
      [month]: increment,
      status: 'New',
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment

    }, { merge: true })
    this.incrementRef3 = this.afs.doc('stats/Team/' + yearString + '/TeamStats').set({
     
    
      totalLeadValue: valueIncrement,
      [month + 'LeadValue']: valueIncrement,
      [month + 'LeadCount']:increment,
      [month + 'LeadValueRaymond']: valueIncrement,
      [month + 'LeadCountRaymond']:increment,
      totalLeadCount: increment

    }, { merge: true })
    this.incrementRef2 = this.afs.doc('stats/' + this.sendToLO +'/'+ yearString + '/Lead').set({
      [month]: increment,
      status: 'Lead',
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment

    }, { merge: true })
    console.log(this.lo, this.sendToLO)
   
 
  }
  else {
  this.incrementRef4 = this.afs.doc('stats/Team/' + yearString + '/TeamStats').set({
    totalOpenLeadValue: valueIncrement,
    [month + 'OpenLeadValue']: valueIncrement,
    [month + 'OpenLeadCount']:increment,
    [month + 'OpenValueRaymond']: valueIncrement,
    [month + 'OpenCountRaymond']:increment,
    totalOpenLeadCount: increment

  }, { merge: true })
}





});
})
  .catch((error) => {
    console.log("Error getting documents: ", error);
});
  Swal.fire(
    'Success!',
    'Thank you for your referral. The message has been emailed and texted to David Smith and the VA Team! Let the buyer know that someone will be contacting them shortly.',
    
    'success'
  ).then();{
    
    
  //   const email = 'mduhon@mutualmortgage.com, davidsmith@mutualmortgage.com'
  //   const fun = this.functions.httpsCallable('LeadEmail');
  //   this.confirmation =  await fun({
  //     dest:email, 
  //     readableTime: this.created,
  // email: BuyerEmail,
  // name: firstName + ' ' + lastName,
  // phone: BuyerPhone,
  // referName: AgentName,
  // referEmail: AgentEmail,
  // referPhone: AgentPhone,
  // notes:notes 
    
  //   }).toPromise()
      this.BuyerEmail = '';
      this.BuyerName = '';
    
      this.BuyerPhone = '';
      this.AgentName = '';
      this.AgentEmail = '';
      this.AgentPhone = '';
      this.notes ='';
  }
  
    //  console.log( buyEmail, buyName, buyPhone, refEmail, refName, refPhone)
 
  }
  lo(lo: any, sendToLO: any) {
    throw new Error('Method not implemented.');
  }
}
