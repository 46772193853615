

import { NgZone, Injectable} from '@angular/core';
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  userId: string;
  amount: number = 2000;

  constructor(public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,) { 

      this.afAuth.authState.subscribe(user => {
        if (user) this.userId = user.uid })
    }

    createSource(token){
    
     const tokens = {token}
      return 
    }

    // processPayment (token){
    //   this.afs.collection('stripe_customers/').doc(this.userId).collection('tokens').add(token)
    //   .then;{
    //     const amount = this.amount;
    //     const currency = 'usd';
    //        const charges = {token, amount, currency}
    //        return this.afs.collection('stripe_customers/').doc(this.userId).collection('charges').add(charges);
    //   }

     
    // }
    processPayment (source){
      console.log(source);
      this.afs.collection('stripeCustomers/').doc(this.userId).collection('tokens').add(source)
      .then;{
        const amount = this.amount;
        const currency = 'usd';
           const charges = {source, amount, currency}
           this.afs.collection('users/').doc(this.userId).update({
             processing: true
           })
           return this.afs.collection('stripeCustomers/').doc(this.userId).collection('charges').add(charges);
      }

     
    }
}
