import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyers-faq',
  templateUrl: './buyers-faq.component.html',
  styleUrls: ['./buyers-faq.component.scss']
})
export class BuyersFAQComponent implements OnInit {
  pdfSrc: string = '/assets/pdf/buyersFAQ.pdf';
  constructor() { }

  ngOnInit() {
  }

}
