import { Component, OnInit, Inject, Renderer, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';

import { LocationStrategy, PlatformLocation, Location, DOCUMENT } from '@angular/common';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbAccordionConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './shared/services/auth.service';
import Swal from 'sweetalert2';
// import firebase from 'firebase/app';

// const analytics = firebase.analytics();
// const perf = firebase.performance();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    @ViewChild(NavbarComponent, {static: false}) navbar: NavbarComponent;
    user: string;
    itemDoc: any;
    item: any;
    refName: string;
    refEmail: '';
    refPhone: '';
    buyEmail:'';
    buyPhone:'';
    buyName:'';
    closeResult: string;
    ts: Date;
    created: any;
    now: number;
    sendRef: any;
    buyFirstName: '';
    buyLastName: '';
    ref: any;
    name: '';
    notes: '';
    constructor( private renderer : Renderer, private router: Router, @Inject(DOCUMENT,) private document: any, private element : ElementRef, public location: Location, config: NgbAccordionConfig,private modalService: NgbModal,public afs: AngularFirestore, public ngZone: NgZone, public authService: AuthService,
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth) {

        this.afAuth.authState.subscribe(user => {
            if(user) {
      this.user = user.uid
      this.itemDoc = this.afs.doc('users/'+ user.uid);
      this.item = this.itemDoc.valueChanges();
      this.item.subscribe(data =>{
        // this.ref = data;
        // this.refName = this.ref.firstName + ' ' + this.ref.lastName;
        // this.refEmail = this.ref.email;
        // this.refPhone = this.ref.phone
        // console.log(this.refName);
      });
            }
          });
    config.closeOthers = true;
    config.type = 'info';
}
open(content, type) {
    this.notes ='';
    this.name = '';
    this.buyEmail = '';
    this.buyFirstName = '';
    this.buyLastName = '';
    this.buyPhone = '';
    if (type === 'sm') {
        console.log('aici');
        this.modalService.open(content, { size: 'sm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } else {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
}

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
}
refer( refEmail,refName, refPhone, buyEmail, buyFirstName,buyLastName, buyPhone){
    this.ts = new Date();
    this.created = this.ts.toDateString();
    this.now = Date.now();
    console.log('ts' + this.ts)
    console.log('created' + this.created)
    console.log('now' + this.now)
  this.sendRef = this.afs.collection('referrals');
  this.sendRef.add({
      to: ['mduhon@mutualmortgage.com'],
    //   
      message: {
        subject: 'Referral from theVAloan.org!',
        text: 'Referral Details.',
        html: 'Lead Info:</br>' + "Buyer's Info: </br>" + this.name + '</br>' + this.buyEmail + '</br>' + this.buyPhone + '</br></br>Referred By:</br>' + this.refName + '</br>' + this.refEmail + '</br>' +this.refPhone + '</br>' + 'Notes' +  '</br>'+ this.notes
        ,
      },
  exactTime: this.now,
  readableTime: this.created,
  timeStamp: this.ts,
  email: this.buyEmail,
  name: this.name,
  phone: this.buyPhone,
  referName: this.refName,
  referEmail: this.refEmail,
  referPhone: this.refPhone,
  notes: this.notes
  
  })
  Swal.fire(
    'Success!',
    'Thank you for your referral. The message has been emailed and texted to David Smith and the VA Team! Let the buyer know that someone will be contacting them shortly.',
    
    'success'
  ).then();{
      this.buyEmail = '';
      this.name = '';
      this.buyLastName = '';
      this.buyPhone = '';
      this.refName = '';
      this.refPhone = '';
      this.refEmail = '';
      this.notes ='';
  }
  
    //  console.log( buyEmail, buyName, buyPhone, refEmail, refName, refPhone)
   
  }

    ngOnInit() {
        var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            }else{
                window.document.activeElement.scrollTop = 0;
            }
            this.navbar.sidebarClose();

            this.renderer.listenGlobal('window', 'scroll', (event) => {
                const number = window.scrollY;
                var _location = this.location.path();
                _location = _location.split('/')[2];

                if (number > 150 || window.pageYOffset > 150) {
                    navbar.classList.remove('navbar-transparent');
                } else if (_location !== 'login' && this.location.path() !== '/nucleoicons') {
                    // remove logic
                    navbar.classList.add('navbar-transparent');
                }
            });
        });
    }
    
}
