import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  document: any;

  constructor(public router: Router,) { }

  ngOnInit() {
  }
facebook(){

  this.document.location.href = 'https://www.facebook.com/groups/vacertifiedagent';
}
referral(){
  
  this.document.location.href = 'https://thevaloan.org/referral';
}
propertyReq(){
  this.document.location.href = 'https://mcusercontent.com/301c7dc4d20e8b3556232e20a/files/09078c4b-aba0-4ae1-8959-fd5423db66a4/VA_Property_Requirements.pdf';
}
cliff(){
  this.document.location.href = 'https://mcusercontent.com/301c7dc4d20e8b3556232e20a/files/461e9386-9254-485f-9234-b68e35dae2b7/MOOM_19_VA_MortgagefoRealEstateProfessionalCliffNotes_DavidSmith.01.pdf';
}
eben(){
  this.document.location.href = 'https://www.ebenefits.va.gov/ebenefits/homepage';
}
faqBuy(){
  this.document.location.href = 'https://mcusercontent.com/301c7dc4d20e8b3556232e20a/files/d1cd148e-7d2c-4e98-af4b-7ca8cea09f2a/VAMortgageFAQForBuyers.pdf';
}
faqSell(){
  this.document.location.href = 'https://mcusercontent.com/301c7dc4d20e8b3556232e20a/files/a760ce0c-f9c9-4c15-8fcf-37595182dd47/VAMortgageFAQForSellers.pdf';
}
vid1(){
  this.document.location.href = 'https://share.vidyard.com/watch/dbLU68yAgd6gMDSFYE8AVJ?';
}
vid2(){
  this.document.location.href = 'https://share.vidyard.com/watch/vNUXgcmLRxHM3H1vUh1LmK?';
}
reg(){
  this.document.location.href = 'https://thevaloan.org/landing/:email';
}

}
