import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    user: string;
    itemsCollection: any;
    item: any;
    replyId: string;
    str: any;
    str2: string;
    str3: string;
    scroll(el: HTMLElement) {
        el.scrollIntoView({behavior: 'smooth'});
      }

    constructor(private afs: AngularFirestore, public authService: AuthService, public location: Location, private element : ElementRef,public afAuth: AngularFireAuth, public router: Router,   private route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,) {
        this.sidebarVisible = false;
        // this.route.paramMap.subscribe(params => {
        //     this.replyId = params.get("id")
        //   })
        //   this.str = this.replyId.substring(1);
        //   console.log( this.str);
    
        this.afAuth.authState.subscribe(user => {
            if(user) {
       
              this.user = user.uid;
         
              this.itemsCollection = this.afs.collection('users').doc(user.uid);
              this.item = this.itemsCollection.valueChanges()
            }
            else {
                
            }
            });
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
  
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
    search(){
        this.router.navigate(['search/']);
    }
login(){
    this.router.navigate(['login/']);
}

    home(){
        this.router.navigate(['home/']);
    }
    calendar(){
        this.router.navigate(['calendar/']);
    }
    events(){
        this.router.navigate(['events/']);
    }
    calculator(){
        this.router.navigate(['calculator/']);
    }
    profile(user){

        console.log(user.uid);
        this.router.navigate(['profile/:' + user.uid]);
    }
    forum(){
        this.router.navigate(['forum/']);
    }
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
           this.str = titlee.substring(6);
           this.str2 = titlee.substring(9);
           this.str3 = titlee.substring(10);
    

        if( titlee === '/index' || titlee === '/rsvp/'+ this.str || titlee === '/register/'+ this.str3 || titlee === '/payments/'+ this.str3 || titlee === '/cert' || titlee === '/certificate') {
            return true;
        }
        else {
            return false;
        }
    }
    // isPayment() {
    //     var titlee = this.location.prepareExternalUrl(this.location.path());
    //        this.str = titlee.substring(10);
   

    //     if( titlee === '/payments/:'+ this.str) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }
  
}
