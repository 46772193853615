// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  stripeKey : "pk_live_ynDn5PpekYgTOG854lAwq7Tf00YW7XI3pg",
  firebase: {
    apiKey: "AIzaSyC5Jj7pRCt8Mk_M4YbJKzUpOKr4TulN4r4",
    authDomain: "thevaloan-6fe1e.firebaseapp.com",
    databaseURL: "https://thevaloan-6fe1e.firebaseio.com",
    projectId: "thevaloan-6fe1e",
    storageBucket: "thevaloan-6fe1e.appspot.com",
    messagingSenderId: "1077684459332",
    appId: "1:1077684459332:web:04ff7429fee71293"
  },

};

