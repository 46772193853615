import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';

import { CalculatorComponent } from './calculator/calculator.component';

import { EventComponent } from './event/event.component';
import { SearchComponent } from './search/search.component';
import { ForumComponent } from './forum/forum.component';
import { EventsComponent } from './components/basicelements/events/events.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ProfileSetupComponent } from './profile-setup/profile-setup.component';
import { CalcComponent } from './calc/calc.component';
import { BarChartComponent } from './calc/bar-chart/bar-chart.component';
import { CertifiedAgentComponent } from './certified-agent/certified-agent.component';
import { AgentsComponent } from './agents/agents.component';
import { MediaComponent } from './media/media.component';
import { RequestEventComponent } from './request-event/request-event.component'
import { NotesComponent } from './notes/notes.component';
import { FAQComponent } from './faq/faq.component'
import { PrivacyComponent }  from './privacy/privacy.component'
import { MapComponent } from './components/map/map.component';
import { SellersFAQComponent } from './sellers-faq/sellers-faq.component';
import { BuyersFAQComponent } from './buyers-faq/buyers-faq.component';
import { CompletedComponent } from './completed/completed.component';
import { RSVPComponent } from './rsvp/rsvp.component';
import { OnlineComponent } from './online/online.component';
import { PaymentsComponent } from './payments/payments.component';
import { RegisterComponent } from './register/register.component';
import { CertificateComponent } from './certificate/certificate.component';
import { CertComponent } from './cert/cert.component';
import { ReferralComponent } from './referral/referral.component';
import { EmailComponent } from './email/email.component';
import { SkipComponent } from './skip/skip.component';
import { WelcomeOrgComponent } from './welcome-org/welcome-org.component';
import { PrepropertiesComponent } from './preproperties/preproperties.component';
import { ReferComponent } from './refer/refer.component';
const routes: Routes =[
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    { path: 'index',                component: ComponentsComponent },
    { path: 'nucleoicons',          component: NucleoiconsComponent },
    { path: 'landing/:id',     component: LandingComponent },
    { path: 'login',       component: LoginComponent },
    { path: 'skip',       component: SkipComponent },
    { path: 'profile/:id',     component: ProfileComponent },
    { path: 'email',       component: EmailComponent },
    { path: 'calc',       component: CalcComponent },
    { path: 'events',       component: EventsComponent },
    { path: 'event/:id',       component: EventComponent },
    { path: 'search',       component: SearchComponent },
    { path: 'home', redirectTo: 'index', pathMatch: 'full' },
    { path: 'forum',       component: ForumComponent },
    { path: 'calendar',       component: CalendarComponent },
    { path: 'profile-setup/:id',       component:ProfileSetupComponent },
    { path: 'chart',       component: BarChartComponent },
    { path: 'calculator',       component: CalculatorComponent },
    { path: 'certified-agent/:id',       component: CertifiedAgentComponent },
    { path: 'agents',       component: AgentsComponent },
    { path: 'media',       component: MediaComponent },
    { path: 'request-event/:id',       component: RequestEventComponent },
    { path: 'notes/:id',       component: NotesComponent },
    { path: 'FAQ',       component: FAQComponent },
    { path: 'privacy',       component: PrivacyComponent },
    { path: 'map', component: MapComponent },
    { path: 'sellersFAQ', component: SellersFAQComponent },
    { path: 'buyersFAQ', component: BuyersFAQComponent },
    { path: 'hooah', component: CompletedComponent },
    { path: 'rsvp/:id', component: RSVPComponent },
    { path: 'online/:id', component: OnlineComponent },
    { path: 'payments/:id', component: PaymentsComponent },
    { path: 'register/:id', component: RegisterComponent },
    { path: 'certificate/:id', component: CertificateComponent },
    { path: 'cert', component: CertComponent },
    { path: 'referral', component: ReferralComponent },
    { path: 'refer', component: ReferComponent },
    { path: 'welcomeorg', component: WelcomeOrgComponent },
    { path: 'preproperties', component: PrepropertiesComponent},
    { path: '**', redirectTo: 'index'},
  
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
