export const data = [
            {
              stateCode: "01",
              countyCode: "001",
              name: "AUTAUGA COUNTY",
              state: "AL",
              'CBSA Number': "33860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "003",
              name: "BALDWIN COUNTY",
              state: "AL",
              'CBSA Number': "19300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "005",
              name: "BARBOUR COUNTY",
              state: "AL",
              'CBSA Number': "21640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "007",
              name: "BIBB COUNTY",
              state: "AL",
              'CBSA Number': "13820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "009",
              name: "BLOUNT COUNTY",
              state: "AL",
              'CBSA Number': "13820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "011",
              name: "BULLOCK COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "013",
              name: "BUTLER COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "015",
              name: "CALHOUN COUNTY",
              state: "AL",
              'CBSA Number': "11500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "017",
              name: "CHAMBERS COUNTY",
              state: "AL",
              'CBSA Number': "29300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "019",
              name: "CHEROKEE COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "021",
              name: "CHILTON COUNTY",
              state: "AL",
              'CBSA Number': "13820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "023",
              name: "CHOCTAW COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "025",
              name: "CLARKE COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "027",
              name: "CLAY COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "029",
              name: "CLEBURNE COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "031",
              name: "COFFEE COUNTY",
              state: "AL",
              'CBSA Number': "21460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "033",
              name: "COLBERT COUNTY",
              state: "AL",
              'CBSA Number': "22520",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "035",
              name: "CONECUH COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "037",
              name: "COOSA COUNTY",
              state: "AL",
              'CBSA Number': "10760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "039",
              name: "COVINGTON COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "041",
              name: "CRENSHAW COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "043",
              name: "CULLMAN COUNTY",
              state: "AL",
              'CBSA Number': "18980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "045",
              name: "DALE COUNTY",
              state: "AL",
              'CBSA Number': "37120",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "047",
              name: "DALLAS COUNTY",
              state: "AL",
              'CBSA Number': "42820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "049",
              name: "DEKALB COUNTY",
              state: "AL",
              'CBSA Number': "22840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "051",
              name: "ELMORE COUNTY",
              state: "AL",
              'CBSA Number': "33860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "053",
              name: "ESCAMBIA COUNTY",
              state: "AL",
              'CBSA Number': "12120",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "055",
              name: "ETOWAH COUNTY",
              state: "AL",
              'CBSA Number': "23460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "057",
              name: "FAYETTE COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "059",
              name: "FRANKLIN COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "061",
              name: "GENEVA COUNTY",
              state: "AL",
              'CBSA Number': "20020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "063",
              name: "GREENE COUNTY",
              state: "AL",
              'CBSA Number': "46220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "065",
              name: "HALE COUNTY",
              state: "AL",
              'CBSA Number': "46220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "067",
              name: "HENRY COUNTY",
              state: "AL",
              'CBSA Number': "20020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "069",
              name: "HOUSTON COUNTY",
              state: "AL",
              'CBSA Number': "20020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "071",
              name: "JACKSON COUNTY",
              state: "AL",
              'CBSA Number': "42460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "073",
              name: "JEFFERSON COUNTY",
              state: "AL",
              'CBSA Number': "13820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "075",
              name: "LAMAR COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "077",
              name: "LAUDERDALE COUNTY",
              state: "AL",
              'CBSA Number': "22520",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "079",
              name: "LAWRENCE COUNTY",
              state: "AL",
              'CBSA Number': "19460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "081",
              name: "LEE COUNTY",
              state: "AL",
              'CBSA Number': "12220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "083",
              name: "LIMESTONE COUNTY",
              state: "AL",
              'CBSA Number': "26620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "085",
              name: "LOWNDES COUNTY",
              state: "AL",
              'CBSA Number': "33860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "087",
              name: "MACON COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "089",
              name: "MADISON COUNTY",
              state: "AL",
              'CBSA Number': "26620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "091",
              name: "MARENGO COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "093",
              name: "MARION COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "095",
              name: "MARSHALL COUNTY",
              state: "AL",
              'CBSA Number': "10700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "097",
              name: "MOBILE COUNTY",
              state: "AL",
              'CBSA Number': "33660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "099",
              name: "MONROE COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "101",
              name: "MONTGOMERY COUNTY",
              state: "AL",
              'CBSA Number': "33860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "103",
              name: "MORGAN COUNTY",
              state: "AL",
              'CBSA Number': "19460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "105",
              name: "PERRY COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "107",
              name: "PICKENS COUNTY",
              state: "AL",
              'CBSA Number': "46220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "109",
              name: "PIKE COUNTY",
              state: "AL",
              'CBSA Number': "45980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "111",
              name: "RANDOLPH COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "113",
              name: "RUSSELL COUNTY",
              state: "AL",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "115",
              name: "ST. CLAIR COUNTY",
              state: "AL",
              'CBSA Number': "13820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "117",
              name: "SHELBY COUNTY",
              state: "AL",
              'CBSA Number': "13820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "119",
              name: "SUMTER COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "121",
              name: "TALLADEGA COUNTY",
              state: "AL",
              'CBSA Number': "45180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "123",
              name: "TALLAPOOSA COUNTY",
              state: "AL",
              'CBSA Number': "10760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "125",
              name: "TUSCALOOSA COUNTY",
              state: "AL",
              'CBSA Number': "46220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "127",
              name: "WALKER COUNTY",
              state: "AL",
              'CBSA Number': "27530",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "129",
              name: "WASHINGTON COUNTY",
              state: "AL",
              'CBSA Number': "33660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "131",
              name: "WILCOX COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "01",
              countyCode: "133",
              name: "WINSTON COUNTY",
              state: "AL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "02",
              countyCode: "013",
              name: "ALEUTIANS EAST BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "016",
              name: "ALEUTIANS WEST CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "020",
              name: "ANCHORAGE MUNICIPALITY",
              state: "AK",
              'CBSA Number': "11260",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "050",
              name: "BETHEL CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "060",
              name: "BRISTOL BAY BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "068",
              name: "DENALI BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "070",
              name: "DILLINGHAM CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "090",
              name: "FAIRBANKS NORTH STAR BOROUGH",
              state: "AK",
              'CBSA Number': "21820",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "100",
              name: "HAINES BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "105",
              name: "HOONAH-ANGOON CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "110",
              name: "JUNEAU CITY AND BOROUGH",
              state: "AK",
              'CBSA Number': "27940",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "122",
              name: "KENAI PENINSULA BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "130",
              name: "KETCHIKAN GATEWAY BOROUGH",
              state: "AK",
              'CBSA Number': "28540",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "150",
              name: "KODIAK ISLAND BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "158",
              name: "KUSILVAK CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "164",
              name: "LAKE AND PENINSULA BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "170",
              name: "MATANUSKA-SUSITNA BOROUGH",
              state: "AK",
              'CBSA Number': "11260",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "180",
              name: "NOME CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "185",
              name: "NORTH SLOPE BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "188",
              name: "NORTHWEST ARCTIC BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "195",
              name: "PETERSBURG CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "198",
              name: "PRINCE OF WALES-HYDER CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "220",
              name: "SITKA CITY AND BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "230",
              name: "SKAGWAY MUNICIPALITY",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "240",
              name: "SOUTHEAST FAIRBANKS CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "261",
              name: "VALDEZ-CORDOVA CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "275",
              name: "WRANGELL CITY AND BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "282",
              name: "YAKUTAT CITY AND BOROUGH",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "02",
              countyCode: "290",
              name: "YUKON-KOYUKUK CENSUS AREA",
              state: "AK",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "04",
              countyCode: "001",
              name: "APACHE COUNTY",
              state: "AZ",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "003",
              name: "COCHISE COUNTY",
              state: "AZ",
              'CBSA Number': "43420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "005",
              name: "COCONINO COUNTY",
              state: "AZ",
              'CBSA Number': "22380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "007",
              name: "GILA COUNTY",
              state: "AZ",
              'CBSA Number': "37740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "009",
              name: "GRAHAM COUNTY",
              state: "AZ",
              'CBSA Number': "40940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "011",
              name: "GREENLEE COUNTY",
              state: "AZ",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "012",
              name: "LA PAZ COUNTY",
              state: "AZ",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "013",
              name: "MARICOPA COUNTY",
              state: "AZ",
              'CBSA Number': "38060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "015",
              name: "MOHAVE COUNTY",
              state: "AZ",
              'CBSA Number': "29420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "017",
              name: "NAVAJO COUNTY",
              state: "AZ",
              'CBSA Number': "43320",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "019",
              name: "PIMA COUNTY",
              state: "AZ",
              'CBSA Number': "46060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "021",
              name: "PINAL COUNTY",
              state: "AZ",
              'CBSA Number': "38060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "023",
              name: "SANTA CRUZ COUNTY",
              state: "AZ",
              'CBSA Number': "35700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "025",
              name: "YAVAPAI COUNTY",
              state: "AZ",
              'CBSA Number': "39150",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "04",
              countyCode: "027",
              name: "YUMA COUNTY",
              state: "AZ",
              'CBSA Number': "49740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "001",
              name: "ARKANSAS COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "003",
              name: "ASHLEY COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "005",
              name: "BAXTER COUNTY",
              state: "AR",
              'CBSA Number': "34260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "007",
              name: "BENTON COUNTY",
              state: "AR",
              'CBSA Number': "22220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "009",
              name: "BOONE COUNTY",
              state: "AR",
              'CBSA Number': "25460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "011",
              name: "BRADLEY COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "013",
              name: "CALHOUN COUNTY",
              state: "AR",
              'CBSA Number': "15780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "015",
              name: "CARROLL COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "017",
              name: "CHICOT COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "019",
              name: "CLARK COUNTY",
              state: "AR",
              'CBSA Number': "11660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "021",
              name: "CLAY COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "023",
              name: "CLEBURNE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "025",
              name: "CLEVELAND COUNTY",
              state: "AR",
              'CBSA Number': "38220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "027",
              name: "COLUMBIA COUNTY",
              state: "AR",
              'CBSA Number': "31620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "029",
              name: "CONWAY COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "031",
              name: "CRAIGHEAD COUNTY",
              state: "AR",
              'CBSA Number': "27860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "033",
              name: "CRAWFORD COUNTY",
              state: "AR",
              'CBSA Number': "22900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "035",
              name: "CRITTENDEN COUNTY",
              state: "AR",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "037",
              name: "CROSS COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "039",
              name: "DALLAS COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "041",
              name: "DESHA COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "043",
              name: "DREW COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "045",
              name: "FAULKNER COUNTY",
              state: "AR",
              'CBSA Number': "30780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "047",
              name: "FRANKLIN COUNTY",
              state: "AR",
              'CBSA Number': "22900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "049",
              name: "FULTON COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "051",
              name: "GARLAND COUNTY",
              state: "AR",
              'CBSA Number': "26300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "053",
              name: "GRANT COUNTY",
              state: "AR",
              'CBSA Number': "30780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "055",
              name: "GREENE COUNTY",
              state: "AR",
              'CBSA Number': "37500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "057",
              name: "HEMPSTEAD COUNTY",
              state: "AR",
              'CBSA Number': "26260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "059",
              name: "HOT SPRING COUNTY",
              state: "AR",
              'CBSA Number': "31680",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "061",
              name: "HOWARD COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "063",
              name: "INDEPENDENCE COUNTY",
              state: "AR",
              'CBSA Number': "12900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "065",
              name: "IZARD COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "067",
              name: "JACKSON COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "069",
              name: "JEFFERSON COUNTY",
              state: "AR",
              'CBSA Number': "38220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "071",
              name: "JOHNSON COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "073",
              name: "LAFAYETTE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "075",
              name: "LAWRENCE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "077",
              name: "LEE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "079",
              name: "LINCOLN COUNTY",
              state: "AR",
              'CBSA Number': "38220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "081",
              name: "LITTLE RIVER COUNTY",
              state: "AR",
              'CBSA Number': "45500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "083",
              name: "LOGAN COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "085",
              name: "LONOKE COUNTY",
              state: "AR",
              'CBSA Number': "30780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "087",
              name: "MADISON COUNTY",
              state: "AR",
              'CBSA Number': "22220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "089",
              name: "MARION COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "091",
              name: "MILLER COUNTY",
              state: "AR",
              'CBSA Number': "45500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "093",
              name: "MISSISSIPPI COUNTY",
              state: "AR",
              'CBSA Number': "14180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "095",
              name: "MONROE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "097",
              name: "MONTGOMERY COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "099",
              name: "NEVADA COUNTY",
              state: "AR",
              'CBSA Number': "26260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "101",
              name: "NEWTON COUNTY",
              state: "AR",
              'CBSA Number': "25460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "103",
              name: "OUACHITA COUNTY",
              state: "AR",
              'CBSA Number': "15780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "105",
              name: "PERRY COUNTY",
              state: "AR",
              'CBSA Number': "30780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "107",
              name: "PHILLIPS COUNTY",
              state: "AR",
              'CBSA Number': "25760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "109",
              name: "PIKE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "111",
              name: "POINSETT COUNTY",
              state: "AR",
              'CBSA Number': "27860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "113",
              name: "POLK COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "115",
              name: "POPE COUNTY",
              state: "AR",
              'CBSA Number': "40780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "117",
              name: "PRAIRIE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "119",
              name: "PULASKI COUNTY",
              state: "AR",
              'CBSA Number': "30780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "121",
              name: "RANDOLPH COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "123",
              name: "ST. FRANCIS COUNTY",
              state: "AR",
              'CBSA Number': "22620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "125",
              name: "SALINE COUNTY",
              state: "AR",
              'CBSA Number': "30780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "127",
              name: "SCOTT COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "129",
              name: "SEARCY COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "131",
              name: "SEBASTIAN COUNTY",
              state: "AR",
              'CBSA Number': "22900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "133",
              name: "SEVIER COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "135",
              name: "SHARP COUNTY",
              state: "AR",
              'CBSA Number': "12900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "137",
              name: "STONE COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "139",
              name: "UNION COUNTY",
              state: "AR",
              'CBSA Number': "20980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "141",
              name: "VAN BUREN COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "143",
              name: "WASHINGTON COUNTY",
              state: "AR",
              'CBSA Number': "22220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "145",
              name: "WHITE COUNTY",
              state: "AR",
              'CBSA Number': "42620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "147",
              name: "WOODRUFF COUNTY",
              state: "AR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "05",
              countyCode: "149",
              name: "YELL COUNTY",
              state: "AR",
              'CBSA Number': "40780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "001",
              name: "ALAMEDA COUNTY",
              state: "CA",
              'CBSA Number': "41860",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "003",
              name: "ALPINE COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "005",
              name: "AMADOR COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "007",
              name: "BUTTE COUNTY",
              state: "CA",
              'CBSA Number': "17020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "009",
              name: "CALAVERAS COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "011",
              name: "COLUSA COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "013",
              name: "CONTRA COSTA COUNTY",
              state: "CA",
              'CBSA Number': "41860",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "015",
              name: "DEL NORTE COUNTY",
              state: "CA",
              'CBSA Number': "18860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "017",
              name: "EL DORADO COUNTY",
              state: "CA",
              'CBSA Number': "40900",
              oneUnit: " $598,000 ",
              twoUnit: " $765,550 ",
              threeUnit: " $925,350 ",
              fourUnit: " $1,150,000 "
            },
            {
              stateCode: "06",
              countyCode: "019",
              name: "FRESNO COUNTY",
              state: "CA",
              'CBSA Number': "23420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "021",
              name: "GLENN COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "023",
              name: "HUMBOLDT COUNTY",
              state: "CA",
              'CBSA Number': "21700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "025",
              name: "IMPERIAL COUNTY",
              state: "CA",
              'CBSA Number': "20940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "027",
              name: "INYO COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "029",
              name: "KERN COUNTY",
              state: "CA",
              'CBSA Number': "12540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "031",
              name: "KINGS COUNTY",
              state: "CA",
              'CBSA Number': "25260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "033",
              name: "LAKE COUNTY",
              state: "CA",
              'CBSA Number': "17340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "035",
              name: "LASSEN COUNTY",
              state: "CA",
              'CBSA Number': "45000",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "037",
              name: "LOS ANGELES COUNTY",
              state: "CA",
              'CBSA Number': "31080",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "039",
              name: "MADERA COUNTY",
              state: "CA",
              'CBSA Number': "31460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "041",
              name: "MARIN COUNTY",
              state: "CA",
              'CBSA Number': "41860",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "043",
              name: "MARIPOSA COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "045",
              name: "MENDOCINO COUNTY",
              state: "CA",
              'CBSA Number': "46380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "047",
              name: "MERCED COUNTY",
              state: "CA",
              'CBSA Number': "32900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "049",
              name: "MODOC COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "051",
              name: "MONO COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "053",
              name: "MONTEREY COUNTY",
              state: "CA",
              'CBSA Number': "41500",
              oneUnit: " $739,450 ",
              twoUnit: " $946,650 ",
              threeUnit: " $1,144,250 ",
              fourUnit: " $1,422,050 "
            },
            {
              stateCode: "06",
              countyCode: "055",
              name: "NAPA COUNTY",
              state: "CA",
              'CBSA Number': "34900",
              oneUnit: " $816,500 ",
              twoUnit: " $1,045,250 ",
              threeUnit: " $1,263,500 ",
              fourUnit: " $1,570,200 "
            },
            {
              stateCode: "06",
              countyCode: "057",
              name: "NEVADA COUNTY",
              state: "CA",
              'CBSA Number': "46020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "059",
              name: "ORANGE COUNTY",
              state: "CA",
              'CBSA Number': "31080",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "061",
              name: "PLACER COUNTY",
              state: "CA",
              'CBSA Number': "40900",
              oneUnit: " $598,000 ",
              twoUnit: " $765,550 ",
              threeUnit: " $925,350 ",
              fourUnit: " $1,150,000 "
            },
            {
              stateCode: "06",
              countyCode: "063",
              name: "PLUMAS COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "065",
              name: "RIVERSIDE COUNTY",
              state: "CA",
              'CBSA Number': "40140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "067",
              name: "SACRAMENTO COUNTY",
              state: "CA",
              'CBSA Number': "40900",
              oneUnit: " $598,000 ",
              twoUnit: " $765,550 ",
              threeUnit: " $925,350 ",
              fourUnit: " $1,150,000 "
            },
            {
              stateCode: "06",
              countyCode: "069",
              name: "SAN BENITO COUNTY",
              state: "CA",
              'CBSA Number': "41940",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "071",
              name: "SAN BERNARDINO COUNTY",
              state: "CA",
              'CBSA Number': "40140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "073",
              name: "SAN DIEGO COUNTY",
              state: "CA",
              'CBSA Number': "41740",
              oneUnit: " $753,250 ",
              twoUnit: " $964,300 ",
              threeUnit: " $1,165,600 ",
              fourUnit: " $1,448,600 "
            },
            {
              stateCode: "06",
              countyCode: "075",
              name: "SAN FRANCISCO COUNTY",
              state: "CA",
              'CBSA Number': "41860",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "077",
              name: "SAN JOAQUIN COUNTY",
              state: "CA",
              'CBSA Number': "44700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "079",
              name: "SAN LUIS OBISPO COUNTY",
              state: "CA",
              'CBSA Number': "42020",
              oneUnit: " $701,500 ",
              twoUnit: " $898,050 ",
              threeUnit: " $1,085,550 ",
              fourUnit: " $1,349,050 "
            },
            {
              stateCode: "06",
              countyCode: "081",
              name: "SAN MATEO COUNTY",
              state: "CA",
              'CBSA Number': "41860",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "083",
              name: "SANTA BARBARA COUNTY",
              state: "CA",
              'CBSA Number': "42200",
              oneUnit: " $660,100 ",
              twoUnit: " $845,050 ",
              threeUnit: " $1,021,450 ",
              fourUnit: " $1,269,450 "
            },
            {
              stateCode: "06",
              countyCode: "085",
              name: "SANTA CLARA COUNTY",
              state: "CA",
              'CBSA Number': "41940",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "087",
              name: "SANTA CRUZ COUNTY",
              state: "CA",
              'CBSA Number': "42100",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "06",
              countyCode: "089",
              name: "SHASTA COUNTY",
              state: "CA",
              'CBSA Number': "39820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "091",
              name: "SIERRA COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "093",
              name: "SISKIYOU COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "095",
              name: "SOLANO COUNTY",
              state: "CA",
              'CBSA Number': "46700",
              oneUnit: " $550,850 ",
              twoUnit: " $705,200 ",
              threeUnit: " $852,400 ",
              fourUnit: " $1,059,350 "
            },
            {
              stateCode: "06",
              countyCode: "097",
              name: "SONOMA COUNTY",
              state: "CA",
              'CBSA Number': "42220",
              oneUnit: " $707,250 ",
              twoUnit: " $905,400 ",
              threeUnit: " $1,094,450 ",
              fourUnit: " $1,360,100 "
            },
            {
              stateCode: "06",
              countyCode: "099",
              name: "STANISLAUS COUNTY",
              state: "CA",
              'CBSA Number': "33700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "101",
              name: "SUTTER COUNTY",
              state: "CA",
              'CBSA Number': "49700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "103",
              name: "TEHAMA COUNTY",
              state: "CA",
              'CBSA Number': "39780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "105",
              name: "TRINITY COUNTY",
              state: "CA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "107",
              name: "TULARE COUNTY",
              state: "CA",
              'CBSA Number': "47300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "109",
              name: "TUOLUMNE COUNTY",
              state: "CA",
              'CBSA Number': "43760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "06",
              countyCode: "111",
              name: "VENTURA COUNTY",
              state: "CA",
              'CBSA Number': "37100",
              oneUnit: " $739,450 ",
              twoUnit: " $946,650 ",
              threeUnit: " $1,144,250 ",
              fourUnit: " $1,422,050 "
            },
            {
              stateCode: "06",
              countyCode: "113",
              name: "YOLO COUNTY",
              state: "CA",
              'CBSA Number': "40900",
              oneUnit: " $598,000 ",
              twoUnit: " $765,550 ",
              threeUnit: " $925,350 ",
              fourUnit: " $1,150,000 "
            },
            {
              stateCode: "06",
              countyCode: "115",
              name: "YUBA COUNTY",
              state: "CA",
              'CBSA Number': "49700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "003",
              name: "ALAMOSA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "005",
              name: "ARAPAHOE COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "007",
              name: "ARCHULETA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "009",
              name: "BACA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "011",
              name: "BENT COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "013",
              name: "BOULDER COUNTY",
              state: "CO",
              'CBSA Number': "14500",
              oneUnit: " $654,350 ",
              twoUnit: " $837,700 ",
              threeUnit: " $1,012,550 ",
              fourUnit: " $1,258,400 "
            },
            {
              stateCode: "08",
              countyCode: "014",
              name: "BROOMFIELD COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "015",
              name: "CHAFFEE COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "017",
              name: "CHEYENNE COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "019",
              name: "CLEAR CREEK COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "021",
              name: "CONEJOS COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "023",
              name: "COSTILLA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "025",
              name: "CROWLEY COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "027",
              name: "CUSTER COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "029",
              name: "DELTA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "031",
              name: "DENVER COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "033",
              name: "DOLORES COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "035",
              name: "DOUGLAS COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "037",
              name: "EAGLE COUNTY",
              state: "CO",
              'CBSA Number': "20780",
              oneUnit: " $822,250 ",
              twoUnit: " $1,052,650 ",
              threeUnit: " $1,272,400 ",
              fourUnit: " $1,581,300 "
            },
            {
              stateCode: "08",
              countyCode: "039",
              name: "ELBERT COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "041",
              name: "EL PASO COUNTY",
              state: "CO",
              'CBSA Number': "17820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "043",
              name: "FREMONT COUNTY",
              state: "CO",
              'CBSA Number': "15860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "045",
              name: "GARFIELD COUNTY",
              state: "CO",
              'CBSA Number': "24060",
              oneUnit: " $765,600 ",
              twoUnit: " $980,325 ",
              threeUnit: " $1,184,925 ",
              fourUnit: " $1,472,550 "
            },
            {
              stateCode: "08",
              countyCode: "047",
              name: "GILPIN COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "049",
              name: "GRAND COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "051",
              name: "GUNNISON COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "053",
              name: "HINSDALE COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "055",
              name: "HUERFANO COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "057",
              name: "JACKSON COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "059",
              name: "JEFFERSON COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "061",
              name: "KIOWA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "063",
              name: "KIT CARSON COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "065",
              name: "LAKE COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "08",
              countyCode: "067",
              name: "LA PLATA COUNTY",
              state: "CO",
              'CBSA Number': "20420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "069",
              name: "LARIMER COUNTY",
              state: "CO",
              'CBSA Number': "22660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "071",
              name: "LAS ANIMAS COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "073",
              name: "LINCOLN COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "075",
              name: "LOGAN COUNTY",
              state: "CO",
              'CBSA Number': "44540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "077",
              name: "MESA COUNTY",
              state: "CO",
              'CBSA Number': "24300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "079",
              name: "MINERAL COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "081",
              name: "MOFFAT COUNTY",
              state: "CO",
              'CBSA Number': "18780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "083",
              name: "MONTEZUMA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "085",
              name: "MONTROSE COUNTY",
              state: "CO",
              'CBSA Number': "33940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "087",
              name: "MORGAN COUNTY",
              state: "CO",
              'CBSA Number': "22820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "089",
              name: "OTERO COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "091",
              name: "OURAY COUNTY",
              state: "CO",
              'CBSA Number': "33940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "093",
              name: "PARK COUNTY",
              state: "CO",
              'CBSA Number': "19740",
              oneUnit: " $596,850 ",
              twoUnit: " $764,050 ",
              threeUnit: " $923,600 ",
              fourUnit: " $1,147,800 "
            },
            {
              stateCode: "08",
              countyCode: "095",
              name: "PHILLIPS COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "097",
              name: "PITKIN COUNTY",
              state: "CO",
              'CBSA Number': "24060",
              oneUnit: " $765,600 ",
              twoUnit: " $980,325 ",
              threeUnit: " $1,184,925 ",
              fourUnit: " $1,472,550 "
            },
            {
              stateCode: "08",
              countyCode: "099",
              name: "PROWERS COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "101",
              name: "PUEBLO COUNTY",
              state: "CO",
              'CBSA Number': "39380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "103",
              name: "RIO BLANCO COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "105",
              name: "RIO GRANDE COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "107",
              name: "ROUTT COUNTY",
              state: "CO",
              'CBSA Number': "44460",
              oneUnit: " $678,500 ",
              twoUnit: " $868,600 ",
              threeUnit: " $1,049,950 ",
              fourUnit: " $1,304,850 "
            },
            {
              stateCode: "08",
              countyCode: "109",
              name: "SAGUACHE COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "111",
              name: "SAN JUAN COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "113",
              name: "SAN MIGUEL COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $649,750 ",
              twoUnit: " $831,800 ",
              threeUnit: " $1,005,450 ",
              fourUnit: " $1,249,550 "
            },
            {
              stateCode: "08",
              countyCode: "115",
              name: "SEDGWICK COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "117",
              name: "SUMMIT COUNTY",
              state: "CO",
              'CBSA Number': "14720",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "08",
              countyCode: "119",
              name: "TELLER COUNTY",
              state: "CO",
              'CBSA Number': "17820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "121",
              name: "WASHINGTON COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "123",
              name: "WELD COUNTY",
              state: "CO",
              'CBSA Number': "24540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "08",
              countyCode: "125",
              name: "YUMA COUNTY",
              state: "CO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "001",
              name: "FAIRFIELD COUNTY",
              state: "CT",
              'CBSA Number': "14860",
              oneUnit: " $601,450 ",
              twoUnit: " $769,950 ",
              threeUnit: " $930,700 ",
              fourUnit: " $1,156,650 "
            },
            {
              stateCode: "09",
              countyCode: "003",
              name: "HARTFORD COUNTY",
              state: "CT",
              'CBSA Number': "25540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "005",
              name: "LITCHFIELD COUNTY",
              state: "CT",
              'CBSA Number': "45860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "007",
              name: "MIDDLESEX COUNTY",
              state: "CT",
              'CBSA Number': "25540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "009",
              name: "NEW HAVEN COUNTY",
              state: "CT",
              'CBSA Number': "35300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "011",
              name: "NEW LONDON COUNTY",
              state: "CT",
              'CBSA Number': "35980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "013",
              name: "TOLLAND COUNTY",
              state: "CT",
              'CBSA Number': "25540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "09",
              countyCode: "015",
              name: "WINDHAM COUNTY",
              state: "CT",
              'CBSA Number': "49340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "10",
              countyCode: "001",
              name: "KENT COUNTY",
              state: "DE",
              'CBSA Number': "20100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "10",
              countyCode: "003",
              name: "NEW CASTLE COUNTY",
              state: "DE",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "10",
              countyCode: "005",
              name: "SUSSEX COUNTY",
              state: "DE",
              'CBSA Number': "41540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "11",
              countyCode: "001",
              name: "DISTRICT OF COLUMBIA",
              state: "DC",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "12",
              countyCode: "001",
              name: "ALACHUA COUNTY",
              state: "FL",
              'CBSA Number': "23540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "003",
              name: "BAKER COUNTY",
              state: "FL",
              'CBSA Number': "27260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "005",
              name: "BAY COUNTY",
              state: "FL",
              'CBSA Number': "37460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "007",
              name: "BRADFORD COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "009",
              name: "BREVARD COUNTY",
              state: "FL",
              'CBSA Number': "37340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "011",
              name: "BROWARD COUNTY",
              state: "FL",
              'CBSA Number': "33100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "013",
              name: "CALHOUN COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "015",
              name: "CHARLOTTE COUNTY",
              state: "FL",
              'CBSA Number': "39460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "017",
              name: "CITRUS COUNTY",
              state: "FL",
              'CBSA Number': "26140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "019",
              name: "CLAY COUNTY",
              state: "FL",
              'CBSA Number': "27260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "021",
              name: "COLLIER COUNTY",
              state: "FL",
              'CBSA Number': "34940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "023",
              name: "COLUMBIA COUNTY",
              state: "FL",
              'CBSA Number': "29380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "027",
              name: "DESOTO COUNTY",
              state: "FL",
              'CBSA Number': "11580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "029",
              name: "DIXIE COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "031",
              name: "DUVAL COUNTY",
              state: "FL",
              'CBSA Number': "27260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "033",
              name: "ESCAMBIA COUNTY",
              state: "FL",
              'CBSA Number': "37860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "035",
              name: "FLAGLER COUNTY",
              state: "FL",
              'CBSA Number': "19660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "037",
              name: "FRANKLIN COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "039",
              name: "GADSDEN COUNTY",
              state: "FL",
              'CBSA Number': "45220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "041",
              name: "GILCHRIST COUNTY",
              state: "FL",
              'CBSA Number': "23540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "043",
              name: "GLADES COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "045",
              name: "GULF COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "047",
              name: "HAMILTON COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "049",
              name: "HARDEE COUNTY",
              state: "FL",
              'CBSA Number': "48100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "051",
              name: "HENDRY COUNTY",
              state: "FL",
              'CBSA Number': "17500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "053",
              name: "HERNANDO COUNTY",
              state: "FL",
              'CBSA Number': "45300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "055",
              name: "HIGHLANDS COUNTY",
              state: "FL",
              'CBSA Number': "42700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "057",
              name: "HILLSBOROUGH COUNTY",
              state: "FL",
              'CBSA Number': "45300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "059",
              name: "HOLMES COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "061",
              name: "INDIAN RIVER COUNTY",
              state: "FL",
              'CBSA Number': "42680",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "063",
              name: "JACKSON COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "065",
              name: "JEFFERSON COUNTY",
              state: "FL",
              'CBSA Number': "45220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "067",
              name: "LAFAYETTE COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "069",
              name: "LAKE COUNTY",
              state: "FL",
              'CBSA Number': "36740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "071",
              name: "LEE COUNTY",
              state: "FL",
              'CBSA Number': "15980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "073",
              name: "LEON COUNTY",
              state: "FL",
              'CBSA Number': "45220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "075",
              name: "LEVY COUNTY",
              state: "FL",
              'CBSA Number': "23540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "077",
              name: "LIBERTY COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "079",
              name: "MADISON COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "081",
              name: "MANATEE COUNTY",
              state: "FL",
              'CBSA Number': "35840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "083",
              name: "MARION COUNTY",
              state: "FL",
              'CBSA Number': "36100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "085",
              name: "MARTIN COUNTY",
              state: "FL",
              'CBSA Number': "38940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "086",
              name: "MIAMI-DADE COUNTY",
              state: "FL",
              'CBSA Number': "33100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "087",
              name: "MONROE COUNTY",
              state: "FL",
              'CBSA Number': "28580",
              oneUnit: " $608,350 ",
              twoUnit: " $778,800 ",
              threeUnit: " $941,400 ",
              fourUnit: " $1,169,900 "
            },
            {
              stateCode: "12",
              countyCode: "089",
              name: "NASSAU COUNTY",
              state: "FL",
              'CBSA Number': "27260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "091",
              name: "OKALOOSA COUNTY",
              state: "FL",
              'CBSA Number': "18880",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "093",
              name: "OKEECHOBEE COUNTY",
              state: "FL",
              'CBSA Number': "36380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "095",
              name: "ORANGE COUNTY",
              state: "FL",
              'CBSA Number': "36740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "097",
              name: "OSCEOLA COUNTY",
              state: "FL",
              'CBSA Number': "36740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "099",
              name: "PALM BEACH COUNTY",
              state: "FL",
              'CBSA Number': "33100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "101",
              name: "PASCO COUNTY",
              state: "FL",
              'CBSA Number': "45300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "103",
              name: "PINELLAS COUNTY",
              state: "FL",
              'CBSA Number': "45300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "105",
              name: "POLK COUNTY",
              state: "FL",
              'CBSA Number': "29460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "107",
              name: "PUTNAM COUNTY",
              state: "FL",
              'CBSA Number': "37260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "109",
              name: "ST. JOHNS COUNTY",
              state: "FL",
              'CBSA Number': "27260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "111",
              name: "ST. LUCIE COUNTY",
              state: "FL",
              'CBSA Number': "38940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "113",
              name: "SANTA ROSA COUNTY",
              state: "FL",
              'CBSA Number': "37860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "115",
              name: "SARASOTA COUNTY",
              state: "FL",
              'CBSA Number': "35840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "117",
              name: "SEMINOLE COUNTY",
              state: "FL",
              'CBSA Number': "36740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "119",
              name: "SUMTER COUNTY",
              state: "FL",
              'CBSA Number': "45540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "121",
              name: "SUWANNEE COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "123",
              name: "TAYLOR COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "125",
              name: "UNION COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "127",
              name: "VOLUSIA COUNTY",
              state: "FL",
              'CBSA Number': "19660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "129",
              name: "WAKULLA COUNTY",
              state: "FL",
              'CBSA Number': "45220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "131",
              name: "WALTON COUNTY",
              state: "FL",
              'CBSA Number': "18880",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "12",
              countyCode: "133",
              name: "WASHINGTON COUNTY",
              state: "FL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "001",
              name: "APPLING COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "003",
              name: "ATKINSON COUNTY",
              state: "GA",
              'CBSA Number': "20060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "005",
              name: "BACON COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "007",
              name: "BAKER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "009",
              name: "BALDWIN COUNTY",
              state: "GA",
              'CBSA Number': "33300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "011",
              name: "BANKS COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "013",
              name: "BARROW COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "015",
              name: "BARTOW COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "017",
              name: "BEN HILL COUNTY",
              state: "GA",
              'CBSA Number': "22340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "019",
              name: "BERRIEN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "021",
              name: "BIBB COUNTY",
              state: "GA",
              'CBSA Number': "31420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "023",
              name: "BLECKLEY COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "025",
              name: "BRANTLEY COUNTY",
              state: "GA",
              'CBSA Number': "15260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "027",
              name: "BROOKS COUNTY",
              state: "GA",
              'CBSA Number': "46660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "029",
              name: "BRYAN COUNTY",
              state: "GA",
              'CBSA Number': "42340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "031",
              name: "BULLOCH COUNTY",
              state: "GA",
              'CBSA Number': "44340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "033",
              name: "BURKE COUNTY",
              state: "GA",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "035",
              name: "BUTTS COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "037",
              name: "CALHOUN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "039",
              name: "CAMDEN COUNTY",
              state: "GA",
              'CBSA Number': "41220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "043",
              name: "CANDLER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "045",
              name: "CARROLL COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "047",
              name: "CATOOSA COUNTY",
              state: "GA",
              'CBSA Number': "16860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "049",
              name: "CHARLTON COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "051",
              name: "CHATHAM COUNTY",
              state: "GA",
              'CBSA Number': "42340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "053",
              name: "CHATTAHOOCHEE COUNTY",
              state: "GA",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "055",
              name: "CHATTOOGA COUNTY",
              state: "GA",
              'CBSA Number': "44900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "057",
              name: "CHEROKEE COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "059",
              name: "CLARKE COUNTY",
              state: "GA",
              'CBSA Number': "12020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "061",
              name: "CLAY COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "063",
              name: "CLAYTON COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "065",
              name: "CLINCH COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "067",
              name: "COBB COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "069",
              name: "COFFEE COUNTY",
              state: "GA",
              'CBSA Number': "20060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "071",
              name: "COLQUITT COUNTY",
              state: "GA",
              'CBSA Number': "34220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "073",
              name: "COLUMBIA COUNTY",
              state: "GA",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "075",
              name: "COOK COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "077",
              name: "COWETA COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "079",
              name: "CRAWFORD COUNTY",
              state: "GA",
              'CBSA Number': "31420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "081",
              name: "CRISP COUNTY",
              state: "GA",
              'CBSA Number': "18380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "083",
              name: "DADE COUNTY",
              state: "GA",
              'CBSA Number': "16860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "085",
              name: "DAWSON COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "087",
              name: "DECATUR COUNTY",
              state: "GA",
              'CBSA Number': "12460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "089",
              name: "DEKALB COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "091",
              name: "DODGE COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "093",
              name: "DOOLY COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "095",
              name: "DOUGHERTY COUNTY",
              state: "GA",
              'CBSA Number': "10500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "097",
              name: "DOUGLAS COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "099",
              name: "EARLY COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "101",
              name: "ECHOLS COUNTY",
              state: "GA",
              'CBSA Number': "46660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "103",
              name: "EFFINGHAM COUNTY",
              state: "GA",
              'CBSA Number': "42340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "105",
              name: "ELBERT COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "107",
              name: "EMANUEL COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "109",
              name: "EVANS COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "111",
              name: "FANNIN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "113",
              name: "FAYETTE COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "115",
              name: "FLOYD COUNTY",
              state: "GA",
              'CBSA Number': "40660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "117",
              name: "FORSYTH COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "119",
              name: "FRANKLIN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "121",
              name: "FULTON COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "123",
              name: "GILMER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "125",
              name: "GLASCOCK COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "127",
              name: "GLYNN COUNTY",
              state: "GA",
              'CBSA Number': "15260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "129",
              name: "GORDON COUNTY",
              state: "GA",
              'CBSA Number': "15660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "131",
              name: "GRADY COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "133",
              name: "GREENE COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "135",
              name: "GWINNETT COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "137",
              name: "HABERSHAM COUNTY",
              state: "GA",
              'CBSA Number': "18460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "139",
              name: "HALL COUNTY",
              state: "GA",
              'CBSA Number': "23580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "141",
              name: "HANCOCK COUNTY",
              state: "GA",
              'CBSA Number': "33300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "143",
              name: "HARALSON COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "145",
              name: "HARRIS COUNTY",
              state: "GA",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "147",
              name: "HART COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "149",
              name: "HEARD COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "151",
              name: "HENRY COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "153",
              name: "HOUSTON COUNTY",
              state: "GA",
              'CBSA Number': "47580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "155",
              name: "IRWIN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "157",
              name: "JACKSON COUNTY",
              state: "GA",
              'CBSA Number': "27600",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "159",
              name: "JASPER COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "161",
              name: "JEFF DAVIS COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "163",
              name: "JEFFERSON COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "165",
              name: "JENKINS COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "167",
              name: "JOHNSON COUNTY",
              state: "GA",
              'CBSA Number': "20140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "169",
              name: "JONES COUNTY",
              state: "GA",
              'CBSA Number': "31420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "171",
              name: "LAMAR COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "173",
              name: "LANIER COUNTY",
              state: "GA",
              'CBSA Number': "46660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "175",
              name: "LAURENS COUNTY",
              state: "GA",
              'CBSA Number': "20140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "177",
              name: "LEE COUNTY",
              state: "GA",
              'CBSA Number': "10500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "179",
              name: "LIBERTY COUNTY",
              state: "GA",
              'CBSA Number': "25980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "181",
              name: "LINCOLN COUNTY",
              state: "GA",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "183",
              name: "LONG COUNTY",
              state: "GA",
              'CBSA Number': "25980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "185",
              name: "LOWNDES COUNTY",
              state: "GA",
              'CBSA Number': "46660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "187",
              name: "LUMPKIN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "189",
              name: "MCDUFFIE COUNTY",
              state: "GA",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "191",
              name: "MCINTOSH COUNTY",
              state: "GA",
              'CBSA Number': "15260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "193",
              name: "MACON COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "195",
              name: "MADISON COUNTY",
              state: "GA",
              'CBSA Number': "12020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "197",
              name: "MARION COUNTY",
              state: "GA",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "199",
              name: "MERIWETHER COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "201",
              name: "MILLER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "205",
              name: "MITCHELL COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "207",
              name: "MONROE COUNTY",
              state: "GA",
              'CBSA Number': "31420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "209",
              name: "MONTGOMERY COUNTY",
              state: "GA",
              'CBSA Number': "47080",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "211",
              name: "MORGAN COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "213",
              name: "MURRAY COUNTY",
              state: "GA",
              'CBSA Number': "19140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "215",
              name: "MUSCOGEE COUNTY",
              state: "GA",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "217",
              name: "NEWTON COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "219",
              name: "OCONEE COUNTY",
              state: "GA",
              'CBSA Number': "12020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "221",
              name: "OGLETHORPE COUNTY",
              state: "GA",
              'CBSA Number': "12020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "223",
              name: "PAULDING COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "225",
              name: "PEACH COUNTY",
              state: "GA",
              'CBSA Number': "47580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "227",
              name: "PICKENS COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "229",
              name: "PIERCE COUNTY",
              state: "GA",
              'CBSA Number': "48180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "231",
              name: "PIKE COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "233",
              name: "POLK COUNTY",
              state: "GA",
              'CBSA Number': "16340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "235",
              name: "PULASKI COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "237",
              name: "PUTNAM COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "239",
              name: "QUITMAN COUNTY",
              state: "GA",
              'CBSA Number': "21640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "241",
              name: "RABUN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "243",
              name: "RANDOLPH COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "245",
              name: "RICHMOND COUNTY",
              state: "GA",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "247",
              name: "ROCKDALE COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "249",
              name: "SCHLEY COUNTY",
              state: "GA",
              'CBSA Number': "11140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "251",
              name: "SCREVEN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "253",
              name: "SEMINOLE COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "255",
              name: "SPALDING COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "257",
              name: "STEPHENS COUNTY",
              state: "GA",
              'CBSA Number': "45740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "259",
              name: "STEWART COUNTY",
              state: "GA",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "261",
              name: "SUMTER COUNTY",
              state: "GA",
              'CBSA Number': "11140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "263",
              name: "TALBOT COUNTY",
              state: "GA",
              'CBSA Number': "17980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "265",
              name: "TALIAFERRO COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "267",
              name: "TATTNALL COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "269",
              name: "TAYLOR COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "271",
              name: "TELFAIR COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "273",
              name: "TERRELL COUNTY",
              state: "GA",
              'CBSA Number': "10500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "275",
              name: "THOMAS COUNTY",
              state: "GA",
              'CBSA Number': "45620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "277",
              name: "TIFT COUNTY",
              state: "GA",
              'CBSA Number': "45700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "279",
              name: "TOOMBS COUNTY",
              state: "GA",
              'CBSA Number': "47080",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "281",
              name: "TOWNS COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "283",
              name: "TREUTLEN COUNTY",
              state: "GA",
              'CBSA Number': "20140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "285",
              name: "TROUP COUNTY",
              state: "GA",
              'CBSA Number': "29300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "287",
              name: "TURNER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "289",
              name: "TWIGGS COUNTY",
              state: "GA",
              'CBSA Number': "31420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "291",
              name: "UNION COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "293",
              name: "UPSON COUNTY",
              state: "GA",
              'CBSA Number': "45580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "295",
              name: "WALKER COUNTY",
              state: "GA",
              'CBSA Number': "16860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "297",
              name: "WALTON COUNTY",
              state: "GA",
              'CBSA Number': "12060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "299",
              name: "WARE COUNTY",
              state: "GA",
              'CBSA Number': "48180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "301",
              name: "WARREN COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "303",
              name: "WASHINGTON COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "305",
              name: "WAYNE COUNTY",
              state: "GA",
              'CBSA Number': "27700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "307",
              name: "WEBSTER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "309",
              name: "WHEELER COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "311",
              name: "WHITE COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "313",
              name: "WHITFIELD COUNTY",
              state: "GA",
              'CBSA Number': "19140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "315",
              name: "WILCOX COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "317",
              name: "WILKES COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "319",
              name: "WILKINSON COUNTY",
              state: "GA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "13",
              countyCode: "321",
              name: "WORTH COUNTY",
              state: "GA",
              'CBSA Number': "10500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "15",
              countyCode: "001",
              name: "HAWAII COUNTY",
              state: "HI",
              'CBSA Number': "25900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "15",
              countyCode: "003",
              name: "HONOLULU COUNTY",
              state: "HI",
              'CBSA Number': "46520",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "15",
              countyCode: "005",
              name: "KALAWAO COUNTY",
              state: "HI",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "15",
              countyCode: "007",
              name: "KAUAI COUNTY",
              state: "HI",
              'CBSA Number': "28180",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "15",
              countyCode: "009",
              name: "MAUI COUNTY",
              state: "HI",
              'CBSA Number': "27980",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "16",
              countyCode: "001",
              name: "ADA COUNTY",
              state: "ID",
              'CBSA Number': "14260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "003",
              name: "ADAMS COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "005",
              name: "BANNOCK COUNTY",
              state: "ID",
              'CBSA Number': "38540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "007",
              name: "BEAR LAKE COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "009",
              name: "BENEWAH COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "011",
              name: "BINGHAM COUNTY",
              state: "ID",
              'CBSA Number': "13940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "013",
              name: "BLAINE COUNTY",
              state: "ID",
              'CBSA Number': "25200",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "16",
              countyCode: "015",
              name: "BOISE COUNTY",
              state: "ID",
              'CBSA Number': "14260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "017",
              name: "BONNER COUNTY",
              state: "ID",
              'CBSA Number': "41760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "019",
              name: "BONNEVILLE COUNTY",
              state: "ID",
              'CBSA Number': "26820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "021",
              name: "BOUNDARY COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "023",
              name: "BUTTE COUNTY",
              state: "ID",
              'CBSA Number': "26820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "025",
              name: "CAMAS COUNTY",
              state: "ID",
              'CBSA Number': "25200",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "16",
              countyCode: "027",
              name: "CANYON COUNTY",
              state: "ID",
              'CBSA Number': "14260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "029",
              name: "CARIBOU COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "031",
              name: "CASSIA COUNTY",
              state: "ID",
              'CBSA Number': "15420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "033",
              name: "CLARK COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "035",
              name: "CLEARWATER COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "037",
              name: "CUSTER COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "039",
              name: "ELMORE COUNTY",
              state: "ID",
              'CBSA Number': "34300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "041",
              name: "FRANKLIN COUNTY",
              state: "ID",
              'CBSA Number': "30860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "043",
              name: "FREMONT COUNTY",
              state: "ID",
              'CBSA Number': "39940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "045",
              name: "GEM COUNTY",
              state: "ID",
              'CBSA Number': "14260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "047",
              name: "GOODING COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "049",
              name: "IDAHO COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "051",
              name: "JEFFERSON COUNTY",
              state: "ID",
              'CBSA Number': "26820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "053",
              name: "JEROME COUNTY",
              state: "ID",
              'CBSA Number': "46300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "055",
              name: "KOOTENAI COUNTY",
              state: "ID",
              'CBSA Number': "17660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "057",
              name: "LATAH COUNTY",
              state: "ID",
              'CBSA Number': "34140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "059",
              name: "LEMHI COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "061",
              name: "LEWIS COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "063",
              name: "LINCOLN COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "16",
              countyCode: "065",
              name: "MADISON COUNTY",
              state: "ID",
              'CBSA Number': "39940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "067",
              name: "MINIDOKA COUNTY",
              state: "ID",
              'CBSA Number': "15420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "069",
              name: "NEZ PERCE COUNTY",
              state: "ID",
              'CBSA Number': "30300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "071",
              name: "ONEIDA COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "073",
              name: "OWYHEE COUNTY",
              state: "ID",
              'CBSA Number': "14260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "075",
              name: "PAYETTE COUNTY",
              state: "ID",
              'CBSA Number': "36620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "077",
              name: "POWER COUNTY",
              state: "ID",
              'CBSA Number': "38540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "079",
              name: "SHOSHONE COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "081",
              name: "TETON COUNTY",
              state: "ID",
              'CBSA Number': "27220",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "16",
              countyCode: "083",
              name: "TWIN FALLS COUNTY",
              state: "ID",
              'CBSA Number': "46300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "085",
              name: "VALLEY COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "16",
              countyCode: "087",
              name: "WASHINGTON COUNTY",
              state: "ID",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "IL",
              'CBSA Number': "39500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "003",
              name: "ALEXANDER COUNTY",
              state: "IL",
              'CBSA Number': "16020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "005",
              name: "BOND COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "007",
              name: "BOONE COUNTY",
              state: "IL",
              'CBSA Number': "40420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "009",
              name: "BROWN COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "011",
              name: "BUREAU COUNTY",
              state: "IL",
              'CBSA Number': "36837",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "013",
              name: "CALHOUN COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "015",
              name: "CARROLL COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "017",
              name: "CASS COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "019",
              name: "CHAMPAIGN COUNTY",
              state: "IL",
              'CBSA Number': "16580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "021",
              name: "CHRISTIAN COUNTY",
              state: "IL",
              'CBSA Number': "45380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "023",
              name: "CLARK COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "025",
              name: "CLAY COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "027",
              name: "CLINTON COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "029",
              name: "COLES COUNTY",
              state: "IL",
              'CBSA Number': "16660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "031",
              name: "COOK COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "033",
              name: "CRAWFORD COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "035",
              name: "CUMBERLAND COUNTY",
              state: "IL",
              'CBSA Number': "16660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "037",
              name: "DEKALB COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "039",
              name: "DE WITT COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "041",
              name: "DOUGLAS COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "043",
              name: "DUPAGE COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "045",
              name: "EDGAR COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "047",
              name: "EDWARDS COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "049",
              name: "EFFINGHAM COUNTY",
              state: "IL",
              'CBSA Number': "20820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "051",
              name: "FAYETTE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "053",
              name: "FORD COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "055",
              name: "FRANKLIN COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "057",
              name: "FULTON COUNTY",
              state: "IL",
              'CBSA Number': "37900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "059",
              name: "GALLATIN COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "061",
              name: "GREENE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "063",
              name: "GRUNDY COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "065",
              name: "HAMILTON COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "067",
              name: "HANCOCK COUNTY",
              state: "IL",
              'CBSA Number': "22800",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "069",
              name: "HARDIN COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "071",
              name: "HENDERSON COUNTY",
              state: "IL",
              'CBSA Number': "15460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "073",
              name: "HENRY COUNTY",
              state: "IL",
              'CBSA Number': "19340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "075",
              name: "IROQUOIS COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "077",
              name: "JACKSON COUNTY",
              state: "IL",
              'CBSA Number': "16060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "079",
              name: "JASPER COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "081",
              name: "JEFFERSON COUNTY",
              state: "IL",
              'CBSA Number': "34500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "083",
              name: "JERSEY COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "085",
              name: "JO DAVIESS COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "087",
              name: "JOHNSON COUNTY",
              state: "IL",
              'CBSA Number': "16060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "089",
              name: "KANE COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "091",
              name: "KANKAKEE COUNTY",
              state: "IL",
              'CBSA Number': "28100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "093",
              name: "KENDALL COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "095",
              name: "KNOX COUNTY",
              state: "IL",
              'CBSA Number': "23660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "097",
              name: "LAKE COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "099",
              name: "LASALLE COUNTY",
              state: "IL",
              'CBSA Number': "36837",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "101",
              name: "LAWRENCE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "103",
              name: "LEE COUNTY",
              state: "IL",
              'CBSA Number': "19940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "105",
              name: "LIVINGSTON COUNTY",
              state: "IL",
              'CBSA Number': "38700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "107",
              name: "LOGAN COUNTY",
              state: "IL",
              'CBSA Number': "30660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "109",
              name: "MCDONOUGH COUNTY",
              state: "IL",
              'CBSA Number': "31380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "111",
              name: "MCHENRY COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "113",
              name: "MCLEAN COUNTY",
              state: "IL",
              'CBSA Number': "14010",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "115",
              name: "MACON COUNTY",
              state: "IL",
              'CBSA Number': "19500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "117",
              name: "MACOUPIN COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "119",
              name: "MADISON COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "121",
              name: "MARION COUNTY",
              state: "IL",
              'CBSA Number': "16460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "123",
              name: "MARSHALL COUNTY",
              state: "IL",
              'CBSA Number': "37900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "125",
              name: "MASON COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "127",
              name: "MASSAC COUNTY",
              state: "IL",
              'CBSA Number': "37140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "129",
              name: "MENARD COUNTY",
              state: "IL",
              'CBSA Number': "44100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "131",
              name: "MERCER COUNTY",
              state: "IL",
              'CBSA Number': "19340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "133",
              name: "MONROE COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "135",
              name: "MONTGOMERY COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "137",
              name: "MORGAN COUNTY",
              state: "IL",
              'CBSA Number': "27300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "139",
              name: "MOULTRIE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "141",
              name: "OGLE COUNTY",
              state: "IL",
              'CBSA Number': "40300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "143",
              name: "PEORIA COUNTY",
              state: "IL",
              'CBSA Number': "37900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "145",
              name: "PERRY COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "147",
              name: "PIATT COUNTY",
              state: "IL",
              'CBSA Number': "16580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "149",
              name: "PIKE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "151",
              name: "POPE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "153",
              name: "PULASKI COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "155",
              name: "PUTNAM COUNTY",
              state: "IL",
              'CBSA Number': "36837",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "157",
              name: "RANDOLPH COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "159",
              name: "RICHLAND COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "161",
              name: "ROCK ISLAND COUNTY",
              state: "IL",
              'CBSA Number': "19340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "163",
              name: "ST. CLAIR COUNTY",
              state: "IL",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "165",
              name: "SALINE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "167",
              name: "SANGAMON COUNTY",
              state: "IL",
              'CBSA Number': "44100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "169",
              name: "SCHUYLER COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "171",
              name: "SCOTT COUNTY",
              state: "IL",
              'CBSA Number': "27300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "173",
              name: "SHELBY COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "175",
              name: "STARK COUNTY",
              state: "IL",
              'CBSA Number': "37900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "177",
              name: "STEPHENSON COUNTY",
              state: "IL",
              'CBSA Number': "23300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "179",
              name: "TAZEWELL COUNTY",
              state: "IL",
              'CBSA Number': "37900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "181",
              name: "UNION COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "183",
              name: "VERMILION COUNTY",
              state: "IL",
              'CBSA Number': "19180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "185",
              name: "WABASH COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "187",
              name: "WARREN COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "189",
              name: "WASHINGTON COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "191",
              name: "WAYNE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "193",
              name: "WHITE COUNTY",
              state: "IL",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "195",
              name: "WHITESIDE COUNTY",
              state: "IL",
              'CBSA Number': "44580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "197",
              name: "WILL COUNTY",
              state: "IL",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "199",
              name: "WILLIAMSON COUNTY",
              state: "IL",
              'CBSA Number': "16060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "201",
              name: "WINNEBAGO COUNTY",
              state: "IL",
              'CBSA Number': "40420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "17",
              countyCode: "203",
              name: "WOODFORD COUNTY",
              state: "IL",
              'CBSA Number': "37900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "IN",
              'CBSA Number': "19540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "003",
              name: "ALLEN COUNTY",
              state: "IN",
              'CBSA Number': "23060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "005",
              name: "BARTHOLOMEW COUNTY",
              state: "IN",
              'CBSA Number': "18020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "007",
              name: "BENTON COUNTY",
              state: "IN",
              'CBSA Number': "29200",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "009",
              name: "BLACKFORD COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "011",
              name: "BOONE COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "013",
              name: "BROWN COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "015",
              name: "CARROLL COUNTY",
              state: "IN",
              'CBSA Number': "29200",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "017",
              name: "CASS COUNTY",
              state: "IN",
              'CBSA Number': "30900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "019",
              name: "CLARK COUNTY",
              state: "IN",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "021",
              name: "CLAY COUNTY",
              state: "IN",
              'CBSA Number': "45460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "023",
              name: "CLINTON COUNTY",
              state: "IN",
              'CBSA Number': "23140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "025",
              name: "CRAWFORD COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "027",
              name: "DAVIESS COUNTY",
              state: "IN",
              'CBSA Number': "47780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "029",
              name: "DEARBORN COUNTY",
              state: "IN",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "031",
              name: "DECATUR COUNTY",
              state: "IN",
              'CBSA Number': "24700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "033",
              name: "DEKALB COUNTY",
              state: "IN",
              'CBSA Number': "12140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "035",
              name: "DELAWARE COUNTY",
              state: "IN",
              'CBSA Number': "34620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "037",
              name: "DUBOIS COUNTY",
              state: "IN",
              'CBSA Number': "27540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "039",
              name: "ELKHART COUNTY",
              state: "IN",
              'CBSA Number': "21140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "041",
              name: "FAYETTE COUNTY",
              state: "IN",
              'CBSA Number': "18220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "043",
              name: "FLOYD COUNTY",
              state: "IN",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "045",
              name: "FOUNTAIN COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "047",
              name: "FRANKLIN COUNTY",
              state: "IN",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "049",
              name: "FULTON COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "051",
              name: "GIBSON COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "053",
              name: "GRANT COUNTY",
              state: "IN",
              'CBSA Number': "31980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "055",
              name: "GREENE COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "057",
              name: "HAMILTON COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "059",
              name: "HANCOCK COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "061",
              name: "HARRISON COUNTY",
              state: "IN",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "063",
              name: "HENDRICKS COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "065",
              name: "HENRY COUNTY",
              state: "IN",
              'CBSA Number': "35220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "067",
              name: "HOWARD COUNTY",
              state: "IN",
              'CBSA Number': "29020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "069",
              name: "HUNTINGTON COUNTY",
              state: "IN",
              'CBSA Number': "26540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "071",
              name: "JACKSON COUNTY",
              state: "IN",
              'CBSA Number': "42980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "073",
              name: "JASPER COUNTY",
              state: "IN",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "075",
              name: "JAY COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "077",
              name: "JEFFERSON COUNTY",
              state: "IN",
              'CBSA Number': "31500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "079",
              name: "JENNINGS COUNTY",
              state: "IN",
              'CBSA Number': "35860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "081",
              name: "JOHNSON COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "083",
              name: "KNOX COUNTY",
              state: "IN",
              'CBSA Number': "47180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "085",
              name: "KOSCIUSKO COUNTY",
              state: "IN",
              'CBSA Number': "47700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "087",
              name: "LAGRANGE COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "089",
              name: "LAKE COUNTY",
              state: "IN",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "091",
              name: "LAPORTE COUNTY",
              state: "IN",
              'CBSA Number': "33140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "093",
              name: "LAWRENCE COUNTY",
              state: "IN",
              'CBSA Number': "13260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "095",
              name: "MADISON COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "097",
              name: "MARION COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "099",
              name: "MARSHALL COUNTY",
              state: "IN",
              'CBSA Number': "38500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "101",
              name: "MARTIN COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "103",
              name: "MIAMI COUNTY",
              state: "IN",
              'CBSA Number': "37940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "105",
              name: "MONROE COUNTY",
              state: "IN",
              'CBSA Number': "14020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "107",
              name: "MONTGOMERY COUNTY",
              state: "IN",
              'CBSA Number': "18820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "109",
              name: "MORGAN COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "111",
              name: "NEWTON COUNTY",
              state: "IN",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "113",
              name: "NOBLE COUNTY",
              state: "IN",
              'CBSA Number': "28340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "115",
              name: "OHIO COUNTY",
              state: "IN",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "117",
              name: "ORANGE COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "119",
              name: "OWEN COUNTY",
              state: "IN",
              'CBSA Number': "14020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "121",
              name: "PARKE COUNTY",
              state: "IN",
              'CBSA Number': "45460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "123",
              name: "PERRY COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "125",
              name: "PIKE COUNTY",
              state: "IN",
              'CBSA Number': "27540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "127",
              name: "PORTER COUNTY",
              state: "IN",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "129",
              name: "POSEY COUNTY",
              state: "IN",
              'CBSA Number': "21780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "131",
              name: "PULASKI COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "133",
              name: "PUTNAM COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "135",
              name: "RANDOLPH COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "137",
              name: "RIPLEY COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "139",
              name: "RUSH COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "141",
              name: "ST. JOSEPH COUNTY",
              state: "IN",
              'CBSA Number': "43780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "143",
              name: "SCOTT COUNTY",
              state: "IN",
              'CBSA Number': "42500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "145",
              name: "SHELBY COUNTY",
              state: "IN",
              'CBSA Number': "26900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "147",
              name: "SPENCER COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "149",
              name: "STARKE COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "151",
              name: "STEUBEN COUNTY",
              state: "IN",
              'CBSA Number': "11420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "153",
              name: "SULLIVAN COUNTY",
              state: "IN",
              'CBSA Number': "45460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "155",
              name: "SWITZERLAND COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "157",
              name: "TIPPECANOE COUNTY",
              state: "IN",
              'CBSA Number': "29200",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "159",
              name: "TIPTON COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "161",
              name: "UNION COUNTY",
              state: "IN",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "163",
              name: "VANDERBURGH COUNTY",
              state: "IN",
              'CBSA Number': "21780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "165",
              name: "VERMILLION COUNTY",
              state: "IN",
              'CBSA Number': "45460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "167",
              name: "VIGO COUNTY",
              state: "IN",
              'CBSA Number': "45460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "169",
              name: "WABASH COUNTY",
              state: "IN",
              'CBSA Number': "47340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "171",
              name: "WARREN COUNTY",
              state: "IN",
              'CBSA Number': "29200",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "173",
              name: "WARRICK COUNTY",
              state: "IN",
              'CBSA Number': "21780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "175",
              name: "WASHINGTON COUNTY",
              state: "IN",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "177",
              name: "WAYNE COUNTY",
              state: "IN",
              'CBSA Number': "39980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "179",
              name: "WELLS COUNTY",
              state: "IN",
              'CBSA Number': "14160",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "181",
              name: "WHITE COUNTY",
              state: "IN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "18",
              countyCode: "183",
              name: "WHITLEY COUNTY",
              state: "IN",
              'CBSA Number': "23060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "001",
              name: "ADAIR COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "003",
              name: "ADAMS COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "005",
              name: "ALLAMAKEE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "007",
              name: "APPANOOSE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "009",
              name: "AUDUBON COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "011",
              name: "BENTON COUNTY",
              state: "IA",
              'CBSA Number': "16300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "013",
              name: "BLACK HAWK COUNTY",
              state: "IA",
              'CBSA Number': "47940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "015",
              name: "BOONE COUNTY",
              state: "IA",
              'CBSA Number': "11180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "017",
              name: "BREMER COUNTY",
              state: "IA",
              'CBSA Number': "47940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "019",
              name: "BUCHANAN COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "021",
              name: "BUENA VISTA COUNTY",
              state: "IA",
              'CBSA Number': "44740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "023",
              name: "BUTLER COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "025",
              name: "CALHOUN COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "027",
              name: "CARROLL COUNTY",
              state: "IA",
              'CBSA Number': "16140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "029",
              name: "CASS COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "031",
              name: "CEDAR COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "033",
              name: "CERRO GORDO COUNTY",
              state: "IA",
              'CBSA Number': "32380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "035",
              name: "CHEROKEE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "037",
              name: "CHICKASAW COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "039",
              name: "CLARKE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "041",
              name: "CLAY COUNTY",
              state: "IA",
              'CBSA Number': "43980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "043",
              name: "CLAYTON COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "045",
              name: "CLINTON COUNTY",
              state: "IA",
              'CBSA Number': "17540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "047",
              name: "CRAWFORD COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "049",
              name: "DALLAS COUNTY",
              state: "IA",
              'CBSA Number': "19780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "051",
              name: "DAVIS COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "053",
              name: "DECATUR COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "055",
              name: "DELAWARE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "057",
              name: "DES MOINES COUNTY",
              state: "IA",
              'CBSA Number': "15460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "059",
              name: "DICKINSON COUNTY",
              state: "IA",
              'CBSA Number': "44020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "061",
              name: "DUBUQUE COUNTY",
              state: "IA",
              'CBSA Number': "20220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "063",
              name: "EMMET COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "065",
              name: "FAYETTE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "067",
              name: "FLOYD COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "069",
              name: "FRANKLIN COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "071",
              name: "FREMONT COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "073",
              name: "GREENE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "075",
              name: "GRUNDY COUNTY",
              state: "IA",
              'CBSA Number': "47940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "077",
              name: "GUTHRIE COUNTY",
              state: "IA",
              'CBSA Number': "19780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "079",
              name: "HAMILTON COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "081",
              name: "HANCOCK COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "083",
              name: "HARDIN COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "085",
              name: "HARRISON COUNTY",
              state: "IA",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "087",
              name: "HENRY COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "089",
              name: "HOWARD COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "091",
              name: "HUMBOLDT COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "093",
              name: "IDA COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "095",
              name: "IOWA COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "097",
              name: "JACKSON COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "099",
              name: "JASPER COUNTY",
              state: "IA",
              'CBSA Number': "19780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "101",
              name: "JEFFERSON COUNTY",
              state: "IA",
              'CBSA Number': "21840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "103",
              name: "JOHNSON COUNTY",
              state: "IA",
              'CBSA Number': "26980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "105",
              name: "JONES COUNTY",
              state: "IA",
              'CBSA Number': "16300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "107",
              name: "KEOKUK COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "109",
              name: "KOSSUTH COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "111",
              name: "LEE COUNTY",
              state: "IA",
              'CBSA Number': "22800",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "113",
              name: "LINN COUNTY",
              state: "IA",
              'CBSA Number': "16300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "115",
              name: "LOUISA COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "117",
              name: "LUCAS COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "119",
              name: "LYON COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "121",
              name: "MADISON COUNTY",
              state: "IA",
              'CBSA Number': "19780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "123",
              name: "MAHASKA COUNTY",
              state: "IA",
              'CBSA Number': "36820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "125",
              name: "MARION COUNTY",
              state: "IA",
              'CBSA Number': "37800",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "127",
              name: "MARSHALL COUNTY",
              state: "IA",
              'CBSA Number': "32260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "129",
              name: "MILLS COUNTY",
              state: "IA",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "131",
              name: "MITCHELL COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "133",
              name: "MONONA COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "135",
              name: "MONROE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "137",
              name: "MONTGOMERY COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "139",
              name: "MUSCATINE COUNTY",
              state: "IA",
              'CBSA Number': "34700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "141",
              name: "O'BRIEN COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "143",
              name: "OSCEOLA COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "145",
              name: "PAGE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "147",
              name: "PALO ALTO COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "149",
              name: "PLYMOUTH COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "151",
              name: "POCAHONTAS COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "153",
              name: "POLK COUNTY",
              state: "IA",
              'CBSA Number': "19780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "155",
              name: "POTTAWATTAMIE COUNTY",
              state: "IA",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "157",
              name: "POWESHIEK COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "159",
              name: "RINGGOLD COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "161",
              name: "SAC COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "163",
              name: "SCOTT COUNTY",
              state: "IA",
              'CBSA Number': "19340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "165",
              name: "SHELBY COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "167",
              name: "SIOUX COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "169",
              name: "STORY COUNTY",
              state: "IA",
              'CBSA Number': "11180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "171",
              name: "TAMA COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "173",
              name: "TAYLOR COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "175",
              name: "UNION COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "177",
              name: "VAN BUREN COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "179",
              name: "WAPELLO COUNTY",
              state: "IA",
              'CBSA Number': "36900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "181",
              name: "WARREN COUNTY",
              state: "IA",
              'CBSA Number': "19780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "183",
              name: "WASHINGTON COUNTY",
              state: "IA",
              'CBSA Number': "26980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "185",
              name: "WAYNE COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "187",
              name: "WEBSTER COUNTY",
              state: "IA",
              'CBSA Number': "22700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "189",
              name: "WINNEBAGO COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "191",
              name: "WINNESHIEK COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "193",
              name: "WOODBURY COUNTY",
              state: "IA",
              'CBSA Number': "43580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "195",
              name: "WORTH COUNTY",
              state: "IA",
              'CBSA Number': "32380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "19",
              countyCode: "197",
              name: "WRIGHT COUNTY",
              state: "IA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "001",
              name: "ALLEN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "003",
              name: "ANDERSON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "005",
              name: "ATCHISON COUNTY",
              state: "KS",
              'CBSA Number': "11860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "007",
              name: "BARBER COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "009",
              name: "BARTON COUNTY",
              state: "KS",
              'CBSA Number': "24460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "011",
              name: "BOURBON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "013",
              name: "BROWN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "015",
              name: "BUTLER COUNTY",
              state: "KS",
              'CBSA Number': "48620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "017",
              name: "CHASE COUNTY",
              state: "KS",
              'CBSA Number': "21380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "019",
              name: "CHAUTAUQUA COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "021",
              name: "CHEROKEE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "023",
              name: "CHEYENNE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "025",
              name: "CLARK COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "027",
              name: "CLAY COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "029",
              name: "CLOUD COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "031",
              name: "COFFEY COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "033",
              name: "COMANCHE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "035",
              name: "COWLEY COUNTY",
              state: "KS",
              'CBSA Number': "49060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "037",
              name: "CRAWFORD COUNTY",
              state: "KS",
              'CBSA Number': "38260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "039",
              name: "DECATUR COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "041",
              name: "DICKINSON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "043",
              name: "DONIPHAN COUNTY",
              state: "KS",
              'CBSA Number': "41140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "045",
              name: "DOUGLAS COUNTY",
              state: "KS",
              'CBSA Number': "29940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "047",
              name: "EDWARDS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "049",
              name: "ELK COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "051",
              name: "ELLIS COUNTY",
              state: "KS",
              'CBSA Number': "25700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "053",
              name: "ELLSWORTH COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "055",
              name: "FINNEY COUNTY",
              state: "KS",
              'CBSA Number': "23780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "057",
              name: "FORD COUNTY",
              state: "KS",
              'CBSA Number': "19980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "059",
              name: "FRANKLIN COUNTY",
              state: "KS",
              'CBSA Number': "36840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "061",
              name: "GEARY COUNTY",
              state: "KS",
              'CBSA Number': "31740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "063",
              name: "GOVE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "065",
              name: "GRAHAM COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "067",
              name: "GRANT COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "069",
              name: "GRAY COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "071",
              name: "GREELEY COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "073",
              name: "GREENWOOD COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "075",
              name: "HAMILTON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "077",
              name: "HARPER COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "079",
              name: "HARVEY COUNTY",
              state: "KS",
              'CBSA Number': "48620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "081",
              name: "HASKELL COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "083",
              name: "HODGEMAN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "085",
              name: "JACKSON COUNTY",
              state: "KS",
              'CBSA Number': "45820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "087",
              name: "JEFFERSON COUNTY",
              state: "KS",
              'CBSA Number': "45820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "089",
              name: "JEWELL COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "091",
              name: "JOHNSON COUNTY",
              state: "KS",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "093",
              name: "KEARNY COUNTY",
              state: "KS",
              'CBSA Number': "23780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "095",
              name: "KINGMAN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "097",
              name: "KIOWA COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "099",
              name: "LABETTE COUNTY",
              state: "KS",
              'CBSA Number': "37660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "101",
              name: "LANE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "103",
              name: "LEAVENWORTH COUNTY",
              state: "KS",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "105",
              name: "LINCOLN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "107",
              name: "LINN COUNTY",
              state: "KS",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "109",
              name: "LOGAN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "111",
              name: "LYON COUNTY",
              state: "KS",
              'CBSA Number': "21380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "113",
              name: "MCPHERSON COUNTY",
              state: "KS",
              'CBSA Number': "32700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "115",
              name: "MARION COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "117",
              name: "MARSHALL COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "119",
              name: "MEADE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "121",
              name: "MIAMI COUNTY",
              state: "KS",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "123",
              name: "MITCHELL COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "125",
              name: "MONTGOMERY COUNTY",
              state: "KS",
              'CBSA Number': "17700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "127",
              name: "MORRIS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "129",
              name: "MORTON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "131",
              name: "NEMAHA COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "133",
              name: "NEOSHO COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "135",
              name: "NESS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "137",
              name: "NORTON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "139",
              name: "OSAGE COUNTY",
              state: "KS",
              'CBSA Number': "45820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "141",
              name: "OSBORNE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "143",
              name: "OTTAWA COUNTY",
              state: "KS",
              'CBSA Number': "41460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "145",
              name: "PAWNEE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "147",
              name: "PHILLIPS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "149",
              name: "POTTAWATOMIE COUNTY",
              state: "KS",
              'CBSA Number': "31740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "151",
              name: "PRATT COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "153",
              name: "RAWLINS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "155",
              name: "RENO COUNTY",
              state: "KS",
              'CBSA Number': "26740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "157",
              name: "REPUBLIC COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "159",
              name: "RICE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "161",
              name: "RILEY COUNTY",
              state: "KS",
              'CBSA Number': "31740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "163",
              name: "ROOKS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "165",
              name: "RUSH COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "167",
              name: "RUSSELL COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "169",
              name: "SALINE COUNTY",
              state: "KS",
              'CBSA Number': "41460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "171",
              name: "SCOTT COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "173",
              name: "SEDGWICK COUNTY",
              state: "KS",
              'CBSA Number': "48620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "175",
              name: "SEWARD COUNTY",
              state: "KS",
              'CBSA Number': "30580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "177",
              name: "SHAWNEE COUNTY",
              state: "KS",
              'CBSA Number': "45820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "179",
              name: "SHERIDAN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "181",
              name: "SHERMAN COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "183",
              name: "SMITH COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "185",
              name: "STAFFORD COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "187",
              name: "STANTON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "189",
              name: "STEVENS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "191",
              name: "SUMNER COUNTY",
              state: "KS",
              'CBSA Number': "48620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "193",
              name: "THOMAS COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "195",
              name: "TREGO COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "197",
              name: "WABAUNSEE COUNTY",
              state: "KS",
              'CBSA Number': "45820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "199",
              name: "WALLACE COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "201",
              name: "WASHINGTON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "203",
              name: "WICHITA COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "205",
              name: "WILSON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "207",
              name: "WOODSON COUNTY",
              state: "KS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "20",
              countyCode: "209",
              name: "WYANDOTTE COUNTY",
              state: "KS",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "001",
              name: "ADAIR COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "003",
              name: "ALLEN COUNTY",
              state: "KY",
              'CBSA Number': "14540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "005",
              name: "ANDERSON COUNTY",
              state: "KY",
              'CBSA Number': "23180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "007",
              name: "BALLARD COUNTY",
              state: "KY",
              'CBSA Number': "37140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "009",
              name: "BARREN COUNTY",
              state: "KY",
              'CBSA Number': "23980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "011",
              name: "BATH COUNTY",
              state: "KY",
              'CBSA Number': "34460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "013",
              name: "BELL COUNTY",
              state: "KY",
              'CBSA Number': "33180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "015",
              name: "BOONE COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "017",
              name: "BOURBON COUNTY",
              state: "KY",
              'CBSA Number': "30460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "019",
              name: "BOYD COUNTY",
              state: "KY",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "021",
              name: "BOYLE COUNTY",
              state: "KY",
              'CBSA Number': "19220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "023",
              name: "BRACKEN COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "025",
              name: "BREATHITT COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "027",
              name: "BRECKINRIDGE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "029",
              name: "BULLITT COUNTY",
              state: "KY",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "031",
              name: "BUTLER COUNTY",
              state: "KY",
              'CBSA Number': "14540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "033",
              name: "CALDWELL COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "035",
              name: "CALLOWAY COUNTY",
              state: "KY",
              'CBSA Number': "34660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "037",
              name: "CAMPBELL COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "039",
              name: "CARLISLE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "041",
              name: "CARROLL COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "043",
              name: "CARTER COUNTY",
              state: "KY",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "045",
              name: "CASEY COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "047",
              name: "CHRISTIAN COUNTY",
              state: "KY",
              'CBSA Number': "17300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "049",
              name: "CLARK COUNTY",
              state: "KY",
              'CBSA Number': "30460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "051",
              name: "CLAY COUNTY",
              state: "KY",
              'CBSA Number': "30940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "053",
              name: "CLINTON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "055",
              name: "CRITTENDEN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "057",
              name: "CUMBERLAND COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "059",
              name: "DAVIESS COUNTY",
              state: "KY",
              'CBSA Number': "36980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "061",
              name: "EDMONSON COUNTY",
              state: "KY",
              'CBSA Number': "14540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "063",
              name: "ELLIOTT COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "065",
              name: "ESTILL COUNTY",
              state: "KY",
              'CBSA Number': "40080",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "067",
              name: "FAYETTE COUNTY",
              state: "KY",
              'CBSA Number': "30460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "069",
              name: "FLEMING COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "071",
              name: "FLOYD COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "073",
              name: "FRANKLIN COUNTY",
              state: "KY",
              'CBSA Number': "23180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "075",
              name: "FULTON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "077",
              name: "GALLATIN COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "079",
              name: "GARRARD COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "081",
              name: "GRANT COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "083",
              name: "GRAVES COUNTY",
              state: "KY",
              'CBSA Number': "32460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "085",
              name: "GRAYSON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "087",
              name: "GREEN COUNTY",
              state: "KY",
              'CBSA Number': "15820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "089",
              name: "GREENUP COUNTY",
              state: "KY",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "091",
              name: "HANCOCK COUNTY",
              state: "KY",
              'CBSA Number': "36980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "093",
              name: "HARDIN COUNTY",
              state: "KY",
              'CBSA Number': "21060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "095",
              name: "HARLAN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "097",
              name: "HARRISON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "099",
              name: "HART COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "101",
              name: "HENDERSON COUNTY",
              state: "KY",
              'CBSA Number': "21780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "103",
              name: "HENRY COUNTY",
              state: "KY",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "105",
              name: "HICKMAN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "107",
              name: "HOPKINS COUNTY",
              state: "KY",
              'CBSA Number': "31580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "109",
              name: "JACKSON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "111",
              name: "JEFFERSON COUNTY",
              state: "KY",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "113",
              name: "JESSAMINE COUNTY",
              state: "KY",
              'CBSA Number': "30460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "115",
              name: "JOHNSON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "117",
              name: "KENTON COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "119",
              name: "KNOTT COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "121",
              name: "KNOX COUNTY",
              state: "KY",
              'CBSA Number': "30940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "123",
              name: "LARUE COUNTY",
              state: "KY",
              'CBSA Number': "21060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "125",
              name: "LAUREL COUNTY",
              state: "KY",
              'CBSA Number': "30940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "127",
              name: "LAWRENCE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "129",
              name: "LEE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "131",
              name: "LESLIE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "133",
              name: "LETCHER COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "135",
              name: "LEWIS COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "137",
              name: "LINCOLN COUNTY",
              state: "KY",
              'CBSA Number': "19220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "139",
              name: "LIVINGSTON COUNTY",
              state: "KY",
              'CBSA Number': "37140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "141",
              name: "LOGAN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "143",
              name: "LYON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "145",
              name: "MCCRACKEN COUNTY",
              state: "KY",
              'CBSA Number': "37140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "147",
              name: "MCCREARY COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "149",
              name: "MCLEAN COUNTY",
              state: "KY",
              'CBSA Number': "36980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "151",
              name: "MADISON COUNTY",
              state: "KY",
              'CBSA Number': "40080",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "153",
              name: "MAGOFFIN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "155",
              name: "MARION COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "157",
              name: "MARSHALL COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "159",
              name: "MARTIN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "161",
              name: "MASON COUNTY",
              state: "KY",
              'CBSA Number': "32500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "163",
              name: "MEADE COUNTY",
              state: "KY",
              'CBSA Number': "21060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "165",
              name: "MENIFEE COUNTY",
              state: "KY",
              'CBSA Number': "34460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "167",
              name: "MERCER COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "169",
              name: "METCALFE COUNTY",
              state: "KY",
              'CBSA Number': "23980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "171",
              name: "MONROE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "173",
              name: "MONTGOMERY COUNTY",
              state: "KY",
              'CBSA Number': "34460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "175",
              name: "MORGAN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "177",
              name: "MUHLENBERG COUNTY",
              state: "KY",
              'CBSA Number': "16420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "179",
              name: "NELSON COUNTY",
              state: "KY",
              'CBSA Number': "12680",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "181",
              name: "NICHOLAS COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "183",
              name: "OHIO COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "185",
              name: "OLDHAM COUNTY",
              state: "KY",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "187",
              name: "OWEN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "189",
              name: "OWSLEY COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "191",
              name: "PENDLETON COUNTY",
              state: "KY",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "193",
              name: "PERRY COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "195",
              name: "PIKE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "197",
              name: "POWELL COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "199",
              name: "PULASKI COUNTY",
              state: "KY",
              'CBSA Number': "43700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "201",
              name: "ROBERTSON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "203",
              name: "ROCKCASTLE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "205",
              name: "ROWAN COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "207",
              name: "RUSSELL COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "209",
              name: "SCOTT COUNTY",
              state: "KY",
              'CBSA Number': "30460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "211",
              name: "SHELBY COUNTY",
              state: "KY",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "213",
              name: "SIMPSON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "215",
              name: "SPENCER COUNTY",
              state: "KY",
              'CBSA Number': "31140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "217",
              name: "TAYLOR COUNTY",
              state: "KY",
              'CBSA Number': "15820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "219",
              name: "TODD COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "221",
              name: "TRIGG COUNTY",
              state: "KY",
              'CBSA Number': "17300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "223",
              name: "TRIMBLE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "225",
              name: "UNION COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "227",
              name: "WARREN COUNTY",
              state: "KY",
              'CBSA Number': "14540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "229",
              name: "WASHINGTON COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "231",
              name: "WAYNE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "233",
              name: "WEBSTER COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "235",
              name: "WHITLEY COUNTY",
              state: "KY",
              'CBSA Number': "30940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "237",
              name: "WOLFE COUNTY",
              state: "KY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "21",
              countyCode: "239",
              name: "WOODFORD COUNTY",
              state: "KY",
              'CBSA Number': "30460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "001",
              name: "ACADIA PARISH",
              state: "LA",
              'CBSA Number': "29180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "003",
              name: "ALLEN PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "005",
              name: "ASCENSION PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "007",
              name: "ASSUMPTION PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "009",
              name: "AVOYELLES PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "011",
              name: "BEAUREGARD PARISH",
              state: "LA",
              'CBSA Number': "19760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "013",
              name: "BIENVILLE PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "015",
              name: "BOSSIER PARISH",
              state: "LA",
              'CBSA Number': "43340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "017",
              name: "CADDO PARISH",
              state: "LA",
              'CBSA Number': "43340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "019",
              name: "CALCASIEU PARISH",
              state: "LA",
              'CBSA Number': "29340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "021",
              name: "CALDWELL PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "023",
              name: "CAMERON PARISH",
              state: "LA",
              'CBSA Number': "29340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "025",
              name: "CATAHOULA PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "027",
              name: "CLAIBORNE PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "029",
              name: "CONCORDIA PARISH",
              state: "LA",
              'CBSA Number': "35020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "031",
              name: "DE SOTO PARISH",
              state: "LA",
              'CBSA Number': "43340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "033",
              name: "EAST BATON ROUGE PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "035",
              name: "EAST CARROLL PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "037",
              name: "EAST FELICIANA PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "039",
              name: "EVANGELINE PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "041",
              name: "FRANKLIN PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "043",
              name: "GRANT PARISH",
              state: "LA",
              'CBSA Number': "10780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "045",
              name: "IBERIA PARISH",
              state: "LA",
              'CBSA Number': "29180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "047",
              name: "IBERVILLE PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "049",
              name: "JACKSON PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "051",
              name: "JEFFERSON PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "053",
              name: "JEFFERSON DAVIS PARISH",
              state: "LA",
              'CBSA Number': "27660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "055",
              name: "LAFAYETTE PARISH",
              state: "LA",
              'CBSA Number': "29180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "057",
              name: "LAFOURCHE PARISH",
              state: "LA",
              'CBSA Number': "26380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "059",
              name: "LA SALLE PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "061",
              name: "LINCOLN PARISH",
              state: "LA",
              'CBSA Number': "40820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "063",
              name: "LIVINGSTON PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "065",
              name: "MADISON PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "067",
              name: "MOREHOUSE PARISH",
              state: "LA",
              'CBSA Number': "33740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "069",
              name: "NATCHITOCHES PARISH",
              state: "LA",
              'CBSA Number': "35060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "071",
              name: "ORLEANS PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "073",
              name: "OUACHITA PARISH",
              state: "LA",
              'CBSA Number': "33740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "075",
              name: "PLAQUEMINES PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "077",
              name: "POINTE COUPEE PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "079",
              name: "RAPIDES PARISH",
              state: "LA",
              'CBSA Number': "10780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "081",
              name: "RED RIVER PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "083",
              name: "RICHLAND PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "085",
              name: "SABINE PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "087",
              name: "ST. BERNARD PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "089",
              name: "ST. CHARLES PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "091",
              name: "ST. HELENA PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "093",
              name: "ST. JAMES PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "095",
              name: "ST. JOHN THE BAPTIST PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "097",
              name: "ST. LANDRY PARISH",
              state: "LA",
              'CBSA Number': "36660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "099",
              name: "ST. MARTIN PARISH",
              state: "LA",
              'CBSA Number': "29180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "101",
              name: "ST. MARY PARISH",
              state: "LA",
              'CBSA Number': "34020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "103",
              name: "ST. TAMMANY PARISH",
              state: "LA",
              'CBSA Number': "35380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "105",
              name: "TANGIPAHOA PARISH",
              state: "LA",
              'CBSA Number': "25220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "107",
              name: "TENSAS PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "109",
              name: "TERREBONNE PARISH",
              state: "LA",
              'CBSA Number': "26380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "111",
              name: "UNION PARISH",
              state: "LA",
              'CBSA Number': "33740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "113",
              name: "VERMILION PARISH",
              state: "LA",
              'CBSA Number': "29180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "115",
              name: "VERNON PARISH",
              state: "LA",
              'CBSA Number': "22860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "117",
              name: "WASHINGTON PARISH",
              state: "LA",
              'CBSA Number': "14220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "119",
              name: "WEBSTER PARISH",
              state: "LA",
              'CBSA Number': "33380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "121",
              name: "WEST BATON ROUGE PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "123",
              name: "WEST CARROLL PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "125",
              name: "WEST FELICIANA PARISH",
              state: "LA",
              'CBSA Number': "12940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "22",
              countyCode: "127",
              name: "WINN PARISH",
              state: "LA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "001",
              name: "ANDROSCOGGIN COUNTY",
              state: "ME",
              'CBSA Number': "30340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "003",
              name: "AROOSTOOK COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "005",
              name: "CUMBERLAND COUNTY",
              state: "ME",
              'CBSA Number': "38860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "007",
              name: "FRANKLIN COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "009",
              name: "HANCOCK COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "011",
              name: "KENNEBEC COUNTY",
              state: "ME",
              'CBSA Number': "12300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "013",
              name: "KNOX COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "015",
              name: "LINCOLN COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "017",
              name: "OXFORD COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "019",
              name: "PENOBSCOT COUNTY",
              state: "ME",
              'CBSA Number': "12620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "021",
              name: "PISCATAQUIS COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "023",
              name: "SAGADAHOC COUNTY",
              state: "ME",
              'CBSA Number': "38860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "025",
              name: "SOMERSET COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "027",
              name: "WALDO COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "029",
              name: "WASHINGTON COUNTY",
              state: "ME",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "23",
              countyCode: "031",
              name: "YORK COUNTY",
              state: "ME",
              'CBSA Number': "38860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "001",
              name: "ALLEGANY COUNTY",
              state: "MD",
              'CBSA Number': "19060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "003",
              name: "ANNE ARUNDEL COUNTY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "005",
              name: "BALTIMORE COUNTY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "009",
              name: "CALVERT COUNTY",
              state: "MD",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "24",
              countyCode: "011",
              name: "CAROLINE COUNTY",
              state: "MD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "013",
              name: "CARROLL COUNTY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "015",
              name: "CECIL COUNTY",
              state: "MD",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "017",
              name: "CHARLES COUNTY",
              state: "MD",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "24",
              countyCode: "019",
              name: "DORCHESTER COUNTY",
              state: "MD",
              'CBSA Number': "15700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "021",
              name: "FREDERICK COUNTY",
              state: "MD",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "24",
              countyCode: "023",
              name: "GARRETT COUNTY",
              state: "MD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "025",
              name: "HARFORD COUNTY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "027",
              name: "HOWARD COUNTY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "029",
              name: "KENT COUNTY",
              state: "MD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "031",
              name: "MONTGOMERY COUNTY",
              state: "MD",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "24",
              countyCode: "033",
              name: "PRINCE GEORGE'S COUNTY",
              state: "MD",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "24",
              countyCode: "035",
              name: "QUEEN ANNE'S COUNTY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "037",
              name: "ST. MARY'S COUNTY",
              state: "MD",
              'CBSA Number': "15680",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "039",
              name: "SOMERSET COUNTY",
              state: "MD",
              'CBSA Number': "41540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "041",
              name: "TALBOT COUNTY",
              state: "MD",
              'CBSA Number': "20660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "043",
              name: "WASHINGTON COUNTY",
              state: "MD",
              'CBSA Number': "25180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "045",
              name: "WICOMICO COUNTY",
              state: "MD",
              'CBSA Number': "41540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "047",
              name: "WORCESTER COUNTY",
              state: "MD",
              'CBSA Number': "41540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "24",
              countyCode: "510",
              name: "BALTIMORE CITY",
              state: "MD",
              'CBSA Number': "12580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "001",
              name: "BARNSTABLE COUNTY",
              state: "MA",
              'CBSA Number': "12700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "003",
              name: "BERKSHIRE COUNTY",
              state: "MA",
              'CBSA Number': "38340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "005",
              name: "BRISTOL COUNTY",
              state: "MA",
              'CBSA Number': "39300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "007",
              name: "DUKES COUNTY",
              state: "MA",
              'CBSA Number': "47240",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "25",
              countyCode: "009",
              name: "ESSEX COUNTY",
              state: "MA",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "25",
              countyCode: "011",
              name: "FRANKLIN COUNTY",
              state: "MA",
              'CBSA Number': "44140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "013",
              name: "HAMPDEN COUNTY",
              state: "MA",
              'CBSA Number': "44140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "015",
              name: "HAMPSHIRE COUNTY",
              state: "MA",
              'CBSA Number': "44140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "25",
              countyCode: "017",
              name: "MIDDLESEX COUNTY",
              state: "MA",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "25",
              countyCode: "019",
              name: "NANTUCKET COUNTY",
              state: "MA",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "25",
              countyCode: "021",
              name: "NORFOLK COUNTY",
              state: "MA",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "25",
              countyCode: "023",
              name: "PLYMOUTH COUNTY",
              state: "MA",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "25",
              countyCode: "025",
              name: "SUFFOLK COUNTY",
              state: "MA",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "25",
              countyCode: "027",
              name: "WORCESTER COUNTY",
              state: "MA",
              'CBSA Number': "49340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "001",
              name: "ALCONA COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "003",
              name: "ALGER COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "005",
              name: "ALLEGAN COUNTY",
              state: "MI",
              'CBSA Number': "26090",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "007",
              name: "ALPENA COUNTY",
              state: "MI",
              'CBSA Number': "10980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "009",
              name: "ANTRIM COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "011",
              name: "ARENAC COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "013",
              name: "BARAGA COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "015",
              name: "BARRY COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "017",
              name: "BAY COUNTY",
              state: "MI",
              'CBSA Number': "13020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "019",
              name: "BENZIE COUNTY",
              state: "MI",
              'CBSA Number': "45900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "021",
              name: "BERRIEN COUNTY",
              state: "MI",
              'CBSA Number': "35660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "023",
              name: "BRANCH COUNTY",
              state: "MI",
              'CBSA Number': "17740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "025",
              name: "CALHOUN COUNTY",
              state: "MI",
              'CBSA Number': "12980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "027",
              name: "CASS COUNTY",
              state: "MI",
              'CBSA Number': "43780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "029",
              name: "CHARLEVOIX COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "031",
              name: "CHEBOYGAN COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "033",
              name: "CHIPPEWA COUNTY",
              state: "MI",
              'CBSA Number': "42300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "035",
              name: "CLARE COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "037",
              name: "CLINTON COUNTY",
              state: "MI",
              'CBSA Number': "29620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "039",
              name: "CRAWFORD COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "041",
              name: "DELTA COUNTY",
              state: "MI",
              'CBSA Number': "21540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "043",
              name: "DICKINSON COUNTY",
              state: "MI",
              'CBSA Number': "27020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "045",
              name: "EATON COUNTY",
              state: "MI",
              'CBSA Number': "29620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "047",
              name: "EMMET COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "049",
              name: "GENESEE COUNTY",
              state: "MI",
              'CBSA Number': "22420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "051",
              name: "GLADWIN COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "053",
              name: "GOGEBIC COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "055",
              name: "GRAND TRAVERSE COUNTY",
              state: "MI",
              'CBSA Number': "45900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "057",
              name: "GRATIOT COUNTY",
              state: "MI",
              'CBSA Number': "10940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "059",
              name: "HILLSDALE COUNTY",
              state: "MI",
              'CBSA Number': "25880",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "061",
              name: "HOUGHTON COUNTY",
              state: "MI",
              'CBSA Number': "26340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "063",
              name: "HURON COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "065",
              name: "INGHAM COUNTY",
              state: "MI",
              'CBSA Number': "29620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "067",
              name: "IONIA COUNTY",
              state: "MI",
              'CBSA Number': "24340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "069",
              name: "IOSCO COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "071",
              name: "IRON COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "073",
              name: "ISABELLA COUNTY",
              state: "MI",
              'CBSA Number': "34380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "075",
              name: "JACKSON COUNTY",
              state: "MI",
              'CBSA Number': "27100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "077",
              name: "KALAMAZOO COUNTY",
              state: "MI",
              'CBSA Number': "28020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "079",
              name: "KALKASKA COUNTY",
              state: "MI",
              'CBSA Number': "45900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "081",
              name: "KENT COUNTY",
              state: "MI",
              'CBSA Number': "24340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "083",
              name: "KEWEENAW COUNTY",
              state: "MI",
              'CBSA Number': "26340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "085",
              name: "LAKE COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "087",
              name: "LAPEER COUNTY",
              state: "MI",
              'CBSA Number': "19820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "089",
              name: "LEELANAU COUNTY",
              state: "MI",
              'CBSA Number': "45900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "091",
              name: "LENAWEE COUNTY",
              state: "MI",
              'CBSA Number': "10300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "093",
              name: "LIVINGSTON COUNTY",
              state: "MI",
              'CBSA Number': "19820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "095",
              name: "LUCE COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "097",
              name: "MACKINAC COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "099",
              name: "MACOMB COUNTY",
              state: "MI",
              'CBSA Number': "19820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "101",
              name: "MANISTEE COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "103",
              name: "MARQUETTE COUNTY",
              state: "MI",
              'CBSA Number': "32100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "105",
              name: "MASON COUNTY",
              state: "MI",
              'CBSA Number': "31220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "107",
              name: "MECOSTA COUNTY",
              state: "MI",
              'CBSA Number': "13660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "109",
              name: "MENOMINEE COUNTY",
              state: "MI",
              'CBSA Number': "31940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "111",
              name: "MIDLAND COUNTY",
              state: "MI",
              'CBSA Number': "33220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "113",
              name: "MISSAUKEE COUNTY",
              state: "MI",
              'CBSA Number': "15620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "115",
              name: "MONROE COUNTY",
              state: "MI",
              'CBSA Number': "33780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "117",
              name: "MONTCALM COUNTY",
              state: "MI",
              'CBSA Number': "24340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "119",
              name: "MONTMORENCY COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "121",
              name: "MUSKEGON COUNTY",
              state: "MI",
              'CBSA Number': "34740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "123",
              name: "NEWAYGO COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "125",
              name: "OAKLAND COUNTY",
              state: "MI",
              'CBSA Number': "19820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "127",
              name: "OCEANA COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "129",
              name: "OGEMAW COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "131",
              name: "ONTONAGON COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "133",
              name: "OSCEOLA COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "135",
              name: "OSCODA COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "137",
              name: "OTSEGO COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "139",
              name: "OTTAWA COUNTY",
              state: "MI",
              'CBSA Number': "24340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "141",
              name: "PRESQUE ISLE COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "143",
              name: "ROSCOMMON COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "145",
              name: "SAGINAW COUNTY",
              state: "MI",
              'CBSA Number': "40980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "147",
              name: "ST. CLAIR COUNTY",
              state: "MI",
              'CBSA Number': "19820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "149",
              name: "ST. JOSEPH COUNTY",
              state: "MI",
              'CBSA Number': "44780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "151",
              name: "SANILAC COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "153",
              name: "SCHOOLCRAFT COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "155",
              name: "SHIAWASSEE COUNTY",
              state: "MI",
              'CBSA Number': "29620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "157",
              name: "TUSCOLA COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "159",
              name: "VAN BUREN COUNTY",
              state: "MI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "161",
              name: "WASHTENAW COUNTY",
              state: "MI",
              'CBSA Number': "11460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "163",
              name: "WAYNE COUNTY",
              state: "MI",
              'CBSA Number': "19820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "26",
              countyCode: "165",
              name: "WEXFORD COUNTY",
              state: "MI",
              'CBSA Number': "15620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "001",
              name: "AITKIN COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "003",
              name: "ANOKA COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "005",
              name: "BECKER COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "007",
              name: "BELTRAMI COUNTY",
              state: "MN",
              'CBSA Number': "13420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "009",
              name: "BENTON COUNTY",
              state: "MN",
              'CBSA Number': "41060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "011",
              name: "BIG STONE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "013",
              name: "BLUE EARTH COUNTY",
              state: "MN",
              'CBSA Number': "31860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "015",
              name: "BROWN COUNTY",
              state: "MN",
              'CBSA Number': "35580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "017",
              name: "CARLTON COUNTY",
              state: "MN",
              'CBSA Number': "20260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "019",
              name: "CARVER COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "021",
              name: "CASS COUNTY",
              state: "MN",
              'CBSA Number': "14660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "023",
              name: "CHIPPEWA COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "025",
              name: "CHISAGO COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "027",
              name: "CLAY COUNTY",
              state: "MN",
              'CBSA Number': "22020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "029",
              name: "CLEARWATER COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "031",
              name: "COOK COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "033",
              name: "COTTONWOOD COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "035",
              name: "CROW WING COUNTY",
              state: "MN",
              'CBSA Number': "14660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "037",
              name: "DAKOTA COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "039",
              name: "DODGE COUNTY",
              state: "MN",
              'CBSA Number': "40340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "041",
              name: "DOUGLAS COUNTY",
              state: "MN",
              'CBSA Number': "10820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "043",
              name: "FARIBAULT COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "045",
              name: "FILLMORE COUNTY",
              state: "MN",
              'CBSA Number': "40340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "047",
              name: "FREEBORN COUNTY",
              state: "MN",
              'CBSA Number': "10660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "049",
              name: "GOODHUE COUNTY",
              state: "MN",
              'CBSA Number': "39860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "051",
              name: "GRANT COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "053",
              name: "HENNEPIN COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "055",
              name: "HOUSTON COUNTY",
              state: "MN",
              'CBSA Number': "29100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "057",
              name: "HUBBARD COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "059",
              name: "ISANTI COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "061",
              name: "ITASCA COUNTY",
              state: "MN",
              'CBSA Number': "24330",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "063",
              name: "JACKSON COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "065",
              name: "KANABEC COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "067",
              name: "KANDIYOHI COUNTY",
              state: "MN",
              'CBSA Number': "48820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "069",
              name: "KITTSON COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "071",
              name: "KOOCHICHING COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "073",
              name: "LAC QUI PARLE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "075",
              name: "LAKE COUNTY",
              state: "MN",
              'CBSA Number': "20260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "077",
              name: "LAKE OF THE WOODS COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "079",
              name: "LE SUEUR COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "081",
              name: "LINCOLN COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "083",
              name: "LYON COUNTY",
              state: "MN",
              'CBSA Number': "32140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "085",
              name: "MCLEOD COUNTY",
              state: "MN",
              'CBSA Number': "26780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "087",
              name: "MAHNOMEN COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "089",
              name: "MARSHALL COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "091",
              name: "MARTIN COUNTY",
              state: "MN",
              'CBSA Number': "21860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "093",
              name: "MEEKER COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "095",
              name: "MILLE LACS COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "097",
              name: "MORRISON COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "099",
              name: "MOWER COUNTY",
              state: "MN",
              'CBSA Number': "12380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "101",
              name: "MURRAY COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "103",
              name: "NICOLLET COUNTY",
              state: "MN",
              'CBSA Number': "31860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "105",
              name: "NOBLES COUNTY",
              state: "MN",
              'CBSA Number': "49380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "107",
              name: "NORMAN COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "109",
              name: "OLMSTED COUNTY",
              state: "MN",
              'CBSA Number': "40340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "111",
              name: "OTTER TAIL COUNTY",
              state: "MN",
              'CBSA Number': "22260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "113",
              name: "PENNINGTON COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "115",
              name: "PINE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "117",
              name: "PIPESTONE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "119",
              name: "POLK COUNTY",
              state: "MN",
              'CBSA Number': "24220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "121",
              name: "POPE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "123",
              name: "RAMSEY COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "125",
              name: "RED LAKE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "127",
              name: "REDWOOD COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "129",
              name: "RENVILLE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "131",
              name: "RICE COUNTY",
              state: "MN",
              'CBSA Number': "22060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "133",
              name: "ROCK COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "135",
              name: "ROSEAU COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "137",
              name: "ST. LOUIS COUNTY",
              state: "MN",
              'CBSA Number': "20260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "139",
              name: "SCOTT COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "141",
              name: "SHERBURNE COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "143",
              name: "SIBLEY COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "145",
              name: "STEARNS COUNTY",
              state: "MN",
              'CBSA Number': "41060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "147",
              name: "STEELE COUNTY",
              state: "MN",
              'CBSA Number': "36940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "149",
              name: "STEVENS COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "151",
              name: "SWIFT COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "153",
              name: "TODD COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "155",
              name: "TRAVERSE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "157",
              name: "WABASHA COUNTY",
              state: "MN",
              'CBSA Number': "40340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "159",
              name: "WADENA COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "161",
              name: "WASECA COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "163",
              name: "WASHINGTON COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "165",
              name: "WATONWAN COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "167",
              name: "WILKIN COUNTY",
              state: "MN",
              'CBSA Number': "47420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "169",
              name: "WINONA COUNTY",
              state: "MN",
              'CBSA Number': "49100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "171",
              name: "WRIGHT COUNTY",
              state: "MN",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "27",
              countyCode: "173",
              name: "YELLOW MEDICINE COUNTY",
              state: "MN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "MS",
              'CBSA Number': "35020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "003",
              name: "ALCORN COUNTY",
              state: "MS",
              'CBSA Number': "18420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "005",
              name: "AMITE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "007",
              name: "ATTALA COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "009",
              name: "BENTON COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "011",
              name: "BOLIVAR COUNTY",
              state: "MS",
              'CBSA Number': "17380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "013",
              name: "CALHOUN COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "015",
              name: "CARROLL COUNTY",
              state: "MS",
              'CBSA Number': "24900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "017",
              name: "CHICKASAW COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "019",
              name: "CHOCTAW COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "021",
              name: "CLAIBORNE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "023",
              name: "CLARKE COUNTY",
              state: "MS",
              'CBSA Number': "32940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "025",
              name: "CLAY COUNTY",
              state: "MS",
              'CBSA Number': "48500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "027",
              name: "COAHOMA COUNTY",
              state: "MS",
              'CBSA Number': "17260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "029",
              name: "COPIAH COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "031",
              name: "COVINGTON COUNTY",
              state: "MS",
              'CBSA Number': "25620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "033",
              name: "DESOTO COUNTY",
              state: "MS",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "035",
              name: "FORREST COUNTY",
              state: "MS",
              'CBSA Number': "25620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "037",
              name: "FRANKLIN COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "039",
              name: "GEORGE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "041",
              name: "GREENE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "043",
              name: "GRENADA COUNTY",
              state: "MS",
              'CBSA Number': "24980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "045",
              name: "HANCOCK COUNTY",
              state: "MS",
              'CBSA Number': "25060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "047",
              name: "HARRISON COUNTY",
              state: "MS",
              'CBSA Number': "25060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "049",
              name: "HINDS COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "051",
              name: "HOLMES COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "053",
              name: "HUMPHREYS COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "055",
              name: "ISSAQUENA COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "057",
              name: "ITAWAMBA COUNTY",
              state: "MS",
              'CBSA Number': "46180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "059",
              name: "JACKSON COUNTY",
              state: "MS",
              'CBSA Number': "25060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "061",
              name: "JASPER COUNTY",
              state: "MS",
              'CBSA Number': "29860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "063",
              name: "JEFFERSON COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "065",
              name: "JEFFERSON DAVIS COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "067",
              name: "JONES COUNTY",
              state: "MS",
              'CBSA Number': "29860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "069",
              name: "KEMPER COUNTY",
              state: "MS",
              'CBSA Number': "32940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "071",
              name: "LAFAYETTE COUNTY",
              state: "MS",
              'CBSA Number': "37060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "073",
              name: "LAMAR COUNTY",
              state: "MS",
              'CBSA Number': "25620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "075",
              name: "LAUDERDALE COUNTY",
              state: "MS",
              'CBSA Number': "32940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "077",
              name: "LAWRENCE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "079",
              name: "LEAKE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "081",
              name: "LEE COUNTY",
              state: "MS",
              'CBSA Number': "46180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "083",
              name: "LEFLORE COUNTY",
              state: "MS",
              'CBSA Number': "24900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "085",
              name: "LINCOLN COUNTY",
              state: "MS",
              'CBSA Number': "15020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "087",
              name: "LOWNDES COUNTY",
              state: "MS",
              'CBSA Number': "18060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "089",
              name: "MADISON COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "091",
              name: "MARION COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "093",
              name: "MARSHALL COUNTY",
              state: "MS",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "095",
              name: "MONROE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "097",
              name: "MONTGOMERY COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "099",
              name: "NESHOBA COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "101",
              name: "NEWTON COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "103",
              name: "NOXUBEE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "105",
              name: "OKTIBBEHA COUNTY",
              state: "MS",
              'CBSA Number': "44260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "107",
              name: "PANOLA COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "109",
              name: "PEARL RIVER COUNTY",
              state: "MS",
              'CBSA Number': "38100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "111",
              name: "PERRY COUNTY",
              state: "MS",
              'CBSA Number': "25620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "113",
              name: "PIKE COUNTY",
              state: "MS",
              'CBSA Number': "32620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "115",
              name: "PONTOTOC COUNTY",
              state: "MS",
              'CBSA Number': "46180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "117",
              name: "PRENTISS COUNTY",
              state: "MS",
              'CBSA Number': "46180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "119",
              name: "QUITMAN COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "121",
              name: "RANKIN COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "123",
              name: "SCOTT COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "125",
              name: "SHARKEY COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "127",
              name: "SIMPSON COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "129",
              name: "SMITH COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "131",
              name: "STONE COUNTY",
              state: "MS",
              'CBSA Number': "25060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "133",
              name: "SUNFLOWER COUNTY",
              state: "MS",
              'CBSA Number': "26940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "135",
              name: "TALLAHATCHIE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "137",
              name: "TATE COUNTY",
              state: "MS",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "139",
              name: "TIPPAH COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "141",
              name: "TISHOMINGO COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "143",
              name: "TUNICA COUNTY",
              state: "MS",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "145",
              name: "UNION COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "147",
              name: "WALTHALL COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "149",
              name: "WARREN COUNTY",
              state: "MS",
              'CBSA Number': "46980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "151",
              name: "WASHINGTON COUNTY",
              state: "MS",
              'CBSA Number': "24740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "153",
              name: "WAYNE COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "155",
              name: "WEBSTER COUNTY",
              state: "MS",
              'CBSA Number': "44260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "157",
              name: "WILKINSON COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "159",
              name: "WINSTON COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "161",
              name: "YALOBUSHA COUNTY",
              state: "MS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "28",
              countyCode: "163",
              name: "YAZOO COUNTY",
              state: "MS",
              'CBSA Number': "27140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "001",
              name: "ADAIR COUNTY",
              state: "MO",
              'CBSA Number': "28860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "003",
              name: "ANDREW COUNTY",
              state: "MO",
              'CBSA Number': "41140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "005",
              name: "ATCHISON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "007",
              name: "AUDRAIN COUNTY",
              state: "MO",
              'CBSA Number': "33020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "009",
              name: "BARRY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "011",
              name: "BARTON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "013",
              name: "BATES COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "015",
              name: "BENTON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "017",
              name: "BOLLINGER COUNTY",
              state: "MO",
              'CBSA Number': "16020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "019",
              name: "BOONE COUNTY",
              state: "MO",
              'CBSA Number': "17860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "021",
              name: "BUCHANAN COUNTY",
              state: "MO",
              'CBSA Number': "41140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "023",
              name: "BUTLER COUNTY",
              state: "MO",
              'CBSA Number': "38740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "025",
              name: "CALDWELL COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "027",
              name: "CALLAWAY COUNTY",
              state: "MO",
              'CBSA Number': "27620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "029",
              name: "CAMDEN COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "031",
              name: "CAPE GIRARDEAU COUNTY",
              state: "MO",
              'CBSA Number': "16020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "033",
              name: "CARROLL COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "035",
              name: "CARTER COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "037",
              name: "CASS COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "039",
              name: "CEDAR COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "041",
              name: "CHARITON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "043",
              name: "CHRISTIAN COUNTY",
              state: "MO",
              'CBSA Number': "44180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "045",
              name: "CLARK COUNTY",
              state: "MO",
              'CBSA Number': "22800",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "047",
              name: "CLAY COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "049",
              name: "CLINTON COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "051",
              name: "COLE COUNTY",
              state: "MO",
              'CBSA Number': "27620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "053",
              name: "COOPER COUNTY",
              state: "MO",
              'CBSA Number': "17860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "055",
              name: "CRAWFORD COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "057",
              name: "DADE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "059",
              name: "DALLAS COUNTY",
              state: "MO",
              'CBSA Number': "44180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "061",
              name: "DAVIESS COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "063",
              name: "DEKALB COUNTY",
              state: "MO",
              'CBSA Number': "41140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "065",
              name: "DENT COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "067",
              name: "DOUGLAS COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "069",
              name: "DUNKLIN COUNTY",
              state: "MO",
              'CBSA Number': "28380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "071",
              name: "FRANKLIN COUNTY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "073",
              name: "GASCONADE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "075",
              name: "GENTRY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "077",
              name: "GREENE COUNTY",
              state: "MO",
              'CBSA Number': "44180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "079",
              name: "GRUNDY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "081",
              name: "HARRISON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "083",
              name: "HENRY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "085",
              name: "HICKORY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "087",
              name: "HOLT COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "089",
              name: "HOWARD COUNTY",
              state: "MO",
              'CBSA Number': "17860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "091",
              name: "HOWELL COUNTY",
              state: "MO",
              'CBSA Number': "48460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "093",
              name: "IRON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "095",
              name: "JACKSON COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "097",
              name: "JASPER COUNTY",
              state: "MO",
              'CBSA Number': "27900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "099",
              name: "JEFFERSON COUNTY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "101",
              name: "JOHNSON COUNTY",
              state: "MO",
              'CBSA Number': "47660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "103",
              name: "KNOX COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "105",
              name: "LACLEDE COUNTY",
              state: "MO",
              'CBSA Number': "30060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "107",
              name: "LAFAYETTE COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "109",
              name: "LAWRENCE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "111",
              name: "LEWIS COUNTY",
              state: "MO",
              'CBSA Number': "39500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "113",
              name: "LINCOLN COUNTY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "115",
              name: "LINN COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "117",
              name: "LIVINGSTON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "119",
              name: "MCDONALD COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "121",
              name: "MACON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "123",
              name: "MADISON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "125",
              name: "MARIES COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "127",
              name: "MARION COUNTY",
              state: "MO",
              'CBSA Number': "25300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "129",
              name: "MERCER COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "131",
              name: "MILLER COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "133",
              name: "MISSISSIPPI COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "135",
              name: "MONITEAU COUNTY",
              state: "MO",
              'CBSA Number': "27620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "137",
              name: "MONROE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "139",
              name: "MONTGOMERY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "141",
              name: "MORGAN COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "143",
              name: "NEW MADRID COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "145",
              name: "NEWTON COUNTY",
              state: "MO",
              'CBSA Number': "27900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "147",
              name: "NODAWAY COUNTY",
              state: "MO",
              'CBSA Number': "32340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "149",
              name: "OREGON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "151",
              name: "OSAGE COUNTY",
              state: "MO",
              'CBSA Number': "27620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "153",
              name: "OZARK COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "155",
              name: "PEMISCOT COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "157",
              name: "PERRY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "159",
              name: "PETTIS COUNTY",
              state: "MO",
              'CBSA Number': "42740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "161",
              name: "PHELPS COUNTY",
              state: "MO",
              'CBSA Number': "40620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "163",
              name: "PIKE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "165",
              name: "PLATTE COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "167",
              name: "POLK COUNTY",
              state: "MO",
              'CBSA Number': "44180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "169",
              name: "PULASKI COUNTY",
              state: "MO",
              'CBSA Number': "22780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "171",
              name: "PUTNAM COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "173",
              name: "RALLS COUNTY",
              state: "MO",
              'CBSA Number': "25300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "175",
              name: "RANDOLPH COUNTY",
              state: "MO",
              'CBSA Number': "33620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "177",
              name: "RAY COUNTY",
              state: "MO",
              'CBSA Number': "28140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "179",
              name: "REYNOLDS COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "181",
              name: "RIPLEY COUNTY",
              state: "MO",
              'CBSA Number': "38740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "183",
              name: "ST. CHARLES COUNTY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "185",
              name: "ST. CLAIR COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "186",
              name: "STE. GENEVIEVE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "187",
              name: "ST. FRANCOIS COUNTY",
              state: "MO",
              'CBSA Number': "22100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "189",
              name: "ST. LOUIS COUNTY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "195",
              name: "SALINE COUNTY",
              state: "MO",
              'CBSA Number': "32180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "197",
              name: "SCHUYLER COUNTY",
              state: "MO",
              'CBSA Number': "28860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "199",
              name: "SCOTLAND COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "201",
              name: "SCOTT COUNTY",
              state: "MO",
              'CBSA Number': "43460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "203",
              name: "SHANNON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "205",
              name: "SHELBY COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "207",
              name: "STODDARD COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "209",
              name: "STONE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "211",
              name: "SULLIVAN COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "213",
              name: "TANEY COUNTY",
              state: "MO",
              'CBSA Number': "14700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "215",
              name: "TEXAS COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "217",
              name: "VERNON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "219",
              name: "WARREN COUNTY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "221",
              name: "WASHINGTON COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "223",
              name: "WAYNE COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "225",
              name: "WEBSTER COUNTY",
              state: "MO",
              'CBSA Number': "44180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "227",
              name: "WORTH COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "229",
              name: "WRIGHT COUNTY",
              state: "MO",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "29",
              countyCode: "510",
              name: "ST. LOUIS CITY",
              state: "MO",
              'CBSA Number': "41180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "001",
              name: "BEAVERHEAD COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "003",
              name: "BIG HORN COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "005",
              name: "BLAINE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "007",
              name: "BROADWATER COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "009",
              name: "CARBON COUNTY",
              state: "MT",
              'CBSA Number': "13740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "011",
              name: "CARTER COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "013",
              name: "CASCADE COUNTY",
              state: "MT",
              'CBSA Number': "24500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "015",
              name: "CHOUTEAU COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "017",
              name: "CUSTER COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "019",
              name: "DANIELS COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "021",
              name: "DAWSON COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "023",
              name: "DEER LODGE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "025",
              name: "FALLON COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "027",
              name: "FERGUS COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "029",
              name: "FLATHEAD COUNTY",
              state: "MT",
              'CBSA Number': "28060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "031",
              name: "GALLATIN COUNTY",
              state: "MT",
              'CBSA Number': "14580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "033",
              name: "GARFIELD COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "035",
              name: "GLACIER COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "037",
              name: "GOLDEN VALLEY COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "039",
              name: "GRANITE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "041",
              name: "HILL COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "043",
              name: "JEFFERSON COUNTY",
              state: "MT",
              'CBSA Number': "25740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "045",
              name: "JUDITH BASIN COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "047",
              name: "LAKE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "049",
              name: "LEWIS AND CLARK COUNTY",
              state: "MT",
              'CBSA Number': "25740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "051",
              name: "LIBERTY COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "053",
              name: "LINCOLN COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "055",
              name: "MCCONE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "057",
              name: "MADISON COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "059",
              name: "MEAGHER COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "061",
              name: "MINERAL COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "063",
              name: "MISSOULA COUNTY",
              state: "MT",
              'CBSA Number': "33540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "065",
              name: "MUSSELSHELL COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "067",
              name: "PARK COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "069",
              name: "PETROLEUM COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "071",
              name: "PHILLIPS COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "073",
              name: "PONDERA COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "075",
              name: "POWDER RIVER COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "077",
              name: "POWELL COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "079",
              name: "PRAIRIE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "081",
              name: "RAVALLI COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "083",
              name: "RICHLAND COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "085",
              name: "ROOSEVELT COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "087",
              name: "ROSEBUD COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "089",
              name: "SANDERS COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "091",
              name: "SHERIDAN COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "093",
              name: "SILVER BOW COUNTY",
              state: "MT",
              'CBSA Number': "15580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "095",
              name: "STILLWATER COUNTY",
              state: "MT",
              'CBSA Number': "13740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "097",
              name: "SWEET GRASS COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "099",
              name: "TETON COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "101",
              name: "TOOLE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "103",
              name: "TREASURE COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "105",
              name: "VALLEY COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "107",
              name: "WHEATLAND COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "109",
              name: "WIBAUX COUNTY",
              state: "MT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "30",
              countyCode: "111",
              name: "YELLOWSTONE COUNTY",
              state: "MT",
              'CBSA Number': "13740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "NE",
              'CBSA Number': "25580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "003",
              name: "ANTELOPE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "005",
              name: "ARTHUR COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "007",
              name: "BANNER COUNTY",
              state: "NE",
              'CBSA Number': "42420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "009",
              name: "BLAINE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "011",
              name: "BOONE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "013",
              name: "BOX BUTTE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "015",
              name: "BOYD COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "017",
              name: "BROWN COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "019",
              name: "BUFFALO COUNTY",
              state: "NE",
              'CBSA Number': "28260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "021",
              name: "BURT COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "023",
              name: "BUTLER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "025",
              name: "CASS COUNTY",
              state: "NE",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "027",
              name: "CEDAR COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "029",
              name: "CHASE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "031",
              name: "CHERRY COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "033",
              name: "CHEYENNE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "035",
              name: "CLAY COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "037",
              name: "COLFAX COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "039",
              name: "CUMING COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "041",
              name: "CUSTER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "043",
              name: "DAKOTA COUNTY",
              state: "NE",
              'CBSA Number': "43580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "045",
              name: "DAWES COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "047",
              name: "DAWSON COUNTY",
              state: "NE",
              'CBSA Number': "30420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "049",
              name: "DEUEL COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "051",
              name: "DIXON COUNTY",
              state: "NE",
              'CBSA Number': "43580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "053",
              name: "DODGE COUNTY",
              state: "NE",
              'CBSA Number': "23340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "055",
              name: "DOUGLAS COUNTY",
              state: "NE",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "057",
              name: "DUNDY COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "059",
              name: "FILLMORE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "061",
              name: "FRANKLIN COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "063",
              name: "FRONTIER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "065",
              name: "FURNAS COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "067",
              name: "GAGE COUNTY",
              state: "NE",
              'CBSA Number': "13100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "069",
              name: "GARDEN COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "071",
              name: "GARFIELD COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "073",
              name: "GOSPER COUNTY",
              state: "NE",
              'CBSA Number': "30420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "075",
              name: "GRANT COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "077",
              name: "GREELEY COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "079",
              name: "HALL COUNTY",
              state: "NE",
              'CBSA Number': "24260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "081",
              name: "HAMILTON COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "083",
              name: "HARLAN COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "085",
              name: "HAYES COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "087",
              name: "HITCHCOCK COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "089",
              name: "HOLT COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "091",
              name: "HOOKER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "093",
              name: "HOWARD COUNTY",
              state: "NE",
              'CBSA Number': "24260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "095",
              name: "JEFFERSON COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "097",
              name: "JOHNSON COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "099",
              name: "KEARNEY COUNTY",
              state: "NE",
              'CBSA Number': "28260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "101",
              name: "KEITH COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "103",
              name: "KEYA PAHA COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "105",
              name: "KIMBALL COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "107",
              name: "KNOX COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "109",
              name: "LANCASTER COUNTY",
              state: "NE",
              'CBSA Number': "30700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "111",
              name: "LINCOLN COUNTY",
              state: "NE",
              'CBSA Number': "35820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "113",
              name: "LOGAN COUNTY",
              state: "NE",
              'CBSA Number': "35820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "115",
              name: "LOUP COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "117",
              name: "MCPHERSON COUNTY",
              state: "NE",
              'CBSA Number': "35820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "119",
              name: "MADISON COUNTY",
              state: "NE",
              'CBSA Number': "35740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "121",
              name: "MERRICK COUNTY",
              state: "NE",
              'CBSA Number': "24260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "123",
              name: "MORRILL COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "125",
              name: "NANCE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "127",
              name: "NEMAHA COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "129",
              name: "NUCKOLLS COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "131",
              name: "OTOE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "133",
              name: "PAWNEE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "135",
              name: "PERKINS COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "137",
              name: "PHELPS COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "139",
              name: "PIERCE COUNTY",
              state: "NE",
              'CBSA Number': "35740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "141",
              name: "PLATTE COUNTY",
              state: "NE",
              'CBSA Number': "18100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "143",
              name: "POLK COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "145",
              name: "RED WILLOW COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "147",
              name: "RICHARDSON COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "149",
              name: "ROCK COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "151",
              name: "SALINE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "153",
              name: "SARPY COUNTY",
              state: "NE",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "155",
              name: "SAUNDERS COUNTY",
              state: "NE",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "157",
              name: "SCOTTS BLUFF COUNTY",
              state: "NE",
              'CBSA Number': "42420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "159",
              name: "SEWARD COUNTY",
              state: "NE",
              'CBSA Number': "30700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "161",
              name: "SHERIDAN COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "163",
              name: "SHERMAN COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "165",
              name: "SIOUX COUNTY",
              state: "NE",
              'CBSA Number': "42420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "167",
              name: "STANTON COUNTY",
              state: "NE",
              'CBSA Number': "35740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "169",
              name: "THAYER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "171",
              name: "THOMAS COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "173",
              name: "THURSTON COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "175",
              name: "VALLEY COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "177",
              name: "WASHINGTON COUNTY",
              state: "NE",
              'CBSA Number': "36540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "179",
              name: "WAYNE COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "181",
              name: "WEBSTER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "183",
              name: "WHEELER COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "31",
              countyCode: "185",
              name: "YORK COUNTY",
              state: "NE",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "001",
              name: "CHURCHILL COUNTY",
              state: "NV",
              'CBSA Number': "21980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "003",
              name: "CLARK COUNTY",
              state: "NV",
              'CBSA Number': "29820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "005",
              name: "DOUGLAS COUNTY",
              state: "NV",
              'CBSA Number': "23820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "007",
              name: "ELKO COUNTY",
              state: "NV",
              'CBSA Number': "21220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "009",
              name: "ESMERALDA COUNTY",
              state: "NV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "011",
              name: "EUREKA COUNTY",
              state: "NV",
              'CBSA Number': "21220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "013",
              name: "HUMBOLDT COUNTY",
              state: "NV",
              'CBSA Number': "49080",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "015",
              name: "LANDER COUNTY",
              state: "NV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "017",
              name: "LINCOLN COUNTY",
              state: "NV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "019",
              name: "LYON COUNTY",
              state: "NV",
              'CBSA Number': "22280",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "021",
              name: "MINERAL COUNTY",
              state: "NV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "023",
              name: "NYE COUNTY",
              state: "NV",
              'CBSA Number': "37220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "027",
              name: "PERSHING COUNTY",
              state: "NV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "029",
              name: "STOREY COUNTY",
              state: "NV",
              'CBSA Number': "39900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "031",
              name: "WASHOE COUNTY",
              state: "NV",
              'CBSA Number': "39900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "033",
              name: "WHITE PINE COUNTY",
              state: "NV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "32",
              countyCode: "510",
              name: "CARSON CITY",
              state: "NV",
              'CBSA Number': "16180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "001",
              name: "BELKNAP COUNTY",
              state: "NH",
              'CBSA Number': "29060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "003",
              name: "CARROLL COUNTY",
              state: "NH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "005",
              name: "CHESHIRE COUNTY",
              state: "NH",
              'CBSA Number': "28300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "007",
              name: "COOS COUNTY",
              state: "NH",
              'CBSA Number': "13620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "009",
              name: "GRAFTON COUNTY",
              state: "NH",
              'CBSA Number': "30100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "011",
              name: "HILLSBOROUGH COUNTY",
              state: "NH",
              'CBSA Number': "31700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "013",
              name: "MERRIMACK COUNTY",
              state: "NH",
              'CBSA Number': "18180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "33",
              countyCode: "015",
              name: "ROCKINGHAM COUNTY",
              state: "NH",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "33",
              countyCode: "017",
              name: "STRAFFORD COUNTY",
              state: "NH",
              'CBSA Number': "14460",
              oneUnit: " $724,500 ",
              twoUnit: " $927,500 ",
              threeUnit: " $1,121,150 ",
              fourUnit: " $1,393,300 "
            },
            {
              stateCode: "33",
              countyCode: "019",
              name: "SULLIVAN COUNTY",
              state: "NH",
              'CBSA Number': "30100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "001",
              name: "ATLANTIC COUNTY",
              state: "NJ",
              'CBSA Number': "12100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "003",
              name: "BERGEN COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "005",
              name: "BURLINGTON COUNTY",
              state: "NJ",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "007",
              name: "CAMDEN COUNTY",
              state: "NJ",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "009",
              name: "CAPE MAY COUNTY",
              state: "NJ",
              'CBSA Number': "36140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "011",
              name: "CUMBERLAND COUNTY",
              state: "NJ",
              'CBSA Number': "47220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "013",
              name: "ESSEX COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "015",
              name: "GLOUCESTER COUNTY",
              state: "NJ",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "017",
              name: "HUDSON COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "019",
              name: "HUNTERDON COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "021",
              name: "MERCER COUNTY",
              state: "NJ",
              'CBSA Number': "45940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "023",
              name: "MIDDLESEX COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "025",
              name: "MONMOUTH COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "027",
              name: "MORRIS COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "029",
              name: "OCEAN COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "031",
              name: "PASSAIC COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "033",
              name: "SALEM COUNTY",
              state: "NJ",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "34",
              countyCode: "035",
              name: "SOMERSET COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "037",
              name: "SUSSEX COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "039",
              name: "UNION COUNTY",
              state: "NJ",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "34",
              countyCode: "041",
              name: "WARREN COUNTY",
              state: "NJ",
              'CBSA Number': "10900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "001",
              name: "BERNALILLO COUNTY",
              state: "NM",
              'CBSA Number': "10740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "003",
              name: "CATRON COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "005",
              name: "CHAVES COUNTY",
              state: "NM",
              'CBSA Number': "40740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "006",
              name: "CIBOLA COUNTY",
              state: "NM",
              'CBSA Number': "24380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "007",
              name: "COLFAX COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "009",
              name: "CURRY COUNTY",
              state: "NM",
              'CBSA Number': "17580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "011",
              name: "DE BACA COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "013",
              name: "DONA ANA COUNTY",
              state: "NM",
              'CBSA Number': "29740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "015",
              name: "EDDY COUNTY",
              state: "NM",
              'CBSA Number': "16100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "017",
              name: "GRANT COUNTY",
              state: "NM",
              'CBSA Number': "43500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "019",
              name: "GUADALUPE COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "021",
              name: "HARDING COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "023",
              name: "HIDALGO COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "025",
              name: "LEA COUNTY",
              state: "NM",
              'CBSA Number': "26020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "027",
              name: "LINCOLN COUNTY",
              state: "NM",
              'CBSA Number': "40760",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "028",
              name: "LOS ALAMOS COUNTY",
              state: "NM",
              'CBSA Number': "31060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "029",
              name: "LUNA COUNTY",
              state: "NM",
              'CBSA Number': "19700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "031",
              name: "MCKINLEY COUNTY",
              state: "NM",
              'CBSA Number': "23700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "033",
              name: "MORA COUNTY",
              state: "NM",
              'CBSA Number': "29780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "035",
              name: "OTERO COUNTY",
              state: "NM",
              'CBSA Number': "10460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "037",
              name: "QUAY COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "039",
              name: "RIO ARRIBA COUNTY",
              state: "NM",
              'CBSA Number': "21580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "041",
              name: "ROOSEVELT COUNTY",
              state: "NM",
              'CBSA Number': "38780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "043",
              name: "SANDOVAL COUNTY",
              state: "NM",
              'CBSA Number': "10740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "045",
              name: "SAN JUAN COUNTY",
              state: "NM",
              'CBSA Number': "22140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "047",
              name: "SAN MIGUEL COUNTY",
              state: "NM",
              'CBSA Number': "29780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "049",
              name: "SANTA FE COUNTY",
              state: "NM",
              'CBSA Number': "42140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "051",
              name: "SIERRA COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "053",
              name: "SOCORRO COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "055",
              name: "TAOS COUNTY",
              state: "NM",
              'CBSA Number': "45340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "057",
              name: "TORRANCE COUNTY",
              state: "NM",
              'CBSA Number': "10740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "059",
              name: "UNION COUNTY",
              state: "NM",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "35",
              countyCode: "061",
              name: "VALENCIA COUNTY",
              state: "NM",
              'CBSA Number': "10740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "001",
              name: "ALBANY COUNTY",
              state: "NY",
              'CBSA Number': "10580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "003",
              name: "ALLEGANY COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "005",
              name: "BRONX COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "007",
              name: "BROOME COUNTY",
              state: "NY",
              'CBSA Number': "13780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "009",
              name: "CATTARAUGUS COUNTY",
              state: "NY",
              'CBSA Number': "36460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "011",
              name: "CAYUGA COUNTY",
              state: "NY",
              'CBSA Number': "12180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "013",
              name: "CHAUTAUQUA COUNTY",
              state: "NY",
              'CBSA Number': "27460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "015",
              name: "CHEMUNG COUNTY",
              state: "NY",
              'CBSA Number': "21300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "017",
              name: "CHENANGO COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "019",
              name: "CLINTON COUNTY",
              state: "NY",
              'CBSA Number': "38460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "021",
              name: "COLUMBIA COUNTY",
              state: "NY",
              'CBSA Number': "26460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "023",
              name: "CORTLAND COUNTY",
              state: "NY",
              'CBSA Number': "18660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "025",
              name: "DELAWARE COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "027",
              name: "DUTCHESS COUNTY",
              state: "NY",
              'CBSA Number': "39100",
              oneUnit: " $726,525 ",
              twoUnit: " $930,300 ",
              threeUnit: " $1,124,475 ",
              fourUnit: " $1,397,400 "
            },
            {
              stateCode: "36",
              countyCode: "029",
              name: "ERIE COUNTY",
              state: "NY",
              'CBSA Number': "15380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "031",
              name: "ESSEX COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "033",
              name: "FRANKLIN COUNTY",
              state: "NY",
              'CBSA Number': "31660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "035",
              name: "FULTON COUNTY",
              state: "NY",
              'CBSA Number': "24100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "037",
              name: "GENESEE COUNTY",
              state: "NY",
              'CBSA Number': "12860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "039",
              name: "GREENE COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "041",
              name: "HAMILTON COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "043",
              name: "HERKIMER COUNTY",
              state: "NY",
              'CBSA Number': "46540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "045",
              name: "JEFFERSON COUNTY",
              state: "NY",
              'CBSA Number': "48060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "047",
              name: "KINGS COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "049",
              name: "LEWIS COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "051",
              name: "LIVINGSTON COUNTY",
              state: "NY",
              'CBSA Number': "40380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "053",
              name: "MADISON COUNTY",
              state: "NY",
              'CBSA Number': "45060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "055",
              name: "MONROE COUNTY",
              state: "NY",
              'CBSA Number': "40380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "057",
              name: "MONTGOMERY COUNTY",
              state: "NY",
              'CBSA Number': "11220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "059",
              name: "NASSAU COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "061",
              name: "NEW YORK COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "063",
              name: "NIAGARA COUNTY",
              state: "NY",
              'CBSA Number': "15380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "065",
              name: "ONEIDA COUNTY",
              state: "NY",
              'CBSA Number': "46540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "067",
              name: "ONONDAGA COUNTY",
              state: "NY",
              'CBSA Number': "45060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "069",
              name: "ONTARIO COUNTY",
              state: "NY",
              'CBSA Number': "40380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "071",
              name: "ORANGE COUNTY",
              state: "NY",
              'CBSA Number': "39100",
              oneUnit: " $726,525 ",
              twoUnit: " $930,300 ",
              threeUnit: " $1,124,475 ",
              fourUnit: " $1,397,400 "
            },
            {
              stateCode: "36",
              countyCode: "073",
              name: "ORLEANS COUNTY",
              state: "NY",
              'CBSA Number': "40380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "075",
              name: "OSWEGO COUNTY",
              state: "NY",
              'CBSA Number': "45060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "077",
              name: "OTSEGO COUNTY",
              state: "NY",
              'CBSA Number': "36580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "079",
              name: "PUTNAM COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "081",
              name: "QUEENS COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "083",
              name: "RENSSELAER COUNTY",
              state: "NY",
              'CBSA Number': "10580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "085",
              name: "RICHMOND COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "087",
              name: "ROCKLAND COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "089",
              name: "ST. LAWRENCE COUNTY",
              state: "NY",
              'CBSA Number': "36300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "091",
              name: "SARATOGA COUNTY",
              state: "NY",
              'CBSA Number': "10580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "093",
              name: "SCHENECTADY COUNTY",
              state: "NY",
              'CBSA Number': "10580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "095",
              name: "SCHOHARIE COUNTY",
              state: "NY",
              'CBSA Number': "10580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "097",
              name: "SCHUYLER COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "099",
              name: "SENECA COUNTY",
              state: "NY",
              'CBSA Number': "42900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "101",
              name: "STEUBEN COUNTY",
              state: "NY",
              'CBSA Number': "18500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "103",
              name: "SUFFOLK COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "105",
              name: "SULLIVAN COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "107",
              name: "TIOGA COUNTY",
              state: "NY",
              'CBSA Number': "13780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "109",
              name: "TOMPKINS COUNTY",
              state: "NY",
              'CBSA Number': "27060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "111",
              name: "ULSTER COUNTY",
              state: "NY",
              'CBSA Number': "28740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "113",
              name: "WARREN COUNTY",
              state: "NY",
              'CBSA Number': "24020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "115",
              name: "WASHINGTON COUNTY",
              state: "NY",
              'CBSA Number': "24020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "117",
              name: "WAYNE COUNTY",
              state: "NY",
              'CBSA Number': "40380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "119",
              name: "WESTCHESTER COUNTY",
              state: "NY",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "36",
              countyCode: "121",
              name: "WYOMING COUNTY",
              state: "NY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "36",
              countyCode: "123",
              name: "YATES COUNTY",
              state: "NY",
              'CBSA Number': "40380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "001",
              name: "ALAMANCE COUNTY",
              state: "NC",
              'CBSA Number': "15500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "003",
              name: "ALEXANDER COUNTY",
              state: "NC",
              'CBSA Number': "25860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "005",
              name: "ALLEGHANY COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "007",
              name: "ANSON COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "009",
              name: "ASHE COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "011",
              name: "AVERY COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "013",
              name: "BEAUFORT COUNTY",
              state: "NC",
              'CBSA Number': "47820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "015",
              name: "BERTIE COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "017",
              name: "BLADEN COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "019",
              name: "BRUNSWICK COUNTY",
              state: "NC",
              'CBSA Number': "34820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "021",
              name: "BUNCOMBE COUNTY",
              state: "NC",
              'CBSA Number': "11700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "023",
              name: "BURKE COUNTY",
              state: "NC",
              'CBSA Number': "25860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "025",
              name: "CABARRUS COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "027",
              name: "CALDWELL COUNTY",
              state: "NC",
              'CBSA Number': "25860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "029",
              name: "CAMDEN COUNTY",
              state: "NC",
              'CBSA Number': "47260",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "37",
              countyCode: "031",
              name: "CARTERET COUNTY",
              state: "NC",
              'CBSA Number': "33980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "033",
              name: "CASWELL COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "035",
              name: "CATAWBA COUNTY",
              state: "NC",
              'CBSA Number': "25860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "037",
              name: "CHATHAM COUNTY",
              state: "NC",
              'CBSA Number': "20500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "039",
              name: "CHEROKEE COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "041",
              name: "CHOWAN COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "043",
              name: "CLAY COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "045",
              name: "CLEVELAND COUNTY",
              state: "NC",
              'CBSA Number': "43140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "047",
              name: "COLUMBUS COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "049",
              name: "CRAVEN COUNTY",
              state: "NC",
              'CBSA Number': "35100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "051",
              name: "CUMBERLAND COUNTY",
              state: "NC",
              'CBSA Number': "22180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "053",
              name: "CURRITUCK COUNTY",
              state: "NC",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "055",
              name: "DARE COUNTY",
              state: "NC",
              'CBSA Number': "28620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "057",
              name: "DAVIDSON COUNTY",
              state: "NC",
              'CBSA Number': "49180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "059",
              name: "DAVIE COUNTY",
              state: "NC",
              'CBSA Number': "49180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "061",
              name: "DUPLIN COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "063",
              name: "DURHAM COUNTY",
              state: "NC",
              'CBSA Number': "20500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "065",
              name: "EDGECOMBE COUNTY",
              state: "NC",
              'CBSA Number': "40580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "067",
              name: "FORSYTH COUNTY",
              state: "NC",
              'CBSA Number': "49180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "069",
              name: "FRANKLIN COUNTY",
              state: "NC",
              'CBSA Number': "39580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "071",
              name: "GASTON COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "073",
              name: "GATES COUNTY",
              state: "NC",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "075",
              name: "GRAHAM COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "077",
              name: "GRANVILLE COUNTY",
              state: "NC",
              'CBSA Number': "20500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "079",
              name: "GREENE COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "081",
              name: "GUILFORD COUNTY",
              state: "NC",
              'CBSA Number': "24660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "083",
              name: "HALIFAX COUNTY",
              state: "NC",
              'CBSA Number': "40260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "085",
              name: "HARNETT COUNTY",
              state: "NC",
              'CBSA Number': "22180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "087",
              name: "HAYWOOD COUNTY",
              state: "NC",
              'CBSA Number': "11700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "089",
              name: "HENDERSON COUNTY",
              state: "NC",
              'CBSA Number': "11700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "091",
              name: "HERTFORD COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "093",
              name: "HOKE COUNTY",
              state: "NC",
              'CBSA Number': "22180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "095",
              name: "HYDE COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "097",
              name: "IREDELL COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "099",
              name: "JACKSON COUNTY",
              state: "NC",
              'CBSA Number': "19000",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "101",
              name: "JOHNSTON COUNTY",
              state: "NC",
              'CBSA Number': "39580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "103",
              name: "JONES COUNTY",
              state: "NC",
              'CBSA Number': "35100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "105",
              name: "LEE COUNTY",
              state: "NC",
              'CBSA Number': "41820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "107",
              name: "LENOIR COUNTY",
              state: "NC",
              'CBSA Number': "28820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "109",
              name: "LINCOLN COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "111",
              name: "MCDOWELL COUNTY",
              state: "NC",
              'CBSA Number': "32000",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "113",
              name: "MACON COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "115",
              name: "MADISON COUNTY",
              state: "NC",
              'CBSA Number': "11700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "117",
              name: "MARTIN COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "119",
              name: "MECKLENBURG COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "121",
              name: "MITCHELL COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "123",
              name: "MONTGOMERY COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "125",
              name: "MOORE COUNTY",
              state: "NC",
              'CBSA Number': "38240",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "127",
              name: "NASH COUNTY",
              state: "NC",
              'CBSA Number': "40580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "129",
              name: "NEW HANOVER COUNTY",
              state: "NC",
              'CBSA Number': "48900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "131",
              name: "NORTHAMPTON COUNTY",
              state: "NC",
              'CBSA Number': "40260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "133",
              name: "ONSLOW COUNTY",
              state: "NC",
              'CBSA Number': "27340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "135",
              name: "ORANGE COUNTY",
              state: "NC",
              'CBSA Number': "20500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "137",
              name: "PAMLICO COUNTY",
              state: "NC",
              'CBSA Number': "35100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "139",
              name: "PASQUOTANK COUNTY",
              state: "NC",
              'CBSA Number': "21020",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "37",
              countyCode: "141",
              name: "PENDER COUNTY",
              state: "NC",
              'CBSA Number': "48900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "143",
              name: "PERQUIMANS COUNTY",
              state: "NC",
              'CBSA Number': "21020",
              oneUnit: " $625,500 ",
              twoUnit: " $800,775 ",
              threeUnit: " $967,950 ",
              fourUnit: " $1,202,925 "
            },
            {
              stateCode: "37",
              countyCode: "145",
              name: "PERSON COUNTY",
              state: "NC",
              'CBSA Number': "20500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "147",
              name: "PITT COUNTY",
              state: "NC",
              'CBSA Number': "24780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "149",
              name: "POLK COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "151",
              name: "RANDOLPH COUNTY",
              state: "NC",
              'CBSA Number': "24660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "153",
              name: "RICHMOND COUNTY",
              state: "NC",
              'CBSA Number': "40460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "155",
              name: "ROBESON COUNTY",
              state: "NC",
              'CBSA Number': "31300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "157",
              name: "ROCKINGHAM COUNTY",
              state: "NC",
              'CBSA Number': "24660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "159",
              name: "ROWAN COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "161",
              name: "RUTHERFORD COUNTY",
              state: "NC",
              'CBSA Number': "22580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "163",
              name: "SAMPSON COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "165",
              name: "SCOTLAND COUNTY",
              state: "NC",
              'CBSA Number': "29900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "167",
              name: "STANLY COUNTY",
              state: "NC",
              'CBSA Number': "10620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "169",
              name: "STOKES COUNTY",
              state: "NC",
              'CBSA Number': "49180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "171",
              name: "SURRY COUNTY",
              state: "NC",
              'CBSA Number': "34340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "173",
              name: "SWAIN COUNTY",
              state: "NC",
              'CBSA Number': "19000",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "175",
              name: "TRANSYLVANIA COUNTY",
              state: "NC",
              'CBSA Number': "14820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "177",
              name: "TYRRELL COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "179",
              name: "UNION COUNTY",
              state: "NC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "181",
              name: "VANCE COUNTY",
              state: "NC",
              'CBSA Number': "25780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "183",
              name: "WAKE COUNTY",
              state: "NC",
              'CBSA Number': "39580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "185",
              name: "WARREN COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "187",
              name: "WASHINGTON COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "189",
              name: "WATAUGA COUNTY",
              state: "NC",
              'CBSA Number': "14380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "191",
              name: "WAYNE COUNTY",
              state: "NC",
              'CBSA Number': "24140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "193",
              name: "WILKES COUNTY",
              state: "NC",
              'CBSA Number': "35900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "195",
              name: "WILSON COUNTY",
              state: "NC",
              'CBSA Number': "48980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "197",
              name: "YADKIN COUNTY",
              state: "NC",
              'CBSA Number': "49180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "37",
              countyCode: "199",
              name: "YANCEY COUNTY",
              state: "NC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "003",
              name: "BARNES COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "005",
              name: "BENSON COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "007",
              name: "BILLINGS COUNTY",
              state: "ND",
              'CBSA Number': "19860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "009",
              name: "BOTTINEAU COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "011",
              name: "BOWMAN COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "013",
              name: "BURKE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "015",
              name: "BURLEIGH COUNTY",
              state: "ND",
              'CBSA Number': "13900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "017",
              name: "CASS COUNTY",
              state: "ND",
              'CBSA Number': "22020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "019",
              name: "CAVALIER COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "021",
              name: "DICKEY COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "023",
              name: "DIVIDE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "025",
              name: "DUNN COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "027",
              name: "EDDY COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "029",
              name: "EMMONS COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "031",
              name: "FOSTER COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "033",
              name: "GOLDEN VALLEY COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "035",
              name: "GRAND FORKS COUNTY",
              state: "ND",
              'CBSA Number': "24220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "037",
              name: "GRANT COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "039",
              name: "GRIGGS COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "041",
              name: "HETTINGER COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "043",
              name: "KIDDER COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "045",
              name: "LAMOURE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "047",
              name: "LOGAN COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "049",
              name: "MCHENRY COUNTY",
              state: "ND",
              'CBSA Number': "33500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "051",
              name: "MCINTOSH COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "053",
              name: "MCKENZIE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "055",
              name: "MCLEAN COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "057",
              name: "MERCER COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "059",
              name: "MORTON COUNTY",
              state: "ND",
              'CBSA Number': "13900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "061",
              name: "MOUNTRAIL COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "063",
              name: "NELSON COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "065",
              name: "OLIVER COUNTY",
              state: "ND",
              'CBSA Number': "13900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "067",
              name: "PEMBINA COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "069",
              name: "PIERCE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "071",
              name: "RAMSEY COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "073",
              name: "RANSOM COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "075",
              name: "RENVILLE COUNTY",
              state: "ND",
              'CBSA Number': "33500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "077",
              name: "RICHLAND COUNTY",
              state: "ND",
              'CBSA Number': "47420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "079",
              name: "ROLETTE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "081",
              name: "SARGENT COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "083",
              name: "SHERIDAN COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "085",
              name: "SIOUX COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "087",
              name: "SLOPE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "089",
              name: "STARK COUNTY",
              state: "ND",
              'CBSA Number': "19860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "091",
              name: "STEELE COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "093",
              name: "STUTSMAN COUNTY",
              state: "ND",
              'CBSA Number': "27420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "095",
              name: "TOWNER COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "097",
              name: "TRAILL COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "099",
              name: "WALSH COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "101",
              name: "WARD COUNTY",
              state: "ND",
              'CBSA Number': "33500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "103",
              name: "WELLS COUNTY",
              state: "ND",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "38",
              countyCode: "105",
              name: "WILLIAMS COUNTY",
              state: "ND",
              'CBSA Number': "48780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "003",
              name: "ALLEN COUNTY",
              state: "OH",
              'CBSA Number': "30620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "005",
              name: "ASHLAND COUNTY",
              state: "OH",
              'CBSA Number': "11740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "007",
              name: "ASHTABULA COUNTY",
              state: "OH",
              'CBSA Number': "11780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "009",
              name: "ATHENS COUNTY",
              state: "OH",
              'CBSA Number': "11900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "011",
              name: "AUGLAIZE COUNTY",
              state: "OH",
              'CBSA Number': "47540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "013",
              name: "BELMONT COUNTY",
              state: "OH",
              'CBSA Number': "48540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "015",
              name: "BROWN COUNTY",
              state: "OH",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "017",
              name: "BUTLER COUNTY",
              state: "OH",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "019",
              name: "CARROLL COUNTY",
              state: "OH",
              'CBSA Number': "15940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "021",
              name: "CHAMPAIGN COUNTY",
              state: "OH",
              'CBSA Number': "46500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "023",
              name: "CLARK COUNTY",
              state: "OH",
              'CBSA Number': "44220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "025",
              name: "CLERMONT COUNTY",
              state: "OH",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "027",
              name: "CLINTON COUNTY",
              state: "OH",
              'CBSA Number': "48940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "029",
              name: "COLUMBIANA COUNTY",
              state: "OH",
              'CBSA Number': "41400",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "031",
              name: "COSHOCTON COUNTY",
              state: "OH",
              'CBSA Number': "18740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "033",
              name: "CRAWFORD COUNTY",
              state: "OH",
              'CBSA Number': "15340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "035",
              name: "CUYAHOGA COUNTY",
              state: "OH",
              'CBSA Number': "17460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "037",
              name: "DARKE COUNTY",
              state: "OH",
              'CBSA Number': "24820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "039",
              name: "DEFIANCE COUNTY",
              state: "OH",
              'CBSA Number': "19580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "041",
              name: "DELAWARE COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "043",
              name: "ERIE COUNTY",
              state: "OH",
              'CBSA Number': "41780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "045",
              name: "FAIRFIELD COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "047",
              name: "FAYETTE COUNTY",
              state: "OH",
              'CBSA Number': "47920",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "049",
              name: "FRANKLIN COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "051",
              name: "FULTON COUNTY",
              state: "OH",
              'CBSA Number': "45780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "053",
              name: "GALLIA COUNTY",
              state: "OH",
              'CBSA Number': "38580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "055",
              name: "GEAUGA COUNTY",
              state: "OH",
              'CBSA Number': "17460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "057",
              name: "GREENE COUNTY",
              state: "OH",
              'CBSA Number': "19430",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "059",
              name: "GUERNSEY COUNTY",
              state: "OH",
              'CBSA Number': "15740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "061",
              name: "HAMILTON COUNTY",
              state: "OH",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "063",
              name: "HANCOCK COUNTY",
              state: "OH",
              'CBSA Number': "22300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "065",
              name: "HARDIN COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "067",
              name: "HARRISON COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "069",
              name: "HENRY COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "071",
              name: "HIGHLAND COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "073",
              name: "HOCKING COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "075",
              name: "HOLMES COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "077",
              name: "HURON COUNTY",
              state: "OH",
              'CBSA Number': "35940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "079",
              name: "JACKSON COUNTY",
              state: "OH",
              'CBSA Number': "27160",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "081",
              name: "JEFFERSON COUNTY",
              state: "OH",
              'CBSA Number': "48260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "083",
              name: "KNOX COUNTY",
              state: "OH",
              'CBSA Number': "34540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "085",
              name: "LAKE COUNTY",
              state: "OH",
              'CBSA Number': "17460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "087",
              name: "LAWRENCE COUNTY",
              state: "OH",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "089",
              name: "LICKING COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "091",
              name: "LOGAN COUNTY",
              state: "OH",
              'CBSA Number': "13340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "093",
              name: "LORAIN COUNTY",
              state: "OH",
              'CBSA Number': "17460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "095",
              name: "LUCAS COUNTY",
              state: "OH",
              'CBSA Number': "45780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "097",
              name: "MADISON COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "099",
              name: "MAHONING COUNTY",
              state: "OH",
              'CBSA Number': "49660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "101",
              name: "MARION COUNTY",
              state: "OH",
              'CBSA Number': "32020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "103",
              name: "MEDINA COUNTY",
              state: "OH",
              'CBSA Number': "17460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "105",
              name: "MEIGS COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "107",
              name: "MERCER COUNTY",
              state: "OH",
              'CBSA Number': "16380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "109",
              name: "MIAMI COUNTY",
              state: "OH",
              'CBSA Number': "19430",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "111",
              name: "MONROE COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "113",
              name: "MONTGOMERY COUNTY",
              state: "OH",
              'CBSA Number': "19430",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "115",
              name: "MORGAN COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "117",
              name: "MORROW COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "119",
              name: "MUSKINGUM COUNTY",
              state: "OH",
              'CBSA Number': "49780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "121",
              name: "NOBLE COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "123",
              name: "OTTAWA COUNTY",
              state: "OH",
              'CBSA Number': "45780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "125",
              name: "PAULDING COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "127",
              name: "PERRY COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "129",
              name: "PICKAWAY COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "131",
              name: "PIKE COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "133",
              name: "PORTAGE COUNTY",
              state: "OH",
              'CBSA Number': "10420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "135",
              name: "PREBLE COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "137",
              name: "PUTNAM COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "139",
              name: "RICHLAND COUNTY",
              state: "OH",
              'CBSA Number': "31900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "141",
              name: "ROSS COUNTY",
              state: "OH",
              'CBSA Number': "17060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "143",
              name: "SANDUSKY COUNTY",
              state: "OH",
              'CBSA Number': "23380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "145",
              name: "SCIOTO COUNTY",
              state: "OH",
              'CBSA Number': "39020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "147",
              name: "SENECA COUNTY",
              state: "OH",
              'CBSA Number': "45660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "149",
              name: "SHELBY COUNTY",
              state: "OH",
              'CBSA Number': "43380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "151",
              name: "STARK COUNTY",
              state: "OH",
              'CBSA Number': "15940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "153",
              name: "SUMMIT COUNTY",
              state: "OH",
              'CBSA Number': "10420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "155",
              name: "TRUMBULL COUNTY",
              state: "OH",
              'CBSA Number': "49660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "157",
              name: "TUSCARAWAS COUNTY",
              state: "OH",
              'CBSA Number': "35420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "159",
              name: "UNION COUNTY",
              state: "OH",
              'CBSA Number': "18140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "161",
              name: "VAN WERT COUNTY",
              state: "OH",
              'CBSA Number': "46780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "163",
              name: "VINTON COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "165",
              name: "WARREN COUNTY",
              state: "OH",
              'CBSA Number': "17140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "167",
              name: "WASHINGTON COUNTY",
              state: "OH",
              'CBSA Number': "31930",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "169",
              name: "WAYNE COUNTY",
              state: "OH",
              'CBSA Number': "49300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "171",
              name: "WILLIAMS COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "173",
              name: "WOOD COUNTY",
              state: "OH",
              'CBSA Number': "45780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "39",
              countyCode: "175",
              name: "WYANDOT COUNTY",
              state: "OH",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "001",
              name: "ADAIR COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "003",
              name: "ALFALFA COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "005",
              name: "ATOKA COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "007",
              name: "BEAVER COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "009",
              name: "BECKHAM COUNTY",
              state: "OK",
              'CBSA Number': "21120",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "011",
              name: "BLAINE COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "013",
              name: "BRYAN COUNTY",
              state: "OK",
              'CBSA Number': "20460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "015",
              name: "CADDO COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "017",
              name: "CANADIAN COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "019",
              name: "CARTER COUNTY",
              state: "OK",
              'CBSA Number': "11620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "021",
              name: "CHEROKEE COUNTY",
              state: "OK",
              'CBSA Number': "45140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "023",
              name: "CHOCTAW COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "025",
              name: "CIMARRON COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "027",
              name: "CLEVELAND COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "029",
              name: "COAL COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "031",
              name: "COMANCHE COUNTY",
              state: "OK",
              'CBSA Number': "30020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "033",
              name: "COTTON COUNTY",
              state: "OK",
              'CBSA Number': "30020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "035",
              name: "CRAIG COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "037",
              name: "CREEK COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "039",
              name: "CUSTER COUNTY",
              state: "OK",
              'CBSA Number': "48220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "041",
              name: "DELAWARE COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "043",
              name: "DEWEY COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "045",
              name: "ELLIS COUNTY",
              state: "OK",
              'CBSA Number': "49260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "047",
              name: "GARFIELD COUNTY",
              state: "OK",
              'CBSA Number': "21420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "049",
              name: "GARVIN COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "051",
              name: "GRADY COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "053",
              name: "GRANT COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "055",
              name: "GREER COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "057",
              name: "HARMON COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "059",
              name: "HARPER COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "061",
              name: "HASKELL COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "063",
              name: "HUGHES COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "065",
              name: "JACKSON COUNTY",
              state: "OK",
              'CBSA Number': "11060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "067",
              name: "JEFFERSON COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "069",
              name: "JOHNSTON COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "071",
              name: "KAY COUNTY",
              state: "OK",
              'CBSA Number': "38620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "073",
              name: "KINGFISHER COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "075",
              name: "KIOWA COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "077",
              name: "LATIMER COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "079",
              name: "LE FLORE COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "081",
              name: "LINCOLN COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "083",
              name: "LOGAN COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "085",
              name: "LOVE COUNTY",
              state: "OK",
              'CBSA Number': "11620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "087",
              name: "MCCLAIN COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "089",
              name: "MCCURTAIN COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "091",
              name: "MCINTOSH COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "093",
              name: "MAJOR COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "095",
              name: "MARSHALL COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "097",
              name: "MAYES COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "099",
              name: "MURRAY COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "101",
              name: "MUSKOGEE COUNTY",
              state: "OK",
              'CBSA Number': "34780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "103",
              name: "NOBLE COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "105",
              name: "NOWATA COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "107",
              name: "OKFUSKEE COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "109",
              name: "OKLAHOMA COUNTY",
              state: "OK",
              'CBSA Number': "36420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "111",
              name: "OKMULGEE COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "113",
              name: "OSAGE COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "115",
              name: "OTTAWA COUNTY",
              state: "OK",
              'CBSA Number': "33060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "117",
              name: "PAWNEE COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "119",
              name: "PAYNE COUNTY",
              state: "OK",
              'CBSA Number': "44660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "121",
              name: "PITTSBURG COUNTY",
              state: "OK",
              'CBSA Number': "32540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "123",
              name: "PONTOTOC COUNTY",
              state: "OK",
              'CBSA Number': "10220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "125",
              name: "POTTAWATOMIE COUNTY",
              state: "OK",
              'CBSA Number': "43060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "127",
              name: "PUSHMATAHA COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "129",
              name: "ROGER MILLS COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "131",
              name: "ROGERS COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "133",
              name: "SEMINOLE COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "135",
              name: "SEQUOYAH COUNTY",
              state: "OK",
              'CBSA Number': "22900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "137",
              name: "STEPHENS COUNTY",
              state: "OK",
              'CBSA Number': "20340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "139",
              name: "TEXAS COUNTY",
              state: "OK",
              'CBSA Number': "25100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "141",
              name: "TILLMAN COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "143",
              name: "TULSA COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "145",
              name: "WAGONER COUNTY",
              state: "OK",
              'CBSA Number': "46140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "147",
              name: "WASHINGTON COUNTY",
              state: "OK",
              'CBSA Number': "12780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "149",
              name: "WASHITA COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "151",
              name: "WOODS COUNTY",
              state: "OK",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "40",
              countyCode: "153",
              name: "WOODWARD COUNTY",
              state: "OK",
              'CBSA Number': "49260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "001",
              name: "BAKER COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "003",
              name: "BENTON COUNTY",
              state: "OR",
              'CBSA Number': "18700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "005",
              name: "CLACKAMAS COUNTY",
              state: "OR",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "007",
              name: "CLATSOP COUNTY",
              state: "OR",
              'CBSA Number': "11820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "009",
              name: "COLUMBIA COUNTY",
              state: "OR",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "011",
              name: "COOS COUNTY",
              state: "OR",
              'CBSA Number': "18300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "013",
              name: "CROOK COUNTY",
              state: "OR",
              'CBSA Number': "39260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "015",
              name: "CURRY COUNTY",
              state: "OR",
              'CBSA Number': "15060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "017",
              name: "DESCHUTES COUNTY",
              state: "OR",
              'CBSA Number': "13460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "019",
              name: "DOUGLAS COUNTY",
              state: "OR",
              'CBSA Number': "40700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "021",
              name: "GILLIAM COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "023",
              name: "GRANT COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "025",
              name: "HARNEY COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "027",
              name: "HOOD RIVER COUNTY",
              state: "OR",
              'CBSA Number': "26220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "029",
              name: "JACKSON COUNTY",
              state: "OR",
              'CBSA Number': "32780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "031",
              name: "JEFFERSON COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "033",
              name: "JOSEPHINE COUNTY",
              state: "OR",
              'CBSA Number': "24420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "035",
              name: "KLAMATH COUNTY",
              state: "OR",
              'CBSA Number': "28900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "037",
              name: "LAKE COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "039",
              name: "LANE COUNTY",
              state: "OR",
              'CBSA Number': "21660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "041",
              name: "LINCOLN COUNTY",
              state: "OR",
              'CBSA Number': "35440",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "043",
              name: "LINN COUNTY",
              state: "OR",
              'CBSA Number': "10540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "045",
              name: "MALHEUR COUNTY",
              state: "OR",
              'CBSA Number': "36620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "047",
              name: "MARION COUNTY",
              state: "OR",
              'CBSA Number': "41420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "049",
              name: "MORROW COUNTY",
              state: "OR",
              'CBSA Number': "25840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "051",
              name: "MULTNOMAH COUNTY",
              state: "OR",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "053",
              name: "POLK COUNTY",
              state: "OR",
              'CBSA Number': "41420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "055",
              name: "SHERMAN COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "057",
              name: "TILLAMOOK COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "059",
              name: "UMATILLA COUNTY",
              state: "OR",
              'CBSA Number': "25840",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "061",
              name: "UNION COUNTY",
              state: "OR",
              'CBSA Number': "29260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "063",
              name: "WALLOWA COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "065",
              name: "WASCO COUNTY",
              state: "OR",
              'CBSA Number': "45520",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "067",
              name: "WASHINGTON COUNTY",
              state: "OR",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "069",
              name: "WHEELER COUNTY",
              state: "OR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "41",
              countyCode: "071",
              name: "YAMHILL COUNTY",
              state: "OR",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "PA",
              'CBSA Number': "23900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "003",
              name: "ALLEGHENY COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "005",
              name: "ARMSTRONG COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "007",
              name: "BEAVER COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "009",
              name: "BEDFORD COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "011",
              name: "BERKS COUNTY",
              state: "PA",
              'CBSA Number': "39740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "013",
              name: "BLAIR COUNTY",
              state: "PA",
              'CBSA Number': "11020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "015",
              name: "BRADFORD COUNTY",
              state: "PA",
              'CBSA Number': "42380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "017",
              name: "BUCKS COUNTY",
              state: "PA",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "019",
              name: "BUTLER COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "021",
              name: "CAMBRIA COUNTY",
              state: "PA",
              'CBSA Number': "27780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "023",
              name: "CAMERON COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "025",
              name: "CARBON COUNTY",
              state: "PA",
              'CBSA Number': "10900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "027",
              name: "CENTRE COUNTY",
              state: "PA",
              'CBSA Number': "44300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "029",
              name: "CHESTER COUNTY",
              state: "PA",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "031",
              name: "CLARION COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "033",
              name: "CLEARFIELD COUNTY",
              state: "PA",
              'CBSA Number': "20180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "035",
              name: "CLINTON COUNTY",
              state: "PA",
              'CBSA Number': "30820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "037",
              name: "COLUMBIA COUNTY",
              state: "PA",
              'CBSA Number': "14100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "039",
              name: "CRAWFORD COUNTY",
              state: "PA",
              'CBSA Number': "32740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "041",
              name: "CUMBERLAND COUNTY",
              state: "PA",
              'CBSA Number': "25420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "043",
              name: "DAUPHIN COUNTY",
              state: "PA",
              'CBSA Number': "25420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "045",
              name: "DELAWARE COUNTY",
              state: "PA",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "047",
              name: "ELK COUNTY",
              state: "PA",
              'CBSA Number': "41260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "049",
              name: "ERIE COUNTY",
              state: "PA",
              'CBSA Number': "21500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "051",
              name: "FAYETTE COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "053",
              name: "FOREST COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "055",
              name: "FRANKLIN COUNTY",
              state: "PA",
              'CBSA Number': "16540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "057",
              name: "FULTON COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "059",
              name: "GREENE COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "061",
              name: "HUNTINGDON COUNTY",
              state: "PA",
              'CBSA Number': "26500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "063",
              name: "INDIANA COUNTY",
              state: "PA",
              'CBSA Number': "26860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "065",
              name: "JEFFERSON COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "067",
              name: "JUNIATA COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "069",
              name: "LACKAWANNA COUNTY",
              state: "PA",
              'CBSA Number': "42540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "071",
              name: "LANCASTER COUNTY",
              state: "PA",
              'CBSA Number': "29540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "073",
              name: "LAWRENCE COUNTY",
              state: "PA",
              'CBSA Number': "35260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "075",
              name: "LEBANON COUNTY",
              state: "PA",
              'CBSA Number': "30140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "077",
              name: "LEHIGH COUNTY",
              state: "PA",
              'CBSA Number': "10900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "079",
              name: "LUZERNE COUNTY",
              state: "PA",
              'CBSA Number': "42540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "081",
              name: "LYCOMING COUNTY",
              state: "PA",
              'CBSA Number': "48700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "083",
              name: "MCKEAN COUNTY",
              state: "PA",
              'CBSA Number': "14620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "085",
              name: "MERCER COUNTY",
              state: "PA",
              'CBSA Number': "49660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "087",
              name: "MIFFLIN COUNTY",
              state: "PA",
              'CBSA Number': "30380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "089",
              name: "MONROE COUNTY",
              state: "PA",
              'CBSA Number': "20700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "091",
              name: "MONTGOMERY COUNTY",
              state: "PA",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "093",
              name: "MONTOUR COUNTY",
              state: "PA",
              'CBSA Number': "14100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "095",
              name: "NORTHAMPTON COUNTY",
              state: "PA",
              'CBSA Number': "10900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "097",
              name: "NORTHUMBERLAND COUNTY",
              state: "PA",
              'CBSA Number': "44980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "099",
              name: "PERRY COUNTY",
              state: "PA",
              'CBSA Number': "25420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "101",
              name: "PHILADELPHIA COUNTY",
              state: "PA",
              'CBSA Number': "37980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "103",
              name: "PIKE COUNTY",
              state: "PA",
              'CBSA Number': "35620",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "42",
              countyCode: "105",
              name: "POTTER COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "107",
              name: "SCHUYLKILL COUNTY",
              state: "PA",
              'CBSA Number': "39060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "109",
              name: "SNYDER COUNTY",
              state: "PA",
              'CBSA Number': "42780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "111",
              name: "SOMERSET COUNTY",
              state: "PA",
              'CBSA Number': "43740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "113",
              name: "SULLIVAN COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "115",
              name: "SUSQUEHANNA COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "117",
              name: "TIOGA COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "119",
              name: "UNION COUNTY",
              state: "PA",
              'CBSA Number': "30260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "121",
              name: "VENANGO COUNTY",
              state: "PA",
              'CBSA Number': "36340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "123",
              name: "WARREN COUNTY",
              state: "PA",
              'CBSA Number': "47620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "125",
              name: "WASHINGTON COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "127",
              name: "WAYNE COUNTY",
              state: "PA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "129",
              name: "WESTMORELAND COUNTY",
              state: "PA",
              'CBSA Number': "38300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "131",
              name: "WYOMING COUNTY",
              state: "PA",
              'CBSA Number': "42540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "42",
              countyCode: "133",
              name: "YORK COUNTY",
              state: "PA",
              'CBSA Number': "49620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "44",
              countyCode: "001",
              name: "BRISTOL COUNTY",
              state: "RI",
              'CBSA Number': "39300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "44",
              countyCode: "003",
              name: "KENT COUNTY",
              state: "RI",
              'CBSA Number': "39300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "44",
              countyCode: "005",
              name: "NEWPORT COUNTY",
              state: "RI",
              'CBSA Number': "39300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "44",
              countyCode: "007",
              name: "PROVIDENCE COUNTY",
              state: "RI",
              'CBSA Number': "39300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "44",
              countyCode: "009",
              name: "WASHINGTON COUNTY",
              state: "RI",
              'CBSA Number': "39300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "001",
              name: "ABBEVILLE COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "003",
              name: "AIKEN COUNTY",
              state: "SC",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "005",
              name: "ALLENDALE COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "007",
              name: "ANDERSON COUNTY",
              state: "SC",
              'CBSA Number': "24860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "009",
              name: "BAMBERG COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "011",
              name: "BARNWELL COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "013",
              name: "BEAUFORT COUNTY",
              state: "SC",
              'CBSA Number': "25940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "015",
              name: "BERKELEY COUNTY",
              state: "SC",
              'CBSA Number': "16700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "017",
              name: "CALHOUN COUNTY",
              state: "SC",
              'CBSA Number': "17900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "019",
              name: "CHARLESTON COUNTY",
              state: "SC",
              'CBSA Number': "16700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "021",
              name: "CHEROKEE COUNTY",
              state: "SC",
              'CBSA Number': "23500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "023",
              name: "CHESTER COUNTY",
              state: "SC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "025",
              name: "CHESTERFIELD COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "027",
              name: "CLARENDON COUNTY",
              state: "SC",
              'CBSA Number': "44940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "029",
              name: "COLLETON COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "031",
              name: "DARLINGTON COUNTY",
              state: "SC",
              'CBSA Number': "22500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "033",
              name: "DILLON COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "035",
              name: "DORCHESTER COUNTY",
              state: "SC",
              'CBSA Number': "16700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "037",
              name: "EDGEFIELD COUNTY",
              state: "SC",
              'CBSA Number': "12260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "039",
              name: "FAIRFIELD COUNTY",
              state: "SC",
              'CBSA Number': "17900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "041",
              name: "FLORENCE COUNTY",
              state: "SC",
              'CBSA Number': "22500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "043",
              name: "GEORGETOWN COUNTY",
              state: "SC",
              'CBSA Number': "23860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "045",
              name: "GREENVILLE COUNTY",
              state: "SC",
              'CBSA Number': "24860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "047",
              name: "GREENWOOD COUNTY",
              state: "SC",
              'CBSA Number': "24940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "049",
              name: "HAMPTON COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "051",
              name: "HORRY COUNTY",
              state: "SC",
              'CBSA Number': "34820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "053",
              name: "JASPER COUNTY",
              state: "SC",
              'CBSA Number': "25940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "055",
              name: "KERSHAW COUNTY",
              state: "SC",
              'CBSA Number': "17900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "057",
              name: "LANCASTER COUNTY",
              state: "SC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "059",
              name: "LAURENS COUNTY",
              state: "SC",
              'CBSA Number': "24860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "061",
              name: "LEE COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "063",
              name: "LEXINGTON COUNTY",
              state: "SC",
              'CBSA Number': "17900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "065",
              name: "MCCORMICK COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "067",
              name: "MARION COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "069",
              name: "MARLBORO COUNTY",
              state: "SC",
              'CBSA Number': "13500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "071",
              name: "NEWBERRY COUNTY",
              state: "SC",
              'CBSA Number': "35140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "073",
              name: "OCONEE COUNTY",
              state: "SC",
              'CBSA Number': "42860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "075",
              name: "ORANGEBURG COUNTY",
              state: "SC",
              'CBSA Number': "36700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "077",
              name: "PICKENS COUNTY",
              state: "SC",
              'CBSA Number': "24860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "079",
              name: "RICHLAND COUNTY",
              state: "SC",
              'CBSA Number': "17900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "081",
              name: "SALUDA COUNTY",
              state: "SC",
              'CBSA Number': "17900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "083",
              name: "SPARTANBURG COUNTY",
              state: "SC",
              'CBSA Number': "43900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "085",
              name: "SUMTER COUNTY",
              state: "SC",
              'CBSA Number': "44940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "087",
              name: "UNION COUNTY",
              state: "SC",
              'CBSA Number': "46420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "089",
              name: "WILLIAMSBURG COUNTY",
              state: "SC",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "45",
              countyCode: "091",
              name: "YORK COUNTY",
              state: "SC",
              'CBSA Number': "16740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "003",
              name: "AURORA COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "005",
              name: "BEADLE COUNTY",
              state: "SD",
              'CBSA Number': "26700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "007",
              name: "BENNETT COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "009",
              name: "BON HOMME COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "011",
              name: "BROOKINGS COUNTY",
              state: "SD",
              'CBSA Number': "15100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "013",
              name: "BROWN COUNTY",
              state: "SD",
              'CBSA Number': "10100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "015",
              name: "BRULE COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "017",
              name: "BUFFALO COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "019",
              name: "BUTTE COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "021",
              name: "CAMPBELL COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "023",
              name: "CHARLES MIX COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "025",
              name: "CLARK COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "027",
              name: "CLAY COUNTY",
              state: "SD",
              'CBSA Number': "46820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "029",
              name: "CODINGTON COUNTY",
              state: "SD",
              'CBSA Number': "47980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "031",
              name: "CORSON COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "033",
              name: "CUSTER COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "035",
              name: "DAVISON COUNTY",
              state: "SD",
              'CBSA Number': "33580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "037",
              name: "DAY COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "039",
              name: "DEUEL COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "041",
              name: "DEWEY COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "043",
              name: "DOUGLAS COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "045",
              name: "EDMUNDS COUNTY",
              state: "SD",
              'CBSA Number': "10100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "047",
              name: "FALL RIVER COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "049",
              name: "FAULK COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "051",
              name: "GRANT COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "053",
              name: "GREGORY COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "055",
              name: "HAAKON COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "057",
              name: "HAMLIN COUNTY",
              state: "SD",
              'CBSA Number': "47980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "059",
              name: "HAND COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "061",
              name: "HANSON COUNTY",
              state: "SD",
              'CBSA Number': "33580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "063",
              name: "HARDING COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "065",
              name: "HUGHES COUNTY",
              state: "SD",
              'CBSA Number': "38180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "067",
              name: "HUTCHINSON COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "069",
              name: "HYDE COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "071",
              name: "JACKSON COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "073",
              name: "JERAULD COUNTY",
              state: "SD",
              'CBSA Number': "26700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "075",
              name: "JONES COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "077",
              name: "KINGSBURY COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "079",
              name: "LAKE COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "081",
              name: "LAWRENCE COUNTY",
              state: "SD",
              'CBSA Number': "43940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "083",
              name: "LINCOLN COUNTY",
              state: "SD",
              'CBSA Number': "43620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "085",
              name: "LYMAN COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "087",
              name: "MCCOOK COUNTY",
              state: "SD",
              'CBSA Number': "43620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "089",
              name: "MCPHERSON COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "091",
              name: "MARSHALL COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "093",
              name: "MEADE COUNTY",
              state: "SD",
              'CBSA Number': "39660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "095",
              name: "MELLETTE COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "097",
              name: "MINER COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "099",
              name: "MINNEHAHA COUNTY",
              state: "SD",
              'CBSA Number': "43620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "101",
              name: "MOODY COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "102",
              name: "OGLALA LAKOTA COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "103",
              name: "PENNINGTON COUNTY",
              state: "SD",
              'CBSA Number': "39660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "105",
              name: "PERKINS COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "107",
              name: "POTTER COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "109",
              name: "ROBERTS COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "111",
              name: "SANBORN COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "115",
              name: "SPINK COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "117",
              name: "STANLEY COUNTY",
              state: "SD",
              'CBSA Number': "38180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "119",
              name: "SULLY COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "121",
              name: "TODD COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "123",
              name: "TRIPP COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "125",
              name: "TURNER COUNTY",
              state: "SD",
              'CBSA Number': "43620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "127",
              name: "UNION COUNTY",
              state: "SD",
              'CBSA Number': "43580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "129",
              name: "WALWORTH COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "135",
              name: "YANKTON COUNTY",
              state: "SD",
              'CBSA Number': "49460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "46",
              countyCode: "137",
              name: "ZIEBACH COUNTY",
              state: "SD",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "001",
              name: "ANDERSON COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "003",
              name: "BEDFORD COUNTY",
              state: "TN",
              'CBSA Number': "43180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "005",
              name: "BENTON COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "007",
              name: "BLEDSOE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "009",
              name: "BLOUNT COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "011",
              name: "BRADLEY COUNTY",
              state: "TN",
              'CBSA Number': "17420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "013",
              name: "CAMPBELL COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "015",
              name: "CANNON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "017",
              name: "CARROLL COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "019",
              name: "CARTER COUNTY",
              state: "TN",
              'CBSA Number': "27740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "021",
              name: "CHEATHAM COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "023",
              name: "CHESTER COUNTY",
              state: "TN",
              'CBSA Number': "27180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "025",
              name: "CLAIBORNE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "027",
              name: "CLAY COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "029",
              name: "COCKE COUNTY",
              state: "TN",
              'CBSA Number': "35460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "031",
              name: "COFFEE COUNTY",
              state: "TN",
              'CBSA Number': "46100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "033",
              name: "CROCKETT COUNTY",
              state: "TN",
              'CBSA Number': "27180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "035",
              name: "CUMBERLAND COUNTY",
              state: "TN",
              'CBSA Number': "18900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "037",
              name: "DAVIDSON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "039",
              name: "DECATUR COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "041",
              name: "DEKALB COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "043",
              name: "DICKSON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "045",
              name: "DYER COUNTY",
              state: "TN",
              'CBSA Number': "20540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "047",
              name: "FAYETTE COUNTY",
              state: "TN",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "049",
              name: "FENTRESS COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "051",
              name: "FRANKLIN COUNTY",
              state: "TN",
              'CBSA Number': "46100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "053",
              name: "GIBSON COUNTY",
              state: "TN",
              'CBSA Number': "27180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "055",
              name: "GILES COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "057",
              name: "GRAINGER COUNTY",
              state: "TN",
              'CBSA Number': "34100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "059",
              name: "GREENE COUNTY",
              state: "TN",
              'CBSA Number': "24620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "061",
              name: "GRUNDY COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "063",
              name: "HAMBLEN COUNTY",
              state: "TN",
              'CBSA Number': "34100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "065",
              name: "HAMILTON COUNTY",
              state: "TN",
              'CBSA Number': "16860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "067",
              name: "HANCOCK COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "069",
              name: "HARDEMAN COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "071",
              name: "HARDIN COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "073",
              name: "HAWKINS COUNTY",
              state: "TN",
              'CBSA Number': "28700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "075",
              name: "HAYWOOD COUNTY",
              state: "TN",
              'CBSA Number': "15140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "077",
              name: "HENDERSON COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "079",
              name: "HENRY COUNTY",
              state: "TN",
              'CBSA Number': "37540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "081",
              name: "HICKMAN COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "083",
              name: "HOUSTON COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "085",
              name: "HUMPHREYS COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "087",
              name: "JACKSON COUNTY",
              state: "TN",
              'CBSA Number': "18260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "089",
              name: "JEFFERSON COUNTY",
              state: "TN",
              'CBSA Number': "34100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "091",
              name: "JOHNSON COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "093",
              name: "KNOX COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "095",
              name: "LAKE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "097",
              name: "LAUDERDALE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "099",
              name: "LAWRENCE COUNTY",
              state: "TN",
              'CBSA Number': "29980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "101",
              name: "LEWIS COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "103",
              name: "LINCOLN COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "105",
              name: "LOUDON COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "107",
              name: "MCMINN COUNTY",
              state: "TN",
              'CBSA Number': "11940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "109",
              name: "MCNAIRY COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "111",
              name: "MACON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "113",
              name: "MADISON COUNTY",
              state: "TN",
              'CBSA Number': "27180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "115",
              name: "MARION COUNTY",
              state: "TN",
              'CBSA Number': "16860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "117",
              name: "MARSHALL COUNTY",
              state: "TN",
              'CBSA Number': "30280",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "119",
              name: "MAURY COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "121",
              name: "MEIGS COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "123",
              name: "MONROE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "125",
              name: "MONTGOMERY COUNTY",
              state: "TN",
              'CBSA Number': "17300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "127",
              name: "MOORE COUNTY",
              state: "TN",
              'CBSA Number': "46100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "129",
              name: "MORGAN COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "131",
              name: "OBION COUNTY",
              state: "TN",
              'CBSA Number': "46460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "133",
              name: "OVERTON COUNTY",
              state: "TN",
              'CBSA Number': "18260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "135",
              name: "PERRY COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "137",
              name: "PICKETT COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "139",
              name: "POLK COUNTY",
              state: "TN",
              'CBSA Number': "17420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "141",
              name: "PUTNAM COUNTY",
              state: "TN",
              'CBSA Number': "18260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "143",
              name: "RHEA COUNTY",
              state: "TN",
              'CBSA Number': "19420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "145",
              name: "ROANE COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "147",
              name: "ROBERTSON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "149",
              name: "RUTHERFORD COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "151",
              name: "SCOTT COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "153",
              name: "SEQUATCHIE COUNTY",
              state: "TN",
              'CBSA Number': "16860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "155",
              name: "SEVIER COUNTY",
              state: "TN",
              'CBSA Number': "42940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "157",
              name: "SHELBY COUNTY",
              state: "TN",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "159",
              name: "SMITH COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "161",
              name: "STEWART COUNTY",
              state: "TN",
              'CBSA Number': "17300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "163",
              name: "SULLIVAN COUNTY",
              state: "TN",
              'CBSA Number': "28700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "165",
              name: "SUMNER COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "167",
              name: "TIPTON COUNTY",
              state: "TN",
              'CBSA Number': "32820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "169",
              name: "TROUSDALE COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "171",
              name: "UNICOI COUNTY",
              state: "TN",
              'CBSA Number': "27740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "173",
              name: "UNION COUNTY",
              state: "TN",
              'CBSA Number': "28940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "175",
              name: "VAN BUREN COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "177",
              name: "WARREN COUNTY",
              state: "TN",
              'CBSA Number': "32660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "179",
              name: "WASHINGTON COUNTY",
              state: "TN",
              'CBSA Number': "27740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "181",
              name: "WAYNE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "183",
              name: "WEAKLEY COUNTY",
              state: "TN",
              'CBSA Number': "32280",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "185",
              name: "WHITE COUNTY",
              state: "TN",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "47",
              countyCode: "187",
              name: "WILLIAMSON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "47",
              countyCode: "189",
              name: "WILSON COUNTY",
              state: "TN",
              'CBSA Number': "34980",
              oneUnit: " $586,500 ",
              twoUnit: " $750,800 ",
              threeUnit: " $907,550 ",
              fourUnit: " $1,127,900 "
            },
            {
              stateCode: "48",
              countyCode: "001",
              name: "ANDERSON COUNTY",
              state: "TX",
              'CBSA Number': "37300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "003",
              name: "ANDREWS COUNTY",
              state: "TX",
              'CBSA Number': "11380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "005",
              name: "ANGELINA COUNTY",
              state: "TX",
              'CBSA Number': "31260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "007",
              name: "ARANSAS COUNTY",
              state: "TX",
              'CBSA Number': "40530",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "009",
              name: "ARCHER COUNTY",
              state: "TX",
              'CBSA Number': "48660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "011",
              name: "ARMSTRONG COUNTY",
              state: "TX",
              'CBSA Number': "11100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "013",
              name: "ATASCOSA COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "015",
              name: "AUSTIN COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "017",
              name: "BAILEY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "019",
              name: "BANDERA COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "021",
              name: "BASTROP COUNTY",
              state: "TX",
              'CBSA Number': "12420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "023",
              name: "BAYLOR COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "025",
              name: "BEE COUNTY",
              state: "TX",
              'CBSA Number': "13300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "027",
              name: "BELL COUNTY",
              state: "TX",
              'CBSA Number': "28660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "029",
              name: "BEXAR COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "031",
              name: "BLANCO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "033",
              name: "BORDEN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "035",
              name: "BOSQUE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "037",
              name: "BOWIE COUNTY",
              state: "TX",
              'CBSA Number': "45500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "039",
              name: "BRAZORIA COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "041",
              name: "BRAZOS COUNTY",
              state: "TX",
              'CBSA Number': "17780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "043",
              name: "BREWSTER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "045",
              name: "BRISCOE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "047",
              name: "BROOKS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "049",
              name: "BROWN COUNTY",
              state: "TX",
              'CBSA Number': "15220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "051",
              name: "BURLESON COUNTY",
              state: "TX",
              'CBSA Number': "17780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "053",
              name: "BURNET COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "055",
              name: "CALDWELL COUNTY",
              state: "TX",
              'CBSA Number': "12420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "057",
              name: "CALHOUN COUNTY",
              state: "TX",
              'CBSA Number': "38920",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "059",
              name: "CALLAHAN COUNTY",
              state: "TX",
              'CBSA Number': "10180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "061",
              name: "CAMERON COUNTY",
              state: "TX",
              'CBSA Number': "15180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "063",
              name: "CAMP COUNTY",
              state: "TX",
              'CBSA Number': "34420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "065",
              name: "CARSON COUNTY",
              state: "TX",
              'CBSA Number': "11100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "067",
              name: "CASS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "069",
              name: "CASTRO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "071",
              name: "CHAMBERS COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "073",
              name: "CHEROKEE COUNTY",
              state: "TX",
              'CBSA Number': "27380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "075",
              name: "CHILDRESS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "077",
              name: "CLAY COUNTY",
              state: "TX",
              'CBSA Number': "48660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "079",
              name: "COCHRAN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "081",
              name: "COKE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "083",
              name: "COLEMAN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "085",
              name: "COLLIN COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "087",
              name: "COLLINGSWORTH COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "089",
              name: "COLORADO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "091",
              name: "COMAL COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "093",
              name: "COMANCHE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "095",
              name: "CONCHO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "097",
              name: "COOKE COUNTY",
              state: "TX",
              'CBSA Number': "23620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "099",
              name: "CORYELL COUNTY",
              state: "TX",
              'CBSA Number': "28660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "101",
              name: "COTTLE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "103",
              name: "CRANE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "105",
              name: "CROCKETT COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "107",
              name: "CROSBY COUNTY",
              state: "TX",
              'CBSA Number': "31180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "109",
              name: "CULBERSON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "111",
              name: "DALLAM COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "113",
              name: "DALLAS COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "115",
              name: "DAWSON COUNTY",
              state: "TX",
              'CBSA Number': "29500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "117",
              name: "DEAF SMITH COUNTY",
              state: "TX",
              'CBSA Number': "25820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "119",
              name: "DELTA COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "121",
              name: "DENTON COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "123",
              name: "DEWITT COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "125",
              name: "DICKENS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "127",
              name: "DIMMIT COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "129",
              name: "DONLEY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "131",
              name: "DUVAL COUNTY",
              state: "TX",
              'CBSA Number': "10860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "133",
              name: "EASTLAND COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "135",
              name: "ECTOR COUNTY",
              state: "TX",
              'CBSA Number': "36220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "137",
              name: "EDWARDS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "139",
              name: "ELLIS COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "141",
              name: "EL PASO COUNTY",
              state: "TX",
              'CBSA Number': "21340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "143",
              name: "ERATH COUNTY",
              state: "TX",
              'CBSA Number': "44500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "145",
              name: "FALLS COUNTY",
              state: "TX",
              'CBSA Number': "47380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "147",
              name: "FANNIN COUNTY",
              state: "TX",
              'CBSA Number': "14300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "149",
              name: "FAYETTE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "151",
              name: "FISHER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "153",
              name: "FLOYD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "155",
              name: "FOARD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "157",
              name: "FORT BEND COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "159",
              name: "FRANKLIN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "161",
              name: "FREESTONE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "163",
              name: "FRIO COUNTY",
              state: "TX",
              'CBSA Number': "37770",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "165",
              name: "GAINES COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "167",
              name: "GALVESTON COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "169",
              name: "GARZA COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "171",
              name: "GILLESPIE COUNTY",
              state: "TX",
              'CBSA Number': "23240",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "173",
              name: "GLASSCOCK COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "175",
              name: "GOLIAD COUNTY",
              state: "TX",
              'CBSA Number': "47020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "177",
              name: "GONZALES COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "179",
              name: "GRAY COUNTY",
              state: "TX",
              'CBSA Number': "37420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "181",
              name: "GRAYSON COUNTY",
              state: "TX",
              'CBSA Number': "43300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "183",
              name: "GREGG COUNTY",
              state: "TX",
              'CBSA Number': "30980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "185",
              name: "GRIMES COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "187",
              name: "GUADALUPE COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "189",
              name: "HALE COUNTY",
              state: "TX",
              'CBSA Number': "38380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "191",
              name: "HALL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "193",
              name: "HAMILTON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "195",
              name: "HANSFORD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "197",
              name: "HARDEMAN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "199",
              name: "HARDIN COUNTY",
              state: "TX",
              'CBSA Number': "13140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "201",
              name: "HARRIS COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "203",
              name: "HARRISON COUNTY",
              state: "TX",
              'CBSA Number': "30980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "205",
              name: "HARTLEY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "207",
              name: "HASKELL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "209",
              name: "HAYS COUNTY",
              state: "TX",
              'CBSA Number': "12420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "211",
              name: "HEMPHILL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "213",
              name: "HENDERSON COUNTY",
              state: "TX",
              'CBSA Number': "11980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "215",
              name: "HIDALGO COUNTY",
              state: "TX",
              'CBSA Number': "32580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "217",
              name: "HILL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "219",
              name: "HOCKLEY COUNTY",
              state: "TX",
              'CBSA Number': "30220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "221",
              name: "HOOD COUNTY",
              state: "TX",
              'CBSA Number': "24180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "223",
              name: "HOPKINS COUNTY",
              state: "TX",
              'CBSA Number': "44860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "225",
              name: "HOUSTON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "227",
              name: "HOWARD COUNTY",
              state: "TX",
              'CBSA Number': "13700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "229",
              name: "HUDSPETH COUNTY",
              state: "TX",
              'CBSA Number': "21340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "231",
              name: "HUNT COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "233",
              name: "HUTCHINSON COUNTY",
              state: "TX",
              'CBSA Number': "14420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "235",
              name: "IRION COUNTY",
              state: "TX",
              'CBSA Number': "41660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "237",
              name: "JACK COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "239",
              name: "JACKSON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "241",
              name: "JASPER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "243",
              name: "JEFF DAVIS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "245",
              name: "JEFFERSON COUNTY",
              state: "TX",
              'CBSA Number': "13140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "247",
              name: "JIM HOGG COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "249",
              name: "JIM WELLS COUNTY",
              state: "TX",
              'CBSA Number': "10860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "251",
              name: "JOHNSON COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "253",
              name: "JONES COUNTY",
              state: "TX",
              'CBSA Number': "10180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "255",
              name: "KARNES COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "257",
              name: "KAUFMAN COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "259",
              name: "KENDALL COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "261",
              name: "KENEDY COUNTY",
              state: "TX",
              'CBSA Number': "28780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "263",
              name: "KENT COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "265",
              name: "KERR COUNTY",
              state: "TX",
              'CBSA Number': "28500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "267",
              name: "KIMBLE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "269",
              name: "KING COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "271",
              name: "KINNEY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "273",
              name: "KLEBERG COUNTY",
              state: "TX",
              'CBSA Number': "28780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "275",
              name: "KNOX COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "277",
              name: "LAMAR COUNTY",
              state: "TX",
              'CBSA Number': "37580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "279",
              name: "LAMB COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "281",
              name: "LAMPASAS COUNTY",
              state: "TX",
              'CBSA Number': "28660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "283",
              name: "LA SALLE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "285",
              name: "LAVACA COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "287",
              name: "LEE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "289",
              name: "LEON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "291",
              name: "LIBERTY COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "293",
              name: "LIMESTONE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "295",
              name: "LIPSCOMB COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "297",
              name: "LIVE OAK COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "299",
              name: "LLANO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "301",
              name: "LOVING COUNTY",
              state: "TX",
              'CBSA Number': "37780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "303",
              name: "LUBBOCK COUNTY",
              state: "TX",
              'CBSA Number': "31180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "305",
              name: "LYNN COUNTY",
              state: "TX",
              'CBSA Number': "31180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "307",
              name: "MCCULLOCH COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "309",
              name: "MCLENNAN COUNTY",
              state: "TX",
              'CBSA Number': "47380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "311",
              name: "MCMULLEN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "313",
              name: "MADISON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "315",
              name: "MARION COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "317",
              name: "MARTIN COUNTY",
              state: "TX",
              'CBSA Number': "33260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "319",
              name: "MASON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "321",
              name: "MATAGORDA COUNTY",
              state: "TX",
              'CBSA Number': "13060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "323",
              name: "MAVERICK COUNTY",
              state: "TX",
              'CBSA Number': "20580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "325",
              name: "MEDINA COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "327",
              name: "MENARD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "329",
              name: "MIDLAND COUNTY",
              state: "TX",
              'CBSA Number': "33260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "331",
              name: "MILAM COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "333",
              name: "MILLS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "335",
              name: "MITCHELL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "337",
              name: "MONTAGUE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "339",
              name: "MONTGOMERY COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "341",
              name: "MOORE COUNTY",
              state: "TX",
              'CBSA Number': "20300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "343",
              name: "MORRIS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "345",
              name: "MOTLEY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "347",
              name: "NACOGDOCHES COUNTY",
              state: "TX",
              'CBSA Number': "34860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "349",
              name: "NAVARRO COUNTY",
              state: "TX",
              'CBSA Number': "18620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "351",
              name: "NEWTON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "353",
              name: "NOLAN COUNTY",
              state: "TX",
              'CBSA Number': "45020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "355",
              name: "NUECES COUNTY",
              state: "TX",
              'CBSA Number': "18580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "357",
              name: "OCHILTREE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "359",
              name: "OLDHAM COUNTY",
              state: "TX",
              'CBSA Number': "11100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "361",
              name: "ORANGE COUNTY",
              state: "TX",
              'CBSA Number': "13140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "363",
              name: "PALO PINTO COUNTY",
              state: "TX",
              'CBSA Number': "33420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "365",
              name: "PANOLA COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "367",
              name: "PARKER COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "369",
              name: "PARMER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "371",
              name: "PECOS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "373",
              name: "POLK COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "375",
              name: "POTTER COUNTY",
              state: "TX",
              'CBSA Number': "11100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "377",
              name: "PRESIDIO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "379",
              name: "RAINS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "381",
              name: "RANDALL COUNTY",
              state: "TX",
              'CBSA Number': "11100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "383",
              name: "REAGAN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "385",
              name: "REAL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "387",
              name: "RED RIVER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "389",
              name: "REEVES COUNTY",
              state: "TX",
              'CBSA Number': "37780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "391",
              name: "REFUGIO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "393",
              name: "ROBERTS COUNTY",
              state: "TX",
              'CBSA Number': "37420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "395",
              name: "ROBERTSON COUNTY",
              state: "TX",
              'CBSA Number': "17780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "397",
              name: "ROCKWALL COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "399",
              name: "RUNNELS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "401",
              name: "RUSK COUNTY",
              state: "TX",
              'CBSA Number': "30980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "403",
              name: "SABINE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "405",
              name: "SAN AUGUSTINE COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "407",
              name: "SAN JACINTO COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "409",
              name: "SAN PATRICIO COUNTY",
              state: "TX",
              'CBSA Number': "18580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "411",
              name: "SAN SABA COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "413",
              name: "SCHLEICHER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "415",
              name: "SCURRY COUNTY",
              state: "TX",
              'CBSA Number': "43660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "417",
              name: "SHACKELFORD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "419",
              name: "SHELBY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "421",
              name: "SHERMAN COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "423",
              name: "SMITH COUNTY",
              state: "TX",
              'CBSA Number': "46340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "425",
              name: "SOMERVELL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "427",
              name: "STARR COUNTY",
              state: "TX",
              'CBSA Number': "40100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "429",
              name: "STEPHENS COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "431",
              name: "STERLING COUNTY",
              state: "TX",
              'CBSA Number': "41660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "433",
              name: "STONEWALL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "435",
              name: "SUTTON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "437",
              name: "SWISHER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "439",
              name: "TARRANT COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "441",
              name: "TAYLOR COUNTY",
              state: "TX",
              'CBSA Number': "10180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "443",
              name: "TERRELL COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "445",
              name: "TERRY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "447",
              name: "THROCKMORTON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "449",
              name: "TITUS COUNTY",
              state: "TX",
              'CBSA Number': "34420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "451",
              name: "TOM GREEN COUNTY",
              state: "TX",
              'CBSA Number': "41660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "453",
              name: "TRAVIS COUNTY",
              state: "TX",
              'CBSA Number': "12420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "455",
              name: "TRINITY COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "457",
              name: "TYLER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "459",
              name: "UPSHUR COUNTY",
              state: "TX",
              'CBSA Number': "30980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "461",
              name: "UPTON COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "463",
              name: "UVALDE COUNTY",
              state: "TX",
              'CBSA Number': "46620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "465",
              name: "VAL VERDE COUNTY",
              state: "TX",
              'CBSA Number': "19620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "467",
              name: "VAN ZANDT COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "469",
              name: "VICTORIA COUNTY",
              state: "TX",
              'CBSA Number': "47020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "471",
              name: "WALKER COUNTY",
              state: "TX",
              'CBSA Number': "26660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "473",
              name: "WALLER COUNTY",
              state: "TX",
              'CBSA Number': "26420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "475",
              name: "WARD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "477",
              name: "WASHINGTON COUNTY",
              state: "TX",
              'CBSA Number': "14780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "479",
              name: "WEBB COUNTY",
              state: "TX",
              'CBSA Number': "29700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "481",
              name: "WHARTON COUNTY",
              state: "TX",
              'CBSA Number': "20900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "483",
              name: "WHEELER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "485",
              name: "WICHITA COUNTY",
              state: "TX",
              'CBSA Number': "48660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "487",
              name: "WILBARGER COUNTY",
              state: "TX",
              'CBSA Number': "46900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "489",
              name: "WILLACY COUNTY",
              state: "TX",
              'CBSA Number': "39700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "491",
              name: "WILLIAMSON COUNTY",
              state: "TX",
              'CBSA Number': "12420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "493",
              name: "WILSON COUNTY",
              state: "TX",
              'CBSA Number': "41700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "495",
              name: "WINKLER COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "497",
              name: "WISE COUNTY",
              state: "TX",
              'CBSA Number': "19100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "499",
              name: "WOOD COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "501",
              name: "YOAKUM COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "503",
              name: "YOUNG COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "505",
              name: "ZAPATA COUNTY",
              state: "TX",
              'CBSA Number': "49820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "48",
              countyCode: "507",
              name: "ZAVALA COUNTY",
              state: "TX",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "001",
              name: "BEAVER COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "003",
              name: "BOX ELDER COUNTY",
              state: "UT",
              'CBSA Number': "36260",
              oneUnit: " $646,300 ",
              twoUnit: " $827,400 ",
              threeUnit: " $1,000,100 ",
              fourUnit: " $1,242,900 "
            },
            {
              stateCode: "49",
              countyCode: "005",
              name: "CACHE COUNTY",
              state: "UT",
              'CBSA Number': "30860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "007",
              name: "CARBON COUNTY",
              state: "UT",
              'CBSA Number': "39220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "009",
              name: "DAGGETT COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "011",
              name: "DAVIS COUNTY",
              state: "UT",
              'CBSA Number': "36260",
              oneUnit: " $646,300 ",
              twoUnit: " $827,400 ",
              threeUnit: " $1,000,100 ",
              fourUnit: " $1,242,900 "
            },
            {
              stateCode: "49",
              countyCode: "013",
              name: "DUCHESNE COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "015",
              name: "EMERY COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "017",
              name: "GARFIELD COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "019",
              name: "GRAND COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "021",
              name: "IRON COUNTY",
              state: "UT",
              'CBSA Number': "16260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "023",
              name: "JUAB COUNTY",
              state: "UT",
              'CBSA Number': "39340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "025",
              name: "KANE COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "027",
              name: "MILLARD COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "029",
              name: "MORGAN COUNTY",
              state: "UT",
              'CBSA Number': "36260",
              oneUnit: " $646,300 ",
              twoUnit: " $827,400 ",
              threeUnit: " $1,000,100 ",
              fourUnit: " $1,242,900 "
            },
            {
              stateCode: "49",
              countyCode: "031",
              name: "PIUTE COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "033",
              name: "RICH COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "035",
              name: "SALT LAKE COUNTY",
              state: "UT",
              'CBSA Number': "41620",
              oneUnit: " $600,300 ",
              twoUnit: " $768,500 ",
              threeUnit: " $928,950 ",
              fourUnit: " $1,154,450 "
            },
            {
              stateCode: "49",
              countyCode: "037",
              name: "SAN JUAN COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "039",
              name: "SANPETE COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "041",
              name: "SEVIER COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "043",
              name: "SUMMIT COUNTY",
              state: "UT",
              'CBSA Number': "25720",
              oneUnit: " $817,650 ",
              twoUnit: " $1,046,750 ",
              threeUnit: " $1,265,250 ",
              fourUnit: " $1,572,450 "
            },
            {
              stateCode: "49",
              countyCode: "045",
              name: "TOOELE COUNTY",
              state: "UT",
              'CBSA Number': "41620",
              oneUnit: " $600,300 ",
              twoUnit: " $768,500 ",
              threeUnit: " $928,950 ",
              fourUnit: " $1,154,450 "
            },
            {
              stateCode: "49",
              countyCode: "047",
              name: "UINTAH COUNTY",
              state: "UT",
              'CBSA Number': "46860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "049",
              name: "UTAH COUNTY",
              state: "UT",
              'CBSA Number': "39340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "051",
              name: "WASATCH COUNTY",
              state: "UT",
              'CBSA Number': "25720",
              oneUnit: " $817,650 ",
              twoUnit: " $1,046,750 ",
              threeUnit: " $1,265,250 ",
              fourUnit: " $1,572,450 "
            },
            {
              stateCode: "49",
              countyCode: "053",
              name: "WASHINGTON COUNTY",
              state: "UT",
              'CBSA Number': "41100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "055",
              name: "WAYNE COUNTY",
              state: "UT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "49",
              countyCode: "057",
              name: "WEBER COUNTY",
              state: "UT",
              'CBSA Number': "36260",
              oneUnit: " $646,300 ",
              twoUnit: " $827,400 ",
              threeUnit: " $1,000,100 ",
              fourUnit: " $1,242,900 "
            },
            {
              stateCode: "50",
              countyCode: "001",
              name: "ADDISON COUNTY",
              state: "VT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "003",
              name: "BENNINGTON COUNTY",
              state: "VT",
              'CBSA Number': "13540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "005",
              name: "CALEDONIA COUNTY",
              state: "VT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "007",
              name: "CHITTENDEN COUNTY",
              state: "VT",
              'CBSA Number': "15540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "009",
              name: "ESSEX COUNTY",
              state: "VT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "011",
              name: "FRANKLIN COUNTY",
              state: "VT",
              'CBSA Number': "15540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "013",
              name: "GRAND ISLE COUNTY",
              state: "VT",
              'CBSA Number': "15540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "015",
              name: "LAMOILLE COUNTY",
              state: "VT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "017",
              name: "ORANGE COUNTY",
              state: "VT",
              'CBSA Number': "30100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "019",
              name: "ORLEANS COUNTY",
              state: "VT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "021",
              name: "RUTLAND COUNTY",
              state: "VT",
              'CBSA Number': "40860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "023",
              name: "WASHINGTON COUNTY",
              state: "VT",
              'CBSA Number': "12740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "025",
              name: "WINDHAM COUNTY",
              state: "VT",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "50",
              countyCode: "027",
              name: "WINDSOR COUNTY",
              state: "VT",
              'CBSA Number': "30100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "001",
              name: "ACCOMACK COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "003",
              name: "ALBEMARLE COUNTY",
              state: "VA",
              'CBSA Number': "16820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "005",
              name: "ALLEGHANY COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "007",
              name: "AMELIA COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "009",
              name: "AMHERST COUNTY",
              state: "VA",
              'CBSA Number': "31340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "011",
              name: "APPOMATTOX COUNTY",
              state: "VA",
              'CBSA Number': "31340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "013",
              name: "ARLINGTON COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "015",
              name: "AUGUSTA COUNTY",
              state: "VA",
              'CBSA Number': "44420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "017",
              name: "BATH COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "019",
              name: "BEDFORD COUNTY",
              state: "VA",
              'CBSA Number': "31340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "021",
              name: "BLAND COUNTY",
              state: "VA",
              'CBSA Number': "14140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "023",
              name: "BOTETOURT COUNTY",
              state: "VA",
              'CBSA Number': "40220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "025",
              name: "BRUNSWICK COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "027",
              name: "BUCHANAN COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "029",
              name: "BUCKINGHAM COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "031",
              name: "CAMPBELL COUNTY",
              state: "VA",
              'CBSA Number': "31340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "033",
              name: "CAROLINE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "035",
              name: "CARROLL COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "036",
              name: "CHARLES CITY COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "037",
              name: "CHARLOTTE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "041",
              name: "CHESTERFIELD COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "043",
              name: "CLARKE COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "045",
              name: "CRAIG COUNTY",
              state: "VA",
              'CBSA Number': "40220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "047",
              name: "CULPEPER COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "049",
              name: "CUMBERLAND COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "051",
              name: "DICKENSON COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "053",
              name: "DINWIDDIE COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "057",
              name: "ESSEX COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "059",
              name: "FAIRFAX COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "061",
              name: "FAUQUIER COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "063",
              name: "FLOYD COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "065",
              name: "FLUVANNA COUNTY",
              state: "VA",
              'CBSA Number': "16820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "067",
              name: "FRANKLIN COUNTY",
              state: "VA",
              'CBSA Number': "40220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "069",
              name: "FREDERICK COUNTY",
              state: "VA",
              'CBSA Number': "49020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "071",
              name: "GILES COUNTY",
              state: "VA",
              'CBSA Number': "13980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "073",
              name: "GLOUCESTER COUNTY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "075",
              name: "GOOCHLAND COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "077",
              name: "GRAYSON COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "079",
              name: "GREENE COUNTY",
              state: "VA",
              'CBSA Number': "16820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "081",
              name: "GREENSVILLE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "083",
              name: "HALIFAX COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "085",
              name: "HANOVER COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "087",
              name: "HENRICO COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "089",
              name: "HENRY COUNTY",
              state: "VA",
              'CBSA Number': "32300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "091",
              name: "HIGHLAND COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "093",
              name: "ISLE OF WIGHT COUNTY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "095",
              name: "JAMES CITY COUNTY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "097",
              name: "KING AND QUEEN COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "099",
              name: "KING GEORGE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "101",
              name: "KING WILLIAM COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "103",
              name: "LANCASTER COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "105",
              name: "LEE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "107",
              name: "LOUDOUN COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "109",
              name: "LOUISA COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "111",
              name: "LUNENBURG COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "113",
              name: "MADISON COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "115",
              name: "MATHEWS COUNTY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "117",
              name: "MECKLENBURG COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "119",
              name: "MIDDLESEX COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "121",
              name: "MONTGOMERY COUNTY",
              state: "VA",
              'CBSA Number': "13980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "125",
              name: "NELSON COUNTY",
              state: "VA",
              'CBSA Number': "16820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "127",
              name: "NEW KENT COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "131",
              name: "NORTHAMPTON COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "133",
              name: "NORTHUMBERLAND COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "135",
              name: "NOTTOWAY COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "137",
              name: "ORANGE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "139",
              name: "PAGE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "141",
              name: "PATRICK COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "143",
              name: "PITTSYLVANIA COUNTY",
              state: "VA",
              'CBSA Number': "19260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "145",
              name: "POWHATAN COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "147",
              name: "PRINCE EDWARD COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "149",
              name: "PRINCE GEORGE COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "153",
              name: "PRINCE WILLIAM COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "155",
              name: "PULASKI COUNTY",
              state: "VA",
              'CBSA Number': "13980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "157",
              name: "RAPPAHANNOCK COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "159",
              name: "RICHMOND COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "161",
              name: "ROANOKE COUNTY",
              state: "VA",
              'CBSA Number': "40220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "163",
              name: "ROCKBRIDGE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "165",
              name: "ROCKINGHAM COUNTY",
              state: "VA",
              'CBSA Number': "25500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "167",
              name: "RUSSELL COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "169",
              name: "SCOTT COUNTY",
              state: "VA",
              'CBSA Number': "28700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "171",
              name: "SHENANDOAH COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "173",
              name: "SMYTH COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "175",
              name: "SOUTHAMPTON COUNTY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "177",
              name: "SPOTSYLVANIA COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "179",
              name: "STAFFORD COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "181",
              name: "SURRY COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "183",
              name: "SUSSEX COUNTY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "185",
              name: "TAZEWELL COUNTY",
              state: "VA",
              'CBSA Number': "14140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "187",
              name: "WARREN COUNTY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "191",
              name: "WASHINGTON COUNTY",
              state: "VA",
              'CBSA Number': "28700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "193",
              name: "WESTMORELAND COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "195",
              name: "WISE COUNTY",
              state: "VA",
              'CBSA Number': "13720",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "197",
              name: "WYTHE COUNTY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "199",
              name: "YORK COUNTY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "510",
              name: "ALEXANDRIA CITY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "520",
              name: "BRISTOL CITY",
              state: "VA",
              'CBSA Number': "28700",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "530",
              name: "BUENA VISTA CITY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "540",
              name: "CHARLOTTESVILLE CITY",
              state: "VA",
              'CBSA Number': "16820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "550",
              name: "CHESAPEAKE CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "570",
              name: "COLONIAL HEIGHTS CITY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "580",
              name: "COVINGTON CITY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "590",
              name: "DANVILLE CITY",
              state: "VA",
              'CBSA Number': "19260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "595",
              name: "EMPORIA CITY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "600",
              name: "FAIRFAX CITY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "610",
              name: "FALLS CHURCH CITY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "620",
              name: "FRANKLIN CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "630",
              name: "FREDERICKSBURG CITY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "640",
              name: "GALAX CITY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "650",
              name: "HAMPTON CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "660",
              name: "HARRISONBURG CITY",
              state: "VA",
              'CBSA Number': "25500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "670",
              name: "HOPEWELL CITY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "678",
              name: "LEXINGTON CITY",
              state: "VA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "680",
              name: "LYNCHBURG CITY",
              state: "VA",
              'CBSA Number': "31340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "683",
              name: "MANASSAS CITY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "685",
              name: "MANASSAS PARK CITY",
              state: "VA",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "51",
              countyCode: "690",
              name: "MARTINSVILLE CITY",
              state: "VA",
              'CBSA Number': "32300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "700",
              name: "NEWPORT NEWS CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "710",
              name: "NORFOLK CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "720",
              name: "NORTON CITY",
              state: "VA",
              'CBSA Number': "13720",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "730",
              name: "PETERSBURG CITY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "735",
              name: "POQUOSON CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "740",
              name: "PORTSMOUTH CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "750",
              name: "RADFORD CITY",
              state: "VA",
              'CBSA Number': "13980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "760",
              name: "RICHMOND CITY",
              state: "VA",
              'CBSA Number': "40060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "770",
              name: "ROANOKE CITY",
              state: "VA",
              'CBSA Number': "40220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "775",
              name: "SALEM CITY",
              state: "VA",
              'CBSA Number': "40220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "790",
              name: "STAUNTON CITY",
              state: "VA",
              'CBSA Number': "44420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "800",
              name: "SUFFOLK CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "810",
              name: "VIRGINIA BEACH CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "820",
              name: "WAYNESBORO CITY",
              state: "VA",
              'CBSA Number': "44420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "830",
              name: "WILLIAMSBURG CITY",
              state: "VA",
              'CBSA Number': "47260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "51",
              countyCode: "840",
              name: "WINCHESTER CITY",
              state: "VA",
              'CBSA Number': "49020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "WA",
              'CBSA Number': "36830",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "003",
              name: "ASOTIN COUNTY",
              state: "WA",
              'CBSA Number': "30300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "005",
              name: "BENTON COUNTY",
              state: "WA",
              'CBSA Number': "28420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "007",
              name: "CHELAN COUNTY",
              state: "WA",
              'CBSA Number': "48300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "009",
              name: "CLALLAM COUNTY",
              state: "WA",
              'CBSA Number': "38820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "011",
              name: "CLARK COUNTY",
              state: "WA",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "013",
              name: "COLUMBIA COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "015",
              name: "COWLITZ COUNTY",
              state: "WA",
              'CBSA Number': "31020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "017",
              name: "DOUGLAS COUNTY",
              state: "WA",
              'CBSA Number': "48300",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "019",
              name: "FERRY COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "021",
              name: "FRANKLIN COUNTY",
              state: "WA",
              'CBSA Number': "28420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "023",
              name: "GARFIELD COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "025",
              name: "GRANT COUNTY",
              state: "WA",
              'CBSA Number': "34180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "027",
              name: "GRAYS HARBOR COUNTY",
              state: "WA",
              'CBSA Number': "10140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "029",
              name: "ISLAND COUNTY",
              state: "WA",
              'CBSA Number': "36020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "031",
              name: "JEFFERSON COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "033",
              name: "KING COUNTY",
              state: "WA",
              'CBSA Number': "42660",
              oneUnit: " $776,250 ",
              twoUnit: " $993,750 ",
              threeUnit: " $1,201,200 ",
              fourUnit: " $1,492,800 "
            },
            {
              stateCode: "53",
              countyCode: "035",
              name: "KITSAP COUNTY",
              state: "WA",
              'CBSA Number': "14740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "037",
              name: "KITTITAS COUNTY",
              state: "WA",
              'CBSA Number': "21260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "039",
              name: "KLICKITAT COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "041",
              name: "LEWIS COUNTY",
              state: "WA",
              'CBSA Number': "16500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "043",
              name: "LINCOLN COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "045",
              name: "MASON COUNTY",
              state: "WA",
              'CBSA Number': "43220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "047",
              name: "OKANOGAN COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "049",
              name: "PACIFIC COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "051",
              name: "PEND OREILLE COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "053",
              name: "PIERCE COUNTY",
              state: "WA",
              'CBSA Number': "42660",
              oneUnit: " $776,250 ",
              twoUnit: " $993,750 ",
              threeUnit: " $1,201,200 ",
              fourUnit: " $1,492,800 "
            },
            {
              stateCode: "53",
              countyCode: "055",
              name: "SAN JUAN COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "057",
              name: "SKAGIT COUNTY",
              state: "WA",
              'CBSA Number': "34580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "059",
              name: "SKAMANIA COUNTY",
              state: "WA",
              'CBSA Number': "38900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "061",
              name: "SNOHOMISH COUNTY",
              state: "WA",
              'CBSA Number': "42660",
              oneUnit: " $776,250 ",
              twoUnit: " $993,750 ",
              threeUnit: " $1,201,200 ",
              fourUnit: " $1,492,800 "
            },
            {
              stateCode: "53",
              countyCode: "063",
              name: "SPOKANE COUNTY",
              state: "WA",
              'CBSA Number': "44060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "065",
              name: "STEVENS COUNTY",
              state: "WA",
              'CBSA Number': "44060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "067",
              name: "THURSTON COUNTY",
              state: "WA",
              'CBSA Number': "36500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "069",
              name: "WAHKIAKUM COUNTY",
              state: "WA",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "071",
              name: "WALLA WALLA COUNTY",
              state: "WA",
              'CBSA Number': "47460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "073",
              name: "WHATCOM COUNTY",
              state: "WA",
              'CBSA Number': "13380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "075",
              name: "WHITMAN COUNTY",
              state: "WA",
              'CBSA Number': "39420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "53",
              countyCode: "077",
              name: "YAKIMA COUNTY",
              state: "WA",
              'CBSA Number': "49420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "001",
              name: "BARBOUR COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "003",
              name: "BERKELEY COUNTY",
              state: "WV",
              'CBSA Number': "25180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "005",
              name: "BOONE COUNTY",
              state: "WV",
              'CBSA Number': "16620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "007",
              name: "BRAXTON COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "009",
              name: "BROOKE COUNTY",
              state: "WV",
              'CBSA Number': "48260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "011",
              name: "CABELL COUNTY",
              state: "WV",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "013",
              name: "CALHOUN COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "015",
              name: "CLAY COUNTY",
              state: "WV",
              'CBSA Number': "16620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "017",
              name: "DODDRIDGE COUNTY",
              state: "WV",
              'CBSA Number': "17220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "019",
              name: "FAYETTE COUNTY",
              state: "WV",
              'CBSA Number': "13220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "021",
              name: "GILMER COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "023",
              name: "GRANT COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "025",
              name: "GREENBRIER COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "027",
              name: "HAMPSHIRE COUNTY",
              state: "WV",
              'CBSA Number': "49020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "029",
              name: "HANCOCK COUNTY",
              state: "WV",
              'CBSA Number': "48260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "031",
              name: "HARDY COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "033",
              name: "HARRISON COUNTY",
              state: "WV",
              'CBSA Number': "17220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "035",
              name: "JACKSON COUNTY",
              state: "WV",
              'CBSA Number': "16620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "037",
              name: "JEFFERSON COUNTY",
              state: "WV",
              'CBSA Number': "47900",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "54",
              countyCode: "039",
              name: "KANAWHA COUNTY",
              state: "WV",
              'CBSA Number': "16620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "041",
              name: "LEWIS COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "043",
              name: "LINCOLN COUNTY",
              state: "WV",
              'CBSA Number': "16620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "045",
              name: "LOGAN COUNTY",
              state: "WV",
              'CBSA Number': "34350",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "047",
              name: "MCDOWELL COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "049",
              name: "MARION COUNTY",
              state: "WV",
              'CBSA Number': "21900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "051",
              name: "MARSHALL COUNTY",
              state: "WV",
              'CBSA Number': "48540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "053",
              name: "MASON COUNTY",
              state: "WV",
              'CBSA Number': "38580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "055",
              name: "MERCER COUNTY",
              state: "WV",
              'CBSA Number': "14140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "057",
              name: "MINERAL COUNTY",
              state: "WV",
              'CBSA Number': "19060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "059",
              name: "MINGO COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "061",
              name: "MONONGALIA COUNTY",
              state: "WV",
              'CBSA Number': "34060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "063",
              name: "MONROE COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "065",
              name: "MORGAN COUNTY",
              state: "WV",
              'CBSA Number': "25180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "067",
              name: "NICHOLAS COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "069",
              name: "OHIO COUNTY",
              state: "WV",
              'CBSA Number': "48540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "071",
              name: "PENDLETON COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "073",
              name: "PLEASANTS COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "075",
              name: "POCAHONTAS COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "077",
              name: "PRESTON COUNTY",
              state: "WV",
              'CBSA Number': "34060",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "079",
              name: "PUTNAM COUNTY",
              state: "WV",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "081",
              name: "RALEIGH COUNTY",
              state: "WV",
              'CBSA Number': "13220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "083",
              name: "RANDOLPH COUNTY",
              state: "WV",
              'CBSA Number': "21180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "085",
              name: "RITCHIE COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "087",
              name: "ROANE COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "089",
              name: "SUMMERS COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "091",
              name: "TAYLOR COUNTY",
              state: "WV",
              'CBSA Number': "17220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "093",
              name: "TUCKER COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "095",
              name: "TYLER COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "097",
              name: "UPSHUR COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "099",
              name: "WAYNE COUNTY",
              state: "WV",
              'CBSA Number': "26580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "101",
              name: "WEBSTER COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "103",
              name: "WETZEL COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "105",
              name: "WIRT COUNTY",
              state: "WV",
              'CBSA Number': "37620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "107",
              name: "WOOD COUNTY",
              state: "WV",
              'CBSA Number': "37620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "54",
              countyCode: "109",
              name: "WYOMING COUNTY",
              state: "WV",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "001",
              name: "ADAMS COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "003",
              name: "ASHLAND COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "005",
              name: "BARRON COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "007",
              name: "BAYFIELD COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "009",
              name: "BROWN COUNTY",
              state: "WI",
              'CBSA Number': "24580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "011",
              name: "BUFFALO COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "013",
              name: "BURNETT COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "015",
              name: "CALUMET COUNTY",
              state: "WI",
              'CBSA Number': "11540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "017",
              name: "CHIPPEWA COUNTY",
              state: "WI",
              'CBSA Number': "20740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "019",
              name: "CLARK COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "021",
              name: "COLUMBIA COUNTY",
              state: "WI",
              'CBSA Number': "31540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "023",
              name: "CRAWFORD COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "025",
              name: "DANE COUNTY",
              state: "WI",
              'CBSA Number': "31540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "027",
              name: "DODGE COUNTY",
              state: "WI",
              'CBSA Number': "13180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "029",
              name: "DOOR COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "031",
              name: "DOUGLAS COUNTY",
              state: "WI",
              'CBSA Number': "20260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "033",
              name: "DUNN COUNTY",
              state: "WI",
              'CBSA Number': "32860",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "035",
              name: "EAU CLAIRE COUNTY",
              state: "WI",
              'CBSA Number': "20740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "037",
              name: "FLORENCE COUNTY",
              state: "WI",
              'CBSA Number': "27020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "039",
              name: "FOND DU LAC COUNTY",
              state: "WI",
              'CBSA Number': "22540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "041",
              name: "FOREST COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "043",
              name: "GRANT COUNTY",
              state: "WI",
              'CBSA Number': "38420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "045",
              name: "GREEN COUNTY",
              state: "WI",
              'CBSA Number': "31540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "047",
              name: "GREEN LAKE COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "049",
              name: "IOWA COUNTY",
              state: "WI",
              'CBSA Number': "31540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "051",
              name: "IRON COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "053",
              name: "JACKSON COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "055",
              name: "JEFFERSON COUNTY",
              state: "WI",
              'CBSA Number': "48020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "057",
              name: "JUNEAU COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "059",
              name: "KENOSHA COUNTY",
              state: "WI",
              'CBSA Number': "16980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "061",
              name: "KEWAUNEE COUNTY",
              state: "WI",
              'CBSA Number': "24580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "063",
              name: "LA CROSSE COUNTY",
              state: "WI",
              'CBSA Number': "29100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "065",
              name: "LAFAYETTE COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "067",
              name: "LANGLADE COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "069",
              name: "LINCOLN COUNTY",
              state: "WI",
              'CBSA Number': "48140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "071",
              name: "MANITOWOC COUNTY",
              state: "WI",
              'CBSA Number': "31820",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "073",
              name: "MARATHON COUNTY",
              state: "WI",
              'CBSA Number': "48140",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "075",
              name: "MARINETTE COUNTY",
              state: "WI",
              'CBSA Number': "31940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "077",
              name: "MARQUETTE COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "078",
              name: "MENOMINEE COUNTY",
              state: "WI",
              'CBSA Number': "43020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "079",
              name: "MILWAUKEE COUNTY",
              state: "WI",
              'CBSA Number': "33340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "081",
              name: "MONROE COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "083",
              name: "OCONTO COUNTY",
              state: "WI",
              'CBSA Number': "24580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "085",
              name: "ONEIDA COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "087",
              name: "OUTAGAMIE COUNTY",
              state: "WI",
              'CBSA Number': "11540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "089",
              name: "OZAUKEE COUNTY",
              state: "WI",
              'CBSA Number': "33340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "091",
              name: "PEPIN COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "093",
              name: "PIERCE COUNTY",
              state: "WI",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "095",
              name: "POLK COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "097",
              name: "PORTAGE COUNTY",
              state: "WI",
              'CBSA Number': "44620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "099",
              name: "PRICE COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "101",
              name: "RACINE COUNTY",
              state: "WI",
              'CBSA Number': "39540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "103",
              name: "RICHLAND COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "105",
              name: "ROCK COUNTY",
              state: "WI",
              'CBSA Number': "27500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "107",
              name: "RUSK COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "109",
              name: "ST. CROIX COUNTY",
              state: "WI",
              'CBSA Number': "33460",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "111",
              name: "SAUK COUNTY",
              state: "WI",
              'CBSA Number': "12660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "113",
              name: "SAWYER COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "115",
              name: "SHAWANO COUNTY",
              state: "WI",
              'CBSA Number': "43020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "117",
              name: "SHEBOYGAN COUNTY",
              state: "WI",
              'CBSA Number': "43100",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "119",
              name: "TAYLOR COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "121",
              name: "TREMPEALEAU COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "123",
              name: "VERNON COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "125",
              name: "VILAS COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "127",
              name: "WALWORTH COUNTY",
              state: "WI",
              'CBSA Number': "48580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "129",
              name: "WASHBURN COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "131",
              name: "WASHINGTON COUNTY",
              state: "WI",
              'CBSA Number': "33340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "133",
              name: "WAUKESHA COUNTY",
              state: "WI",
              'CBSA Number': "33340",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "135",
              name: "WAUPACA COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "137",
              name: "WAUSHARA COUNTY",
              state: "WI",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "139",
              name: "WINNEBAGO COUNTY",
              state: "WI",
              'CBSA Number': "36780",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "55",
              countyCode: "141",
              name: "WOOD COUNTY",
              state: "WI",
              'CBSA Number': "49220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "001",
              name: "ALBANY COUNTY",
              state: "WY",
              'CBSA Number': "29660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "003",
              name: "BIG HORN COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "005",
              name: "CAMPBELL COUNTY",
              state: "WY",
              'CBSA Number': "23940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "007",
              name: "CARBON COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "009",
              name: "CONVERSE COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "011",
              name: "CROOK COUNTY",
              state: "WY",
              'CBSA Number': "23940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "013",
              name: "FREMONT COUNTY",
              state: "WY",
              'CBSA Number': "40180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "015",
              name: "GOSHEN COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "017",
              name: "HOT SPRINGS COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "019",
              name: "JOHNSON COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "021",
              name: "LARAMIE COUNTY",
              state: "WY",
              'CBSA Number': "16940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "023",
              name: "LINCOLN COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "025",
              name: "NATRONA COUNTY",
              state: "WY",
              'CBSA Number': "16220",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "027",
              name: "NIOBRARA COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "029",
              name: "PARK COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "031",
              name: "PLATTE COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "033",
              name: "SHERIDAN COUNTY",
              state: "WY",
              'CBSA Number': "43260",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "035",
              name: "SUBLETTE COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "037",
              name: "SWEETWATER COUNTY",
              state: "WY",
              'CBSA Number': "40540",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "039",
              name: "TETON COUNTY",
              state: "WY",
              'CBSA Number': "27220",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "56",
              countyCode: "041",
              name: "UINTA COUNTY",
              state: "WY",
              'CBSA Number': "21740",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "043",
              name: "WASHAKIE COUNTY",
              state: "WY",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "56",
              countyCode: "045",
              name: "WESTON COUNTY",
              state: "WY",
              'CBSA Number': "23940",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "60",
              countyCode: "010",
              name: "EASTERN DISTRICT",
              state: "AS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "60",
              countyCode: "020",
              name: "MANU'A DISTRICT",
              state: "AS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "60",
              countyCode: "030",
              name: "ROSE ISLAND",
              state: "AS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "60",
              countyCode: "040",
              name: "SWAINS ISLAND",
              state: "AS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "60",
              countyCode: "050",
              name: "WESTERN DISTRICT",
              state: "AS",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "66",
              countyCode: "010",
              name: "GUAM",
              state: "GU",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "69",
              countyCode: "085",
              name: "NORTHERN ISLANDS MUNICIPALITY",
              state: "MP",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "69",
              countyCode: "100",
              name: "ROTA MUNICIPALITY",
              state: "MP",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "69",
              countyCode: "110",
              name: "SAIPAN MUNICIPALITY",
              state: "MP",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "69",
              countyCode: "120",
              name: "TINIAN MUNICIPALITY",
              state: "MP",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "001",
              name: "ADJUNTAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "38660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "003",
              name: "AGUADA MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "005",
              name: "AGUADILLA MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "007",
              name: "AGUAS BUENAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "009",
              name: "AIBONITO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "011",
              name: "ANASCO MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "013",
              name: "ARECIBO MUNICIPIO",
              state: "PR",
              'CBSA Number': "11640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "015",
              name: "ARROYO MUNICIPIO",
              state: "PR",
              'CBSA Number': "25020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "017",
              name: "BARCELONETA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "019",
              name: "BARRANQUITAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "021",
              name: "BAYAMON MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "023",
              name: "CABO ROJO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "025",
              name: "CAGUAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "027",
              name: "CAMUY MUNICIPIO",
              state: "PR",
              'CBSA Number': "11640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "029",
              name: "CANOVANAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "031",
              name: "CAROLINA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "033",
              name: "CATANO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "035",
              name: "CAYEY MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "037",
              name: "CEIBA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "039",
              name: "CIALES MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "041",
              name: "CIDRA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "043",
              name: "COAMO MUNICIPIO",
              state: "PR",
              'CBSA Number': "17620",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "045",
              name: "COMERIO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "047",
              name: "COROZAL MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "049",
              name: "CULEBRA MUNICIPIO",
              state: "PR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "051",
              name: "DORADO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "053",
              name: "FAJARDO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "054",
              name: "FLORIDA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "055",
              name: "GUANICA MUNICIPIO",
              state: "PR",
              'CBSA Number': "49500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "057",
              name: "GUAYAMA MUNICIPIO",
              state: "PR",
              'CBSA Number': "25020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "059",
              name: "GUAYANILLA MUNICIPIO",
              state: "PR",
              'CBSA Number': "49500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "061",
              name: "GUAYNABO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "063",
              name: "GURABO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "065",
              name: "HATILLO MUNICIPIO",
              state: "PR",
              'CBSA Number': "11640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "067",
              name: "HORMIGUEROS MUNICIPIO",
              state: "PR",
              'CBSA Number': "32420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "069",
              name: "HUMACAO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "071",
              name: "ISABELA MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "073",
              name: "JAYUYA MUNICIPIO",
              state: "PR",
              'CBSA Number': "27580",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "075",
              name: "JUANA DIAZ MUNICIPIO",
              state: "PR",
              'CBSA Number': "38660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "077",
              name: "JUNCOS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "079",
              name: "LAJAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "081",
              name: "LARES MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "083",
              name: "LAS MARIAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "32420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "085",
              name: "LAS PIEDRAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "087",
              name: "LOIZA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "089",
              name: "LUQUILLO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "091",
              name: "MANATI MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "093",
              name: "MARICAO MUNICIPIO",
              state: "PR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "095",
              name: "MAUNABO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "097",
              name: "MAYAGUEZ MUNICIPIO",
              state: "PR",
              'CBSA Number': "32420",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "099",
              name: "MOCA MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "101",
              name: "MOROVIS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "103",
              name: "NAGUABO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "105",
              name: "NARANJITO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "107",
              name: "OROCOVIS MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "109",
              name: "PATILLAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "25020",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "111",
              name: "PENUELAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "49500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "113",
              name: "PONCE MUNICIPIO",
              state: "PR",
              'CBSA Number': "38660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "115",
              name: "QUEBRADILLAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "11640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "117",
              name: "RINCON MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "119",
              name: "RIO GRANDE MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "121",
              name: "SABANA GRANDE MUNICIPIO",
              state: "PR",
              'CBSA Number': "41900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "123",
              name: "SALINAS MUNICIPIO",
              state: "PR",
              'CBSA Number': "17640",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "125",
              name: "SAN GERMAN MUNICIPIO",
              state: "PR",
              'CBSA Number': "41900",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "127",
              name: "SAN JUAN MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "129",
              name: "SAN LORENZO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "131",
              name: "SAN SEBASTIAN MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "133",
              name: "SANTA ISABEL MUNICIPIO",
              state: "PR",
              'CBSA Number': "42180",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "135",
              name: "TOA ALTA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "137",
              name: "TOA BAJA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "139",
              name: "TRUJILLO ALTO MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "141",
              name: "UTUADO MUNICIPIO",
              state: "PR",
              'CBSA Number': "10380",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "143",
              name: "VEGA ALTA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "145",
              name: "VEGA BAJA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "147",
              name: "VIEQUES MUNICIPIO",
              state: "PR",
              'CBSA Number': "",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "149",
              name: "VILLALBA MUNICIPIO",
              state: "PR",
              'CBSA Number': "38660",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "151",
              name: "YABUCOA MUNICIPIO",
              state: "PR",
              'CBSA Number': "41980",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "72",
              countyCode: "153",
              name: "YAUCO MUNICIPIO",
              state: "PR",
              'CBSA Number': "49500",
              oneUnit: " $548,250 ",
              twoUnit: " $702,000 ",
              threeUnit: " $848,500 ",
              fourUnit: " $1,054,500 "
            },
            {
              stateCode: "78",
              countyCode: "010",
              name: "ST. CROIX ISLAND",
              state: "VI",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "78",
              countyCode: "020",
              name: "ST. JOHN ISLAND",
              state: "VI",
              'CBSA Number': "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            },
            {
              stateCode: "78",
              countyCode: "030",
              name: "ST. THOMAS ISLAND",
              state: "VI",
              'CBSA Number' : "",
              oneUnit: " $822,375 ",
              twoUnit: " $1,053,000 ",
              threeUnit: " $1,272,750 ",
              fourUnit: " $1,581,750 "
            }
           ]