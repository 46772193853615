
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions'
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
// import Swal from 'sweetalert2'

@Component({
  selector: 'app-certified-agent',
  templateUrl: './certified-agent.component.html',
  styleUrls: ['./certified-agent.component.scss']
})
export class CertifiedAgentComponent implements OnInit {
  pdfSrc: string = '/assets/pdf/VAMortgageFAQForBuyers.pdf';
  zoom: number = 14;
  lat: number = 44.445248;
  lng: number = 26.099672;
  styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    data : Date = new Date();
    focus;
    focus1;

    items: Observable<{ id: string; }[]>;
    voter: any;
    user: string;
    karma: any;
    karmas: Observable<{}>;
    // karmas: Observable<[]>;
    itemDoc: AngularFirestoreDocument;
    item: Observable<any>;
    finalize: any;
    image: string = null;
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
    downloadURL: Observable<string>;
    uploadState: Observable<string>;
    imageString: string;
    imageUrl: string;
    fileRef: any;
    imageLocation: '';
   backgroundURL:'';
   AvatarURL:'';
  soundsDoc: AngularFirestoreCollection<{}>;
  sounds: Observable<{ id: string; }[]>;
 
  street: any;
  num: any;
  // verifiedDoc: AngularFirestoreCollection<{}>;
  verified: Observable<any>;
  verif: any;
  verifDoc: AngularFirestoreDocument<{}>;
  verifiedDoc: Observable<{}>;
  ownerRef: AngularFirestoreDocument<{}>;
  accNum:'';
  ownerDoc: AngularFirestoreDocument<{}>;
  imageChanged: boolean;
  expanded: boolean;
  db: any;
  database: firebase.firestore.CollectionReference;
  openForm: boolean;
  firstName: '';
  lastName: '';
  accountNumber: '';
  streetNumber: '';
  streetName: '';
  phoneNumber: '';
  open11: boolean;
  open22: boolean;
  open33: boolean;
  open44: boolean;
  open55: boolean;
  open: boolean;
  about: any;
  co: any;
  addressNum: any;
  licenceNum: any;
  firstOpen: boolean;
  lastOpen: boolean;
  companyOpen: boolean;
  addressOpen: boolean;
  bioOpen: boolean;
  licenceOpen: boolean;
  itemDoc2:  AngularFirestoreDocument;
  itemDup: Observable<firebase.firestore.DocumentData>;
  itemDocDup: AngularFirestoreDocument<{}>;
  replyId: string;
  str: string;
  passedId: any;
  matt: any;
  refId: any;
  refName: any;
  certifiedDoc: AngularFirestoreDocument<{}>;
  certified: any;
  d: any;
  i: any;
  ifirst: any;
  ilast: any;
  iphone: any;
  iphone2: any;
  iemail: any;
  ilicence: any;
  iaddress: any;
  icompany: any;
  icertified: any;
  uid: any;
  iabout: any;
  icertifiedAgentId: any;
  iinstagram: any;
  itwitter: any;
  ifacebook: any;
  iheader: any;
  ts: Date;
  created: string;
  now: number;
  sendRef: AngularFirestoreCollection<unknown>;
  buyEmail: string;
  buyPhone: string;
  // refName: string;
  refEmail: string;
  refPhone: string;
  notesValue: string;
  buyLastName: string;
  buyFirstName: string;
  confirmation: any;
  name: string;
  referName: any;
  referEmail: any;
  referPhone: any;
  stateValue: any;
  loanAmount: any;
  lo: any;
  sendToLO: any;
  incrementRef: Promise<void>;
  incrementRef3: Promise<void>;
  incrementRef2: Promise<void>;
  incrementRef4: Promise<void>;
  loan: any;
  

  constructor( public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private functions: AngularFireFunctions,
   ) { 
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    console.log(this.replyId)
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.passedId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.passedId);
      
    this.itemDoc = this.afs.doc('certified-agent/'+ this.str);
    this.item = this.itemDoc.valueChanges();
    this.item.subscribe(data =>{
      this.refId = data.uid ;
      this.refName = data;
  
      console.log(this.refName);
      this.certifiedDoc = this.afs.doc('users/'+ this.refId);
      this.certified = this.certifiedDoc.valueChanges();
      this.item = this.certified.subscribe((datas) => { 
        this.d = datas,
        this.i = this.d.photoURL,
        this.ifirst = this.d.firstName,
        this.ilast = this.d.lastName,
        this.iphone = this.d.phone,
        this.iphone2 = this.d.phone
        this.iemail = this.d.email,
        this.ilicence = this.d.licence,
        this.iaddress = this.d.address,
        this.icompany = this.d.company,
        this.icertified = this.d.certified,
        this.uid = this.d.uid,
        this.iabout = this.d.about,
        this.icertifiedAgentId = this.d.certifiedAgentId,
        this.iinstagram = this.d.instagram,
        this.itwitter = this.d.twitter,
        this.ifacebook = this.d.facebook
        this.iheader = this.d.header
        this.lo = this.d.LO
        
         console.log(this.iemail) 
       },(err)=>{
          console.log("probleme : ", err) 
         });
    });
    }

    ngOnInit() {
      var rellaxHeader = new Rellax('.rellax-header');

        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }
    async refer(ifirst, ilast, iemail, iphone, uid, firstName, lastName, email, phone, state, loan, notes){
      this.loan = loan.replace(/\D/g,'');
      const date = new Date();  // 2009-11-10
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      const yearString = year.toString()
      const increment = firebase.firestore.FieldValue.increment(1);
      const valueIncrement = firebase.firestore.FieldValue.increment(+this.loan);
  
      this.ts = new Date();
      this.created = this.ts.toDateString();
      this.now = Date.now();
      console.log('ts' + this.ts)
      console.log('created' + this.created)
      console.log('now' + this.now)
      if(this.lo != undefined){ 
        this.sendToLO = this.lo
        console.log(this.lo, this.sendToLO)
      }
      else {
        this.sendToLO = 'open'
        console.log('Undefined, marked as Open',this.lo, this.sendToLO)
      }
    this.sendRef = this.afs.collection('referrals');
    this.sendRef.add({
 
    exactTime: this.now,
    readableTime: this.created,
    timeStamp: this.ts,
    email: email,
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    referName: this.ifirst + ' ' + this.ilast,
    referEmail: this.iemail,
    referPhone: this.iphone,
    notes: notes,
    owner:this.uid,
    state: state,
    loanAmount: this.loan,
    LO: this.sendToLO,
    closed: false,
    status: 'New',
    dead: false,
    incubation: false
    
    })
    Swal.fire(
      'Success!',
      'Thank you for your submission. The message has been emailed and texted to The VA Team! Someone will be contacting you shortly.',
      
      'success'
    ).then();{
      if(this.lo != undefined){ 
        this.sendToLO = this.lo
       
        this.afs.collection('users').doc(this.lo).update({
          newLeadCount: increment,
          leadsCount: increment,
          leadValueCount: valueIncrement
        })
        this.incrementRef = this.afs.doc('stats/' + this.sendToLO +'/'+ yearString + '/New').set({
          [month]: increment,
          status: 'New',
          value: valueIncrement,
          [month + 'Value']: valueIncrement,
          total: increment
    
        }, { merge: true })
        this.incrementRef3 = this.afs.doc('stats/Team/' + yearString + '/TeamStats').set({
         
        
          totalLeadValue: valueIncrement,
          [month + 'LeadValue']: valueIncrement,
          [month + 'LeadCount']:increment,
          totalLeadCount: increment
    
        }, { merge: true })
        this.incrementRef2 = this.afs.doc('stats/' + this.sendToLO +'/'+ yearString + '/Lead').set({
          [month]: increment,
          status: 'Lead',
          value: valueIncrement,
          [month + 'Value']: valueIncrement,
          total: increment
    
        }, { merge: true })
        console.log(this.lo, this.sendToLO)
      }
      else {
        this.sendToLO = 'open'
        console.log('Undefined, marked as Open',this.lo, this.sendToLO)
        this.incrementRef4 = this.afs.doc('stats/Team/' + yearString + '/TeamStats').set({
          totalOpenLeadValue: valueIncrement,
          [month + 'OpenLeadValue']: valueIncrement,
          [month + 'OpenLeadCount']:increment,
          totalOpenLeadCount: increment
    
        }, { merge: true })
      }
      const email = 'mduhon@mutualmortgage.com, davidsmith@mutualmortgage.com'
      const fun = this.functions.httpsCallable('sendLeadEmail');
      this.confirmation =  await fun({
        dest:this.iemail, 
        readableTime: this.created,
    email: email,
    name: firstName+ ' ' + lastName,
    phone: phone,
    referName: ifirst + ' ' + ilast,
    referEmail: iemail,
    referPhone: iphone,
    notes: notes 
      
      }).toPromise()
        this.buyEmail = '';
        this.name = '';
        this.buyLastName = '';
        this.buyPhone = '';
        this.refName = '';
        this.refPhone = '';
        this.refEmail = '';
        this.notesValue ='';
    }
    
      //  console.log( buyEmail, buyName, buyPhone, refEmail, refName, refPhone)
     
    }
  
}