
import { Component, OnInit, NgZone, ChangeDetectorRef  } from '@angular/core';

import { GeocodeService } from '../shared/services/geocode.service';
import { Location } from '../shared/services/location-model';
import * as firebase from 'firebase/app';
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import * as geofirex from 'geofirex';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
import { Subscription } from '../../../node_modules/rxjs';
// import Swal from 'sweetalert2'


@Component({
  selector: 'app-profile-setup',
  templateUrl: './profile-setup.component.html',
  styleUrls: ['./profile-setup.component.scss']
})
export class ProfileSetupComponent implements OnInit {
  
   data : Date = new Date();
    focus;
    focus1;

 
    // karmas: Observable<[]>;
    itemDoc: AngularFirestoreDocument;

    finalize: any;
    image: string = null;
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
    downloadURL: Observable<string>;
    uploadState: Observable<string>;
    imageString: string;
    imageUrl: string;
    fileRef: any;
    imageLocation: '';
   backgroundURL:'';
   AvatarURL:'';
  soundsDoc: AngularFirestoreCollection<{}>;
  sounds: Observable<{ id: string; }[]>;
 
 
  firstName: '';
  lastName: '';
  email: '';
  phone: '';
  company: '';
  licence: '';
  address: '';
  bio: '';
  facebook: 'http://facebook.com/';
  twitter: 'http://twitter.com/';
  instagram: 'http://instagram.com/';
  downloadSrc: '';
  itemRef: Observable<{}>;
  i: any;
  item: any;
  uid: any;
  go: Promise<boolean>;
  a: any;



  
  
  public customStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]
  ifirst: any;
  ilast: any;
  iphone: any;
  iphone2: any;
  iemail: any;
  ilicence: any;
  iaddress: any;
  icompany: any;
  icertified: any;
  iabout: any;
  icertifiedAgentId: any;
  iinstagram: any;
  itwitter: any;
  ifacebook: any;

  constructor( public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    private geocodeService: GeocodeService,
    private ref2: ChangeDetectorRef,
   ) { 
  
 


   
    this.afAuth.authState.subscribe(user => {
      if(user) {
        console.log(user.uid);
        this.itemRef = this.afs.doc('users/'+ user.uid).valueChanges();
        this.item = this.itemRef.subscribe((datas) => { 
         this.i = datas,
         this.a = this.i.photoURL,
         this.uid = this.i.uid
         this.ifirst = this.i.firstName,
         this.ilast = this.i.lastName,
         this.iphone = this.i.phone,
         this.iphone2 = this.i.phone
         this.iemail = this.i.email,
         this.ilicence = this.i.licence,
         this.iaddress = this.i.address,
         this.icompany = this.i.company,
         this.icertified = this.i.certified,
         this.iabout = this.i.about,
         this.icertifiedAgentId = this.i.certifiedAgentId,
         this.iinstagram = this.i.instagram,
         this.itwitter = this.i.twitter,
         this.ifacebook = this.i.facebook
          console.log("datas", datas) 
        },(err)=>{
           console.log("probleme : ", err) 
          });
      }})
    
}
useLogo(){
  Swal.fire(
    '<h4>Using Your Emblem</h4>',
    '<ul style="text-align:left;"><li>1 - Save your Coin (right click-Save Image As)</li> <li>2 - Upload it to your signature in your email</li><li>3 - Copy your Certified VA Agent link</li><li>4 - Paste it as the link for your signature image </li> <li>5 - Done!</li> </ul>'+
   
    "Don't worry, we will post specific instructions for diffirent email clients in the weeks to come so check back!",
    'success'
  )
}
    ngOnInit() {

    
      var rellaxHeader = new Rellax('.rellax-header');

        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }
   
    upload(event){
      const id = Math.random().toString(36).substring(2);
      this.ref = this.afStorage.ref(id);
      this.task = this.ref.put(event.target.files[0]);
      this.task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = this.ref.getDownloadURL()
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }

    addAvatar(downloadSrc,user){
          
      // this.afAuth.authState.subscribe(user => {
      //   if(user) {
          console.log(user.uid);
const foodRef = this.afs.doc('users/'+ user.uid);
          foodRef.update({
        
           photoURL: downloadSrc,
          
          }).then();{
           
            // alert('Avatar Added');
            setTimeout(function(){ 
              location.reload(); 
            }, 1000);
           
            
          }


        // }})
      
          
    
        }
        step1(){
          console.log( this.downloadSrc);
          this.afAuth.authState.subscribe(user => {
            if (user) {
              try{
          const foodRef = this.afs.doc('users/'+ user.uid);
          foodRef.update({
        firstName: this.firstName,
        lastName: this.lastName,
         
          
          })}catch(e) {
            console.error(e);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 4000
            })
            Toast.fire({
              type: 'error',
              title: 'Nothing updated.  All fields must be complete on this step.'
            })
            // Swal.fire(
            //   'You missed something!',
            //   'If you want to skip to your profile and add your data later then click the <h3>Skip to profile >></h3> button at the bottom',
            //   'warning'
            // )
          };
        }
      })}
      step2(){
        this.afAuth.authState.subscribe(user => {
          if (user) {
            try{
        const foodRef = this.afs.doc('users/'+ user.uid);
        foodRef.update({
      company: this.company,
      email: this.email,
      phone: this.phone,
        address: this.address,
        about: this.bio
        
        })}catch(e) {
          console.error(e);
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 4000
          })
          
          Toast.fire({
            type: 'error',
            title: 'Nothing updated.  All except " Bio " must be complete on this step.'
          })
          // Swal.fire(
          //   'Oh boy..  You missed a lot of things!',
          //   'To skip all this just click the <h3>Skip to profile >></h3> button at the bottom.  Just remember to do it later.',
          //   'warning'
          // )
        };
      }
    })}
    skip(uid){
      this.router.navigate(['profile/:'+ uid]);
    }
    step3(uid){
      this.afAuth.authState.subscribe(user => {
        if (user) {
          try{
      const foodRef = this.afs.doc('users/'+ user.uid);
      foodRef.update({
    facebook: this.facebook,
    twitter: this.twitter,
    instagram: this.instagram,
      licence: this.licence
    
      
      }).then();{
        Swal.fire(
          'Great!',
          'Your profile is now complete!  Taking you there now.',
          'success'
        )
        this.router.navigate(['profile/:'+ uid]);
      }}catch(e) {
        console.error(e);
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-default'
          },
          buttonsStyling: false
        })
        
        swalWithBootstrapButtons.fire({
          title: 'You missed some things!',
          text: "But it's no problem.  You can always add data once you are in your profile." +
          "  But make sure you do because we can't send leads to an incomplete profile. ",
          type: 'question',
          showCancelButton: true,
          confirmButtonText: '<div style="color:#000">Complete Profile Now!</div>',
          cancelButtonText: "Take me to my profile",
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.router.navigate(['profile/:'+ uid]);
          }
        })
       
        // Swal.fire(
        //   'You missed some things!',
        //   'But it"s no big deal.  you can always add data once you are in your profile.  Just remember to add it later because we cannot send you leads if your profile is not finished. The <script (click)="goTo()"><h3>Skip to profile >></h3> </script> button at the bottom of the page will take you to your profile.',
        //   'warning'
        // )
      };
    }
  })
}
  async photo(id,downloadURL,user){
    console.log(downloadURL)
   this.afAuth.authState.subscribe(async user => {
     if(user) {
    console.log(user.uid);
    const {value: file} = await Swal.fire({
     title: 'Select image',
     input: 'file',
     inputAttributes: {
       'accept': 'image/*',
       'aria-label': 'Upload your profile picture'
     }
   })
   
   if (file) {
     const reader = new FileReader
     reader.onload = (e) => {
       Swal.fire({
         title: 'Your uploaded picture',
         imageUrl: e.target["result"] ,
         imageAlt: 'The uploaded picture',
         
       })
       const id = Math.random().toString(36).substring(2);
             this.ref = this.afStorage.ref(id);
             this.task = this.ref.put(file);
             this.task.snapshotChanges().pipe(
               finalize(() => {
                 this.downloadURL = this.ref.getDownloadURL()
                 this.downloadURL.subscribe(url =>{
                   (this.image = url)
                   console.log(url)
                   const foodRef = this.afs.doc('users/'+ user.uid);
                   foodRef.update({
                 
                    photoURL: url,
                   
                   })
                 } );
               
               }),
               
             ).subscribe() 
           
     }
     
     reader.readAsDataURL(file)
   }
 }
})

  }
  

 
  }