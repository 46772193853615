import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  pdfSrc: string = '/assets/pdf/Mutual-Of-Omaha-Mortgage-Notes.pdf';
  itemRef: any;
  replyId: string;
  str: string;
  passedId: string;

  constructor(public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,

    private route: ActivatedRoute,private activatedRoute: ActivatedRoute,
    ) { 
      
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      console.log( this.str);
      this.itemRef = this.afs.doc('notes/'+ this.str).valueChanges();
      this.passedId = this.activatedRoute.snapshot.paramMap.get('id');
      console.log(this.passedId);
      
    }

  ngOnInit() {
  }

}
