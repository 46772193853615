import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  replyId: string;
  str: any;
  certifiedDoc: any;
  certified: any;
  item: any;
  d: any;
  i: any;
  ifirst: any;
  ilast: any;
  iphone: any;
  iphone2: any;
  iemail: any;
  ilicence: any;
  iaddress: any;
  icompany: any;
  icertified: any;
  uid: any;
  iabout: any;
  icertifiedAgentId: any;
  iinstagram: any;
  itwitter: any;
  ifacebook: any;

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
  ) { 
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.certifiedDoc = this.afs.doc('users/'+ this.str);
    this.certified = this.certifiedDoc.valueChanges();
    this.item = this.certified.subscribe((datas) => { 
      this.d = datas,
      // this.i = this.d.photoURL,
      this.ifirst = this.d.firstName,
      this.ilast = this.d.lastName,
      // this.iphone = this.d.phone,
      // this.iphone2 = this.d.phone
      // this.iemail = this.d.email,
      // this.ilicence = this.d.licence,
      // this.iaddress = this.d.address,
      // this.icompany = this.d.company,
      // this.icertified = this.d.certified,
      // this.uid = this.d.uid,
      // this.iabout = this.d.about,
      // this.icertifiedAgentId = this.d.certifiedAgentId,
      // this.iinstagram = this.d.instagram,
      // this.itwitter = this.d.twitter,
      // this.ifacebook = this.d.facebook
      console.log(this.ifirst. this.ilast)
       console.log("datas", datas) 
     },(err)=>{
        console.log("probleme : ", err) 
       });
  }

  ngOnInit() {
  }
cert(){
  this.router.navigate(['cert']); 
}
}
