import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { stringify } from 'querystring';
import { environment } from 'environments/environment'
import * as firebase from 'firebase';
import * as firebase2 from 'firebase';

import { Observable, of, Subscription } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
import { PhoneAuthProvider } from '../../../../node_modules_nope/@firebase/auth-types';


// var secondary = firebase.initializeApp(environment.firebase2, "secondary");
// var firebase2Data = secondary.firestore();

@Injectable({
  providedIn: 'root'
}) 

export class AuthService {
  userData: any; // Save logged in user data
  userInfo: firebase.firestore.DocumentReference;
  // user: Observable<User>;
  itemDoc: AngularFirestoreDocument<{}>;
  item: Observable<{}>;
  first: any;
  last: any;
  street: any;
  num: any;
  verified: any;
  au: Observable<any>;
  
  userStuff: any;
  me: Observable<any>;
  observed: Subscription;
  y: any;
  admin: Subscription;
  a: any;
  passwordResetEmail: '';
  resident: Subscription;
  r: any;
  name: any;
  myName: Subscription;
  boardMember: Subscription;
  board: any;
  manage: Subscription;
  management: any;
  num2: firebase.firestore.Firestore;
  col: any;
  buyerLine: any;
  buyerDoc: any;
  class: any;
  email:'';
  newEmail: AngularFirestoreDocument<unknown>;
  newUserEmail: firebase.User;
  updateNewEmail: Promise<void>;
  db: firebase.firestore.Firestore;
  userCheck: AngularFirestoreDocument<any>;
  userDoc: any;
  userEmail: any;
  userEmailAddress: string;
  roomCheck: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  
  
 
  

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning

  ) {    
  
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user'));
        this.me = this.afs.doc<User>(`users/${user.uid}`).valueChanges() 
      } else {      
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }     
    })
  }

  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
   
      localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      localStorage.removeItem('user');
      this.router.navigate(['index']);

      setTimeout(function(){ 
        location.reload(); 
      }, 500);
    })
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.afAuth.authState.subscribe(user => {
            if(user) {
              this.router.navigate(['profile/:'+ user.uid]);
            }});
        });
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'success',
          title: 'You are logged in!'
        })
        // this.SetUserData(result.user);
      }).catch((error) => {
        this.SignUpQuickNoCE(email,password)
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'info',
          title: 'Creating your account now! '
        })
      })
  }
  SignInCE(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.afAuth.authState.subscribe(user => {
            if(user) {
              // this.router.navigate(['profile/:'+ user.uid]);
            }});
        });
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'success',
          title: 'You are logged in!'
        })
        // this.SetUserData(result.user);
      }).catch((error) => {
        this.SignUpQuickNoCE(email,password)
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'info',
          title: 'Creating your account now! '
        })
      })
  }
  SignInNoCE(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.afAuth.authState.subscribe(user => {
            if(user) {
              this.router.navigate(['profile/:'+ user.uid]);
            }});
        });
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'success',
          title: 'You are logged in!'
        })
        // this.SetUserData(result.user);
      }).catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'error',
          title: 'Sorry, could not log you in.  Please check your username and password'
        })
      })
  }
  SignInQuick(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.afAuth.authState.subscribe(user => {
            if(user) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                type: 'success',
                title: 'You are logged in!'
              })
            }});
        });
        // this.SetUserData(result.user);
      }).catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'error',
          title: 'Sorry, could not log you in.  Please check your username and password'
        })
      })
  }
  test(){
    const secondRef = this.afs.collection('velocify')
    
  }
  updatePassword(){
    var user = firebase.auth().currentUser;
    var auth = firebase.auth();
    var emailAddress = user.email;
    console.log(emailAddress);
 
    
    auth.sendPasswordResetEmail(emailAddress).then(function() {
      // Email sent.
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000
      })
      
      Toast.fire({
        type: 'success',
        title: 'Check your inbox, password re-set email sent!'
      })
    }).catch(function(error) {
      // An error happened.
      alert(error);
    });
  }

  updateEmail(email){

    var user = firebase.auth().currentUser;

user.updateEmail(email).then(function() {
  // Update successful.
  // this.newUserEmail = this.afAuth.auth.currentUser;
  console.log(user.uid, email);

 
  })
.catch(function(error) {
  console.log(error);
  alert(error)
}).then();{
    this.newEmail = this.afs.doc('users/'+ user.uid);
  this.updateNewEmail = this.newEmail.update({
    email: email
  }).then(() => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    })
    
    Toast.fire({
      type: 'success',
      title: 'Email address updated!'
    })
})
  
}
}
    

  // Sign up with email/password
  SignUp(email, password, firstName, lastName, cell ) {
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(user => {
      if(user) {
        
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: 'assets/img/new_logo.png', 
      firstName: firstName,
      lastName: lastName,
      phone: cell,

    }
    userRef.set(userData, {
      merge: true
    }).then();{
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      userRef.update({
        certified: true,
        certifiedAgentId: user.uid,
      })
      let addDoc = db.collection('certified-agent').doc(user.uid).set({
        uid: user.uid,
        
      })
    }
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    })
    
    Toast.fire({
      type: 'success',
      title: 'You are logged in!'
    })
    // this.router.navigate(['profile/:'+ user.uid]); 
      }});


    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        // this.router.navigate(['index/']);
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        // this.SetUserData(result.user);
      }).catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'info',
          title: error.message
        })
        // window.alert(error.message)
      })
      
  }
  SignUpQuickNoCE(email,password){
    this.db = firebase.firestore()
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(user => {
      if(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
     
      photoURL: 'assets/img/new_logo.png', 
      firstName: '',
      lastName: '',
      phone: '',
   
    }
    userRef.set(userData, {
      merge: true
    }).then();{
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.update({
          certified: true,
          certifiedAgentId: user.uid,
        })
        let addDoc = db.collection('certified-agent').doc(user.uid).set({
          uid: user.uid,
          
        }).then();{
          this.router.navigate(['profile/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are registered!'
          })
        }
    
        
      }
    }
    
      });


    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
       
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        // this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
      
  }
  newReg(userName, userPassword, firstName, lastName, license ){
    this.db = firebase.firestore()
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(user => {
      if(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
     
      photoURL: 'assets/img/new_logo.png', 
      firstName: firstName,
      lastName: lastName,
      phone: '',
      // license: license


    }
    userRef.set(userData, {
      merge: true
    })
    .then();{
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      userRef.update({
        certified: true,
        certifiedAgentId: user.uid,
      })
      let addDoc = db.collection('certified-agent').doc(user.uid).set({
        uid: user.uid,
        
      })
        .then();{
          // this.router.navigate(['profile/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are registered!'
          })
        }
    
        
      // }
    }
    
     } });


    return this.afAuth.auth.createUserWithEmailAndPassword(userName, userPassword,)
      .then((result) => {
       
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        // this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
      
  }
  newReg2(userName, userPassword, firstName, lastName, phone){
    this.db = firebase.firestore()
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(user => {
      if(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,

      photoURL: 'assets/img/new_logo.png', 
      firstName: firstName,
      lastName: lastName,
      phone: phone,


    }
    userRef.set(userData, {
      merge: true
    })
    .then();{
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      userRef.update({
        certified: true,
        certifiedAgentId: user.uid,
      })
      let addDoc = db.collection('certified-agent').doc(user.uid).set({
        uid: user.uid,
        
      })
        .then();{
          // this.router.navigate(['profile/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are registered!'
          })
        }
    
        
      // }
    }
    
     } });


    return this.afAuth.auth.createUserWithEmailAndPassword(userName, userPassword,)
      .then((result) => {
       
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        // this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
      
  }
  SignUpQuick(email, password ) {
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(user => {
      if(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
     
      photoURL: 'assets/img/new_logo.png', 
      firstName: '',
      lastName: '',
      phone: '',
    

 
      

    }
    userRef.set(userData, {
      merge: true
    }).then();{
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.update({
          certified: true,
          certifiedAgentId: user.uid,
        })
        let addDoc = db.collection('certified-agent').doc(user.uid).set({
          uid: user.uid,
          
        })
    }
    
      }});


    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        // this.router.navigate(['index/']);
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        // this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
      
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
     
     
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    console.log(passwordResetEmail);
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }
  async getUser() {
    return this.afAuth.auth.currentUser;
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
   
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    const db = firebase.firestore();
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
    this.afAuth.authState.subscribe(user => {
      if(user) {
      
        this.userCheck = this.afs.collection('users/').doc(user.uid);
  
        this.userCheck.valueChanges().subscribe(doc => {
          this.userDoc = doc;
          this.userEmail = this.userDoc
      if (this.userEmail.email.exists) {
        this.router.navigate(['profile/:'+ user.uid]); 
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'success',
          title: 'You are logged in!'
        })
      } 
     else {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL, 
        firstName: user.displayName,
        lastName: '',
        phone: '',
       
      }
      userRef.set(userData, {
        merge: true
      }).then();{
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.update({
          certified: true,
          certifiedAgentId: user.uid,
        })
        let addDoc = db.collection('certified-agent').doc(user.uid).set({
          uid: user.uid,
          
        })
      // this.router.navigate(['profile-setup/:'+ user.uid]); 
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000
      })
      
      Toast.fire({
        type: 'success',
        title: 'You are logged in!'
      })
        
      }
    }
      })
    }
  });
  }
  GoogleAuthQuick() {
    const db = firebase.firestore();
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
    this.afAuth.authState.subscribe(user => {
      if(user) {
      
        this.userCheck = this.afs.collection('users/').doc(user.uid);
  
        this.userCheck.valueChanges().subscribe(doc => {
          this.userDoc = doc;
          this.userEmail = this.userDoc
      if (this.userEmail.email.exists) {
        
        this.router.navigate(['profile/:'+ user.uid]); 
          
      } 
     else {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL, 
        firstName: user.displayName,
        lastName: '',
        phone: '',
       
      }
      userRef.set(userData, {
        merge: true
      }).then();{
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.update({
          certified: true,
          certifiedAgentId: user.uid,
        })
        let addDoc = db.collection('certified-agent').doc(user.uid).set({
          uid: user.uid,
          
        })
      // this.router.navigate(['profile-setup/:'+ user.uid]); 
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000
      })
      
      Toast.fire({
        type: 'success',
        title: 'You are logged in!'
      })
        
      }
    }
      })
    }
  });
  }
  GoogleLogin() {
   
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
    this.db = firebase.firestore()
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(async user => {
      if(user) {
        this.db = firebase.firestore()
        this.roomCheck = this.db.collection('users/').doc(user.uid);
  
        this.roomCheck.get().then(doc => {
      if (doc.exists) {
          console.log("Document data:", doc.data());
          this.router.navigate(['profile/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are logged in!'
          })
         
      } 
    //  else {
    //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    //   const userData: User = {
    //     uid: user.uid,
    //     email: user.email,
    //     displayName: user.displayName,
    //     photoURL: user.photoURL, 
    //     firstName: user.displayName,
    //     lastName: '',
    //     phone: '',
       
    //   }
    //   userRef.set(userData, {
    //     merge: true
    //   }).then();{
    //     const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    //     userRef.update({
    //       certified: true
    //     })
    //     let addDoc = db.collection('certified-agent').add({
    //       uid: user.uid,
          
    //     }).then(ref => {
    //       console.log('Added document with ID: ', ref.id);
    //       let userRef = db.doc('users/'+ user.uid).update({
    //         certifiedAgentId: ref.id,
    //         certified: true
    //       })
    //     }).then();{
    //       this.router.navigate(['profile/:'+ user.uid]); 
    //       const Toast = Swal.mixin({
    //         toast: true,
    //         position: 'top-end',
    //         showConfirmButton: false,
    //         timer: 5000
    //       })
          
    //       Toast.fire({
    //         type: 'success',
    //         title: 'You are registered!'
    //       })
    //     }
    
        
    //   }
    //       console.log("Creating you an account now");
    //   }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  
  }); 
  
  }})
   
  }
  GoogleLoginQuick() {
    const db = firebase.firestore();
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
    this.afAuth.authState.subscribe(user => {
      if(user) {
      
        this.userCheck = this.afs.collection('users/').doc(user.uid);
  
        this.userCheck.valueChanges().subscribe(doc => {
          this.userDoc = doc;
          this.userEmail = this.userDoc
      if (this.userEmail.email.exists) {
        this.router.navigate(['profile/:'+ user.uid]); 
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'success',
          title: 'You are logged in!'
        })
      } 
     else {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL, 
        firstName: user.displayName,
        lastName: '',
        phone: '',
       
      }
      userRef.set(userData, {
        merge: true
      }).then();{
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.update({
          certified: true,
          certifiedAgentId: user.uid,
        })
        let addDoc = db.collection('certified-agent').doc(user.uid).set({
          uid: user.uid,
          
        })
      // this.router.navigate(['profile-setup/:'+ user.uid]); 
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000
      })
      
      Toast.fire({
        type: 'success',
        title: 'You are logged in!'
      })
        
      }
    }
      })
    }
  });
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
      this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error)
    })
  }
  doFacebookLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.db = firebase.firestore()
        const db = firebase.firestore();
        this.afAuth.authState.subscribe(async user => {
          if(user) {
            this.db = firebase.firestore()
            this.roomCheck = this.db.collection('users/').doc(user.uid);
      
            this.roomCheck.get().then(doc => {
          if (doc.exists) {
              console.log("Document data:", doc.data());
              this.router.navigate(['profile/:'+ user.uid]); 
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                type: 'success',
                title: 'You are logged in!'
              })
             
          } 
         else {
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL, 
            firstName: user.displayName,
            lastName: '',
            phone: '',
           
          }
          userRef.set(userData, {
            merge: true
          }).then();{
            const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
            userRef.update({
              certified: true,
              certifiedAgentId: user.uid,
            })
            let addDoc = db.collection('certified-agent').doc(user.uid).set({
              uid: user.uid,
              
            }).then();{
              this.router.navigate(['profile/:'+ user.uid]); 
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                type: 'success',
                title: 'You are registered!'
              })
            }
        
            
          }
              console.log("Creating you an account now");
          }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      
      }); 
      
      }})
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }
  doFacebookReg(){
    const db = firebase.firestore();
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.afAuth.authState.subscribe(user => {
          if(user) {
          
            this.userCheck = this.afs.collection('users/').doc(user.uid);
      
            this.userCheck.valueChanges().subscribe(doc => {
              this.userDoc = doc;
              this.userEmail = this.userDoc
          if (this.userEmail.email.exists) {
            this.router.navigate(['profile/:'+ user.uid]); 
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000
            })
            
            Toast.fire({
              type: 'success',
              title: 'You are logged in!'
            })
          } 
         else {
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL, 
            firstName: user.displayName,
            lastName: '',
            phone: '',
           
          }
          userRef.set(userData, {
            merge: true
          }).then();{
            const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
            userRef.update({
              certified: true,
              certifiedAgentId: user.uid,
            })
            let addDoc = db.collection('certified-agent').doc(user.uid).set({
              uid: user.uid,
              
            })
          // this.router.navigate(['profile-setup/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are logged in!'
          })
            
          }
        }
          })
        }
      });
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }
  FacebookLogin(){
    const db = firebase.firestore();
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.db = firebase.firestore()
    const db = firebase.firestore();
    this.afAuth.authState.subscribe(async user => {
      if(user) {
        this.db = firebase.firestore()
        this.roomCheck = this.db.collection('users/').doc(user.uid);
  
        this.roomCheck.get().then(doc => {
      if (doc.exists) {
          console.log("Document data:", doc.data());
          this.router.navigate(['profile/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are logged in!'
          })
         
      } 
     else {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const userData: User = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL, 
        firstName: user.displayName,
        lastName: '',
        phone: '',
       
      }
      userRef.set(userData, {
        merge: true
      }).then();{
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        userRef.update({
          certified: true,
          certifiedAgentId: user.uid,
        })
        let addDoc = db.collection('certified-agent').doc(user.uid).set({
          uid: user.uid,
          
        }).then();{
          this.router.navigate(['profile/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are registered!'
          })
        }
    
        
      }
          console.log("Creating you an account now");
      }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  
  }); 
  
  }})
        resolve(res);
      }, err => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          type: 'error',
          title: 'Sorry, could not log you in.  Please check your username and password'
        })
      
        console.log(err);
        reject(err);
      })
    })
  }
  FacebookLoginQuick(){
    const db = firebase.firestore();
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.afAuth.authState.subscribe(user => {
          if(user) {
          
            this.userCheck = this.afs.collection('users/').doc(user.uid);
      
            this.userCheck.valueChanges().subscribe(doc => {
              this.userDoc = doc;
              this.userEmail = this.userDoc
          if (this.userEmail.email.exists) {
            this.router.navigate(['profile/:'+ user.uid]); 
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000
            })
            
            Toast.fire({
              type: 'success',
              title: 'You are logged in!'
            })
          } 
         else {
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL, 
            firstName: user.displayName,
            lastName: '',
            phone: '',
           
          }
          userRef.set(userData, {
            merge: true
          }).then();{
            const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
            userRef.update({
              certified: true
            })
          // this.router.navigate(['profile-setup/:'+ user.uid]); 
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            type: 'success',
            title: 'You are logged in!'
          })
            
          }
        }
          })
        }
      });
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }
 

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL, 
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      // licence: user.license,
      // // certified: false,
      // company: '',
      // bio: '',
      // location:'',    
      
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out 
 

}