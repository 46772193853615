import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preproperties',
  templateUrl: './preproperties.component.html',
  styleUrls: ['./preproperties.component.scss']
})
export class PrepropertiesComponent implements OnInit {
otherAgent: any;
BuyerName: any;
BuyerEmail: any;
BuyerPhone: any;
notes: any;
  constructor() { }

  ngOnInit() {
  }

}
