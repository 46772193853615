import { Component, OnInit, Renderer, OnDestroy, NgZone,Inject, Injectable } from '@angular/core';
import { NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';
import { Router } from "@angular/router";
import dayGridPlugin from '@fullcalendar/daygrid';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from '../shared/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { DOCUMENT } from "@angular/common";
import * as firebase from 'firebase';
import { timingSafeEqual } from 'crypto';
import { NgSelectModule } from '@ng-select/ng-select'
import { AngularFireFunctions } from '@angular/fire/functions'


@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})


export class ComponentsComponent implements OnInit, OnDestroy {
  test: boolean;
    calendarPlugins = [dayGridPlugin]; 
    data : Date = new Date();

    page = 4;
    page1 = 5;
    page2 = 3;
    focus;
    focus1;
    focus2;
    closeResult: string;
   
    link: string;
    user: string;
    itemDoc: AngularFirestoreDocument<{}>;
    item: any;
    referalName: any;
    refName: any;
    refEmail: any;
    refPhone: any;
    refLast: any;
    ts: Date;
    created: any;
    now: number;
    sendRef: AngularFirestoreCollection<{}>;
    eventDoc: AngularFirestoreDocument<unknown>;
    event: any;
    today: string;
  year: any;
  month: any;
  day: any;
  dates: number;
  maths: any;
  monthRef: string;
  dayRef: string;
    eventCollection2: AngularFirestoreCollection<Event>;
    eventsList: any;
    AttendedDoc: any;
    openSection: boolean;
    bool: any;
    domain: any;
    code: any;
    class: any;
    itemOb: any;
    d: any;
    i: any;
    ifirst: any;
    ilast: any;
    iphone: any;
    iphone2: any;
    iemail: any;
    ilicence: any;
    iaddress: any;
    icompany: any;
    icertified: any;
    uid: any;
    iabout: any;
    icertifiedAgentId: any;
    iinstagram: any;
    itwitter: any;
    ifacebook: any;
  reg: boolean;
  log: boolean;

  selectedSimpleItem = 'Military Branch';
    simpleItems = [];
    selectedSimpleItem2 = 'Min. Credit Score';
    simpleItems2 = [];
  gate: boolean;
  voteCountRef: any;
  voteCount: any;
  vote: any;
  buyFirstName: string;
  buyLastName: string;
  notes: any;
  confirmation: any;
  name: string;
  loan: any;
  phone: any;
  buyState:'';
  loanAmount: '';
    handleDateClick(arg) { // handler method
      alert(arg.dateStr);
  
    }
    buyName: '';
    buyEmail:'';
    buyPhone:'';

    date: {year: number, month: number};
    model: NgbDateStruct;

    public isCollapsed = true;
    public isCollapsed1 = true;
    public isCollapsed2 = true;

    state_icon_primary = true;
 
   

    constructor( @Inject(DOCUMENT) private document: any, private renderer : Renderer, config: NgbAccordionConfig,  public router: Router,private modalService: NgbModal,public afs: AngularFirestore, public ngZone: NgZone, public authService: AuthService,
        public afAuth: AngularFireAuth,
        private afa: AngularFireAuth,
        private functions: AngularFireFunctions,) {
         
          this.gate = true;
          this.test = false;
            this.reg = false;
            this.log = false;
            this.class='Veteran'
            this.voteCountRef = this.afs.doc('poll/poll1').valueChanges();
            this.voteCount = this.voteCountRef.subscribe((votes) => { 
              this.vote = votes
              
            });
            this.afAuth.authState.subscribe(user => {
              if(user) {
                this.itemOb = this.afs.doc('users/'+ user.uid).valueChanges();
                this.item = this.itemOb.subscribe((datas) => { 
                  this.d = datas,
                  this.i = this.d.photoURL,
                  this.ifirst = this.d.firstName,
                  this.ilast = this.d.lastName,
                  this.iphone = this.d.phone,
                  this.iphone2 = this.d.phone
                  this.iemail = this.d.email,
                  this.ilicence = this.d.licence,
                  this.iaddress = this.d.address,
                  this.icompany = this.d.company,
                  this.icertified = this.d.certified,
                  this.uid = this.d.uid,
                  this.iabout = this.d.about,
                  this.icertifiedAgentId = this.d.certifiedAgentId,
                  this.iinstagram = this.d.instagram,
                  this.itwitter = this.d.twitter,
                  this.ifacebook = this.d.facebook
                  
                  
                 },(err)=>{
                    console.log("probleme : ", err) 
                   });
              }})
            if (this.code == this.class){
             
            }
            this.year = new Date().getFullYear()
            this.month = new Date().getMonth() + 1
            this.day = new Date().getDate()
           
            if (this.month < 10) {
              this.month = "0"+ (new Date().getMonth() + 1)
           
            }
           
            if (this.day < 10) {
             this.day = "0"+ new Date().getDate()
            
           }
           this.today = this.year + '-' + this.month + '-' + this.day;
          
           this.eventCollection2 = this.afs.collection<Event>('event-requests/', ref => ref.where('date', '==', this.today).limit(2));
           this.eventsList = this.eventCollection2.snapshotChanges().pipe(
             map(actions => actions.map(a => {
               const data = a.payload.doc.data();
               const id = a.payload.doc.id; 
               this.bool = data
              
   if (this.today = this.bool.date) {
       this.openSection = true
   }
   else {
       this.openSection = false;
   }
               return { id, ...data };
             })))
         
            this.afAuth.authState.subscribe(user => {
                if(user) {
          this.user = user.uid
          this.itemDoc = this.afs.doc('users/'+ user.uid);
          this.item = this.itemDoc.valueChanges();
          this.item.subscribe(data =>{
            this.refName = data.firstName + ' ' + data.lastName;
            this.refEmail = data.email;
            this.refPhone = data.phone;
            
          });
        
                }
              });
        config.closeOthers = true;
        config.type = 'info';
        this.simpleItems = ['Army', 'Navy', 'Air Force', 'Marines', 'Coast Guard'];
        this.simpleItems2 = ['640', '600', '580', '610', 'They do not have a minimum'];
    }
    
   
    creditAnswer(value){

    }
    answer(selectedSimpleItem2, selectedSimpleItem){
 
   if (selectedSimpleItem == 'Army' && selectedSimpleItem2 == 'They do not have a minimum'){
    Swal.fire(
      'You passed!',
      'Now you need to go register!',
      
      'success'
    )
    this.gate = false
    this.reg = true;
    this.log = false;
    this.test = false;
   }
   else {
    Swal.fire(
      'incorrect...',
      'At best you got 50% correct...',
      
      'error'
    )
   }
          }
 
    

    attend(id){
        this.afAuth.authState.subscribe(user => {
            if(user) {
      this.user = user.uid
      this.AttendedDoc = this.afs.collection('users/'+ user.uid + '/classes-attended');
      this.item = this.AttendedDoc.add({
          classesAttended: id,
          
      })
      Swal.fire(
        'Success!',
        'Thank you for signing in!',
        
        'success'
      )
    
            }
          });
    }
    open(content, type) {
        if (type === 'sm') {
           
            this.modalService.open(content, { size: 'sm' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            this.modalService.open(content).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }
    testForm(firstName, lastName, email, phone, state, loan, note){
      this.loan = loan.replace(/\D/g,'');
      this.phone = phone.replace(/\D/g,'');
      console.log(firstName, lastName, email, phone, state, loan, note, 'Phone', this.phone)
      if(+this.loan >= 0) {
        console.log(this.loan)
      }
    }
    async refer(firstName, lastName, email, phone, state, loan, note){
      this.loan = loan.replace(/\D/g,'');
      this.phone = phone.replace(/\D/g,'');
      this.ts = new Date();
      this.created = this.ts.toDateString();
      this.now = Date.now();
      console.log('ts' + this.ts)
      console.log('created' + this.created)
      console.log('now' + this.now)
      if(+this.loan >= 0) {
        console.log(this.loan)
      }
    this.sendRef = this.afs.collection('referrals');
    this.sendRef.add({
   
    exactTime: this.now,
    readableTime: this.created,
    timeStamp: this.ts,
    email: email,
    firstName: firstName,
    lastName: lastName,
    phone:this.phone,
    referName: ' theVAloan.org Home Page!',
    notes: note,
    LO: 'open',
    loanAmount: this.loan,
    closed: false,
    status: 'New',
    dead: false,
    incubation: false,
    state: state
    
    })
    Swal.fire(
      'Success!',
      'Thank you for your submission. The message has been emailed and texted to David Smith and the VA Team! Someone will be contacting you shortly.',
      
      'success'
    ).then();{
      const email = 'mduhon@mutualmortgage.com'
      const fun = this.functions.httpsCallable('sendLeadEmail');
      this.confirmation =  await fun({
        dest:email, 
        readableTime: this.created,
    email: this.buyEmail,
    name: this.buyFirstName + ' ' + this.buyLastName,
    phone: this.buyPhone,
    referName: 'theVAloan.org Front Page Lead',
    referEmail: 'none',
    referPhone: 'none',
    notes: this.notes 
      
      }).toPromise()
        this.buyEmail = '';
        this.name = '';
        this.buyFirstName = '';
        this.buyLastName = '';
        this.buyPhone = '';
     
        this.notes ='';
    }
    
      //  console.log( buyEmail, buyName, buyPhone, refEmail, refName, refPhone)
     
    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ngOnInit() {
        this.domain = this.document.location.hostname;
        console.log(this.domain);
      // var rellaxHeader = new Rellax('.rellax-header');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
    }
    ngOnDestroy(){
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }
login(){
    this.router.navigate(['login/']);
}

certify(){
    const db = firebase.firestore();
    
    this.afAuth.authState.subscribe(user => {
      if(user) {
 
    let addDoc = db.collection('certified-agent').add({
      uid: user.uid,
      
    }).then(ref => {
      console.log('Added document with ID: ', ref.id);
      let userRef = db.doc('users/'+ user.uid).update({
        certifiedAgentId: ref.id,
        certified: true
      }).then();{
 this.router.navigate(['profile-setup/:'+ user.uid])
      };
    })
  
  
    }})
  }

  profile(){
    this.afAuth.authState.subscribe(user => {
      if(user) {
        this.router.navigate(['profile/'+ user.uid]);
        this.modalService.dismissAll();
      }});
  }
  
  profileSetup(){
    this.afAuth.authState.subscribe(user => {
      if(user) {
        this.router.navigate(['profile-setup/'+ user.uid]);
        this.modalService.dismissAll();
      }});
  }
register(){
    this.router.navigate(['register/']);
}
regButton(){
  this.gate = false;
  this.reg = true;
  this.log = false;
  this.test = false;
}
logButton(){
  this.gate = false;
  this.reg = false;
  this.log = true;
  this.test = false;
}
testButton(){
  this.gate = false;
  this.test = true
}
sneak(){
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000
  })
  
  Toast.fire({
    type: 'success',
    title: "Don't like tests do you.. We like you already.. "
  })
}
}
