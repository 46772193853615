import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {
  

  private itemCol: AngularFirestoreCollection;
  items: Observable<{ id: string; }[]>;
  constructor(private afs: AngularFirestore,public router: Router,) { 
    this.itemCol = this.afs.collection('users', ref => ref.where('certified','==', true));
    this.items = this.itemCol.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;

           
         
          
        return { id, ...data };
      }))
    );
  }

  ngOnInit() {
  }
click(certifiedAgentId){
  this.router.navigate(['certified-agent/:'+ certifiedAgentId]);
}
}
