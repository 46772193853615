import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
import { firestore } from 'firebase';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-event',
  templateUrl: './request-event.component.html',
  styleUrls: ['./request-event.component.scss']
})
export class RequestEventComponent implements OnInit {
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploadState: Observable<string>;
  imageString: string;
  imageUrl: string;
  fileRef: any;
  ref: AngularFireStorageReference;
  task: any;
  image: string;
  eventId: any;
  replyId: string;
  str: any;
  passedId: string;
  itemRef: Observable<{}>;
  item: Observable<{}>;
  formInfo:'';
  formInfoTime:'';
  Address:'';
  rsvp:'';
  model;
  dateRef: any;
  markDisabled;
  closeResult: string;
  
  
  
  itemCol: AngularFirestoreDocument<{}>;
  date: any;
  dateObj: any;
  dateList: any;
  items: any;
 
  

  constructor(  public router: Router,
    private modalService: NgbModal,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,private activatedRoute: ActivatedRoute,
    ) {
      const isDisabled = (date: NgbDate, current: {month: number}) => date.day >= 6;
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      console.log( this.str);
      this.passedId = this.activatedRoute.snapshot.paramMap.get('id');
      console.log(this.passedId);
      // this.itemCol = this.afs.doc('event-requests/'+ this.str + '/dates');
      // this.dates = this.itemCol.snapshotChanges().pipe(
      //   map(actions => actions.map(a => {
      //     const data = a.payload.doc.data();
      //     const id = a.payload.doc.id;
  
             
           
            
      //     return { id, ...data };
      //   }))
      // );
this.item = this.afs.doc('event-requests/'+ this.str).valueChanges()

this.date = this.item.subscribe( data => {
  console.log(data);
  this.dateList = data;
  this.items = this.dateList.dates;
  console.log(this.dateList.dates)

})


     }

  ngOnInit() {
  
  }
  getDate(model){
    this.dateRef = this.model.year+'-'+ this.model.month+'-'+ this.model.day 
    console.log(this.dateRef);
    const formRef = this.afs.doc('event-requests/'+ this.str);
    formRef.update({
      date: this.dateRef
     
   
    
    })
  }
  submitInfoRsvp(rsvp){
    console.log(this.rsvp);

  const formRef = this.afs.doc('event-requests/'+ this.str);
  formRef.update({

  rsvp: this.rsvp,
  
  })
  }
submitInfo(){

  console.log(this.formInfo);

  const formRef = this.afs.doc('event-requests/'+ this.str);
  formRef.update({

  location: this.formInfo,
  
  })
}
submitTimeInfo(formInfo){
  console.log(this.formInfoTime);

  const formRef = this.afs.doc('event-requests/'+ this.str);
  formRef.update({

  time: this.formInfoTime,
  
  })
}
submitInfoAddress(Address){
  console.log(this.Address);

  const formRef = this.afs.doc('event-requests/'+ this.str);
  formRef.update({

  address: this.Address,
  
  })
}
  async edit(id,downloadURL){
    // console.log(downloadURL)
    this.eventId = id;
    console.log('works');
   this.afAuth.authState.subscribe(async user => {
     if(user) {
    console.log(user.uid);
    const {value: file} = await Swal.fire({
     title: 'Select image',
     input: 'file',
     inputAttributes: {
       'accept': 'image/*',
       'aria-label': 'Upload your company logo'
     }
   })
   
   if (file) {
     const reader = new FileReader
     reader.onload = (e) => {
       Swal.fire({
         title: 'Your uploaded picture',
         imageUrl: e.target["result"] ,
         imageAlt: 'The uploaded picture',
         
       })
       const id = Math.random().toString(36).substring(2);
             this.ref = this.afStorage.ref(id);
             this.task = this.ref.put(file);
             this.task.snapshotChanges().pipe(
               finalize(() => {
                 this.downloadURL = this.ref.getDownloadURL()
                 this.downloadURL.subscribe(url =>{
                   (this.image = url)
                   console.log(url)
                   const foodRef = this.afs.doc('event-requests/'+ this.str);
                   foodRef.update({
                 
                    logo: url,
                   
                   })
                 } );
               
               }),
               
             ).subscribe() 
           
     }
     
     reader.readAsDataURL(file)
   }
 }
})

  }
  open(content, type) {
    if (type === 'sm') {
        console.log('aici');
        this.modalService.open(content, { size: 'sm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } else {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
}

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
}

}
  
