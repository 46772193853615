import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cert',
  templateUrl: './cert.component.html',
  styleUrls: ['./cert.component.scss']
})
export class CertComponent implements OnInit {
  name: string;

  constructor() {
    this.name = '';
   }

  ngOnInit() {
  }
  onPrint(){
    window.print();
}
}
