
import { Component, OnInit, NgZone, Input, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "../shared/services/auth.service";
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {debounceTime} from 'rxjs/operators';
import { PaymentService } from "../shared/services/payment.service"
import { source } from '../../../node_modules_nope/@angular-devkit/schematics/src';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PDFAnnotationData } from 'pdfjs-dist';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import * as firebase from "firebase";
import { AngularFireFunctions } from '@angular/fire/functions'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CertifiedAgentComponent } from 'app/certified-agent/certified-agent.component';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
// import { Input } from './Input'

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
// export class Input {
//   name: string;
//   type: string;
//   top: number;
//   left: number;
//   width: number;
//   height: number;
//   value; any;
// }
export class PaymentsComponent implements OnInit {
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  readonly dpiRatio = 96 / 72;
  pdfSrc: string = '/assets/pdf/trek.pdf';
  private _success = new Subject<string>();
  userId: string;
  staticAlertClosed = false;
  successMessage: string;
  closeResult: string;
  itemDoc: Observable<{}>;
  item: any;
  replyId: string;
  str: any;
  itemRef: Observable<unknown>;
  passedId: string;
  info: any;
  image: any;
  location: any;
  address: any;
  time: any;

  title: any;
  city: any;
  login: boolean;

  userStripe: any;
  id: unknown;
  stripeRef: any;
  tokenId: any;
  isCE: any;
  pretty: any;
pageVariable: string;
 


public myForm: FormGroup;

  input: any;
  res: any;

  confirmation: any;
  uid: any;
email:any;
  userRef: Observable<any>;
  stripeId: any;
  d:any;
  name: any;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  downloadURL: Observable<any>;
  userRInfo: AngularFirestoreDocument<unknown>;
  userInfo: AngularFirestoreDocument<unknown>;
  userInfoSubmit: Promise<void>;
  firstName: '';
  lastName:'';
  emailAddress: '';
  phone:'';
  state:'';
  license:'';
  processing: any;
  payment: any;
  noCE: boolean;
  needCE: boolean;
  signed: any;
  signature: any;
  formSaved: any;
  now: number;
  classId: any;






  constructor(
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    public authService: AuthService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, 
    private modalService: NgbModal,
    private paymentSvc: PaymentService,
    private _fb: FormBuilder,
    private functions: AngularFireFunctions,
  ) {
    this.now = Date.now()
   this.noCE = false
   this.needCE = false
    this.myForm = this._fb.group({});
    // console.log(this._fb)
    this.pageVariable = 'Matt Duhon'
    this.afAuth.authState.subscribe(user => {
      if (user){ console.log(user)

        this.itemDoc = this.afs.doc('users/'+ user.uid).valueChanges();
              this.item = this.itemDoc.subscribe((datas) => { 
                this.d = datas,
                this.name = this.d.firstName
                this.stripeId = this.d.stripeCustomerId
                this.processing = this.d.processing
                this.payment = this.d.payment
                this.signed = this.d.formSigned
                this.signature = this.d.signature
                this.formSaved = this.d.infoSubmitted
                this.classId = this.d.class
                if(this.stripeId == null){
                  this.updadeStripeUser()
                  console.log(`NOT FOUND, Creating Stripe Customer Id`);
                  console.log(this.stripeId)
                }else if (this.stripeId != null){
                  console.log(`Stripe Id is`, this.stripeId);
                  
            }
              
               },(err)=>{
                  console.log("probleme : ", err) 
                 });
      }
    
    })
  
    this.login = false;
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.itemRef = this.afs.doc('notes/'+ this.str).valueChanges();
    this.passedId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.passedId);
    this.itemDoc = this.afs.doc('event-requests/'+ this.str).valueChanges();
    this.item = this.itemDoc.subscribe((datas) => { 
      this.info = datas
      // this.isCE = this.info.isCE,
      // this.pretty = this.info.date
    })
    
  
  } 
//   private createInput(annotation: PDFAnnotationData, rect: number[] = null) {
//     let formControl = new FormControl(annotation.buttonValue || '');

//     const input = new Input();
//     input.name = annotation.fieldName;

//     if (annotation.fieldType === 'Tx') {
//         input.type = 'text';
//         input.value = annotation.buttonValue || '';
//         input.value = annotation.buttonValue[27] || 'Matt, Duhon';
//     }

//     // Calculate all the positions and sizes
//     if (rect) {
//         input.top = rect[1] - (rect[1] - rect[3]);
//         input.left = rect[0];
//         input.height = (rect[1] - rect[3]);
//         input.width = (rect[2] - rect[0]);
//     }

//     this.inputList.push(input);
//     return formControl;
// }



// public getInputPosition(input: Input): any {
//     return {
//         top: `${input.top}px`,
//         left: `${input.left}px`,
//         height: `${input.height}px`,
//         width: `${input.width}px`,
//     };
// }


//   private addInput(annotation: PDFAnnotationData, rect: number[] = null): void {
//     // add input to page
//     this.myForm.addControl(annotation.fieldName, this.createInput(annotation, rect));
  
  
// }

// loadComplete(pdf: PDFDocumentProxy): void {
//     for (let i = 1; i <= pdf.numPages; i++) {

//         // track the current page
//         let currentPage = null;
//         pdf.getPage(i).then(p => {
//             currentPage = p;

//             // get the annotations of the current page
//             return p.getAnnotations();
//         }).then(ann => {

//             // ugly cast due to missing typescript definitions
//             // please contribute to complete @types/pdfjs-dist
//             const annotations = (<any>ann) as PDFAnnotationData[];

//             annotations
//                 .filter(a => a.subtype === 'Widget') // get the form field annotation only
//                 .forEach(a => {
              
//                     // get the rectangle that represent the single field
//                     // and resize it according to the current DPI
//                     const fieldRect = currentPage.getViewport(this.dpiRatio)
//                                                  .convertToViewportRectangle(a.rect);

//                     // add the corresponding input
//                     this.addInput(a, fieldRect);
//                 });
//         });
//     }
// }

  ngOnInit(): void {
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear();
    this.loadStripe();
    setTimeout(() => this.staticAlertClosed = true, 8000);

    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = null);
  }
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
  }
 
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }
  clear(){
    this.signaturePad.clear();
  }
  saveSig(firstName, lastName, state, license, phone, email ){
    this.afAuth.authState.subscribe(user => {
      if (user) {
  this.userInfo = this.afs.doc('users/'+ user.uid);
  this.userInfoSubmit = this.userInfo.update({
    signature: this.signaturePad.toDataURL(),
    formSigned: true,
    infoSubmitted: true,
    signatureTimestamp: this.now,
  }).then();{
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    })
    Toast.fire({
      type: 'success',
      title: 'Professional Information Signed And Saved!'
    })
  }
      }
    else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000
      })
      Toast.fire({
        type: 'error',
        title: 'You must be logged in to submit the form..'
      })
    }})
  }
  updateStripe(){

  }
  yesCE(){
    this.needCE = true
  }
  dontNeed(){
    this.router.navigate(['register/:'+ this.str])
    // this.noCE = true
  }
step1(firstName, lastName, state, license, phone, email ){
  
  this.afAuth.authState.subscribe(user => {
    if (user) {
this.userInfo = this.afs.doc('users/'+ user.uid);
this.userInfoSubmit = this.userInfo.update({
 
    firstName: this.firstName,
    lastName: this.lastName,
    email: this.email,
    phone: this.phone,
    state: this.state,
    license: this.license,
    class: this.str,
 
  infoSubmitted: true
}).then();{
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  })
  Toast.fire({
    type: 'success',
    title: 'Professional Information Submitted!'
  })
}
    }
  else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000
    })
    Toast.fire({
      type: 'error',
      title: 'You must be logged in to submit the form..'
    })
  }})
}
step2(){
 
}
formDownload(){
  this.afAuth.authState.subscribe(user => {
    if (user) {
this.userInfo = this.afs.doc('users/'+ user.uid);
this.userInfoSubmit = this.userInfo.update({

    downloadedForm: true,
   
 
}).then();{
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  })
  Toast.fire({
    type: 'success',
    title: 'Action Saved!!'
  })
}
    }
  else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000
    })
    Toast.fire({
      type: 'error',
      title: 'You must be logged in to submit the form..'
    })
  }})
}
step3(){
  this.afAuth.authState.subscribe(user => {
    if (user) {
    
  this.router.navigate(['profile/:'+ user.uid])
    }})
}
  public changeSuccessMessage() {
    this._success.next(`${new Date()} - Message successfully changed.`);
  }
  loggingIn(){
    this.login = true;
  }
  loggedIn(){
    this.login = false;
  }
  registered(){
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.modalService.dismissAll();
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000
        })
        Toast.fire({
          type: 'success',
          title: 'You are Registered!  Dont forget to update your profile later!'
        })
      }
    
      
    })
   
    
  }
  open(content, type) {
    if (type === 'sm') {
        console.log('aici');
        this.modalService.open(content, { size: 'sm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } else {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
}

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
}

loadStripe() {
     
  if(!window.document.getElementById('stripe-script')) {
    var s = window.document.createElement("script");
    s.id = "stripe-script";
    s.type = "text/javascript";
    s.src = "https://checkout.stripe.com/checkout.js";
    window.document.body.appendChild(s);
  }
}

pay(amount) {    
  

  this.afAuth.authState.subscribe(user => {
    if (user) {
    console.log(this.stripeId.stripeCustomerId);
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_live_ynDn5PpekYgTOG854lAwq7Tf00YW7XI3pg',
      image: 'assets/img/certified-agent.png',
      locale: 'auto',
     
      source: source => {
     
        console.log(source)
        // this.paymentSvc.createSource(source)
         this.paymentSvc.processPayment(source)
      },

      
   

    });
    handler.open({
      name: 'theVAloan.org',
      description: 'C.E. Credit Course',
      amount: amount * 100
    });
  }})


}
 send(token, amount){

 }
async updadeStripeUser(){
  this.afAuth.authState.subscribe(async user => {
    if (user) {
  const fun = this.functions.httpsCallable('updateStripeCustomer');
  this.confirmation =  await fun({email:user.email, uid: user.uid }).toPromise()
 console.log(this.confirmation)
    }})
}

async uploadForm(id,downloadURL,user){
  console.log(downloadURL)
 this.afAuth.authState.subscribe(async user => {
   if(user) {
  console.log(user.uid);
  const {value: file} = await Swal.fire({
   title: 'Select image',
   input: 'file',
   inputAttributes: {
     'accept': 'pdf/*',
     'aria-label': 'Upload Your Form Here'
   }
 })
 
 if (file) {
   const reader = new FileReader
   reader.onload = (e) => {
     Swal.fire({
       title: 'Your Uploaded Form',
       imageUrl: e.target["result"] ,
       imageAlt: 'The uploaded form',
       
     })
     const id = Math.random().toString(36).substring(2);
           this.ref = this.afStorage.ref(id);
           this.task = this.ref.put(file);
           this.task.snapshotChanges().pipe(
             finalize(() => {
               this.downloadURL = this.ref.getDownloadURL()
               this.downloadURL.subscribe(url =>{
                 (this.image = url)
                 console.log(url)
                 const foodRef = this.afs.doc('users/'+ user.uid);
                 foodRef.update({
               
                  CEForm: url,
                  formSubmitted: true
                 
                 }).then();{
                  const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 4000
                  })
                  Toast.fire({
                    type: 'success',
                    title: 'Form Upload Complete!!  You can find it in your profile..'
                  })
                }
               } );
             
             }),
             
           ).subscribe() 
         
   }
   
   reader.readAsDataURL(file)
 }
}
})

}
certify(){
  const db = firebase.firestore();
  
  this.afAuth.authState.subscribe(user => {
    if(user) {
      let addClass = db.collection('users/').doc(user.uid).update({
        entranceLink: this.str,
        certified: true,
        certifiedAgentId: user.uid
        
      })
  let addDoc = db.collection('certified-agent').doc(user.uid).set({
    uid: user.uid,
    
  })


  }})
}
goToProfile(){
  this.afAuth.authState.subscribe(user => {
    if(user) {
      this.router.navigate(['profile/:'+ user.uid])
    }})
}
startForm(){
  Swal.mixin({
    input: 'text',
    confirmButtonText: 'Next &rarr;',
    showCancelButton: true
    
  }).queue([
    {
      title: 'First Name',
      text: 'As it appears on your Real Estate License'
    },
    {
      title: 'Last Name',
      text: 'As it appears on your Real Estate License'
    },
    {
      title: 'Email Address',
      input: 'email',
      inputPlaceholder: 'Enter your email address'
    },
    {
      title: 'Phone Number'
      
    },
    {
      title: 'These are the States eligible for C.E.',
      text: 'If your are not licensed in one of these States we cannot issue C.E.',
      input: 'select',
      inputOptions: {
//         'Alabama ':'Alabama',
// 'Alaska':'Alaska',
// 'Arizona':'Arizona',
// 'Arkansas':'Arkansas',
// 'California':'California',
// 'Colorado':'Colorado',
// 'Connecticut':'Connecticut',
// 'Delaware':'Delaware',
'Florida':'Florida',
// 'Georgia':'Georgia',
// 'Hawaii':'Hawaii',
// 'Idaho':'Idaho',
// 'Illinois':'Illinois',
// 'Indiana':'Indiana',
// 'Iowa':'Iowa',
// 'Kansas':'Kansas',
// 'Kentucky':'Kentucky',
// 'Louisiana':'Louisiana',
// 'Maine':'Maine',
// 'Maryland':'Maryland',
// 'Massachusetts':'Massachusetts',
// 'Michigan':'Michigan',
// 'Minnesota':'Minnesota',
// 'Mississippi':'Mississippi',
// 'Missouri':'Missouri',
// 'Montana':'Montana',
// 'Nebraska':'Nebraska',
'Nevada':'Nevada',
// 'New Hampshire':'New Hampshire',
// 'New Jersey':'New Jersey',
// 'New Mexico':'New Mexico',
// 'New York':'New York',
// 'North Carolina':'North Carolina',
// 'North Dakota':'North Dakota',
// 'Ohio':'Ohio',
// 'Oklahoma':'Oklahoma',
// 'Oregon':'Oregon',
// 'Pennsylvania':'Pennsylvania',
// 'Rhode Island':'Rhode Island',
// 'South Carolina':'South Carolina',
// 'South Dakota':'South Dakota',
// 'Tennessee':'Tennessee',
'Texas':'Texas',
// 'Utah':'Utah',
// 'Vermont':'Vermont',
// 'Virginia':'Virginia',
// 'Washington':'Washington',
// 'West Virginia':'West Virginia',
// 'Wisconsin':'Wisconsin',
// 'Wyoming':'Wyoming',
        
      },
      inputPlaceholder: 'What State you Are Licensed In?',
      showCancelButton: true,
     
    },
    {
      title: 'State License Number'
      
    }
   
  ]).then((result) => {
    if (result.value) {
      const answers = JSON.stringify(result.value)
      Swal.fire({
        title: 'All done!',
        html: `
          Your Information:
          <pre><code>${answers}</code></pre>
        `,
        confirmButtonText: 'Save Now'
      }).then();{
        this.afAuth.authState.subscribe(user => {
          if (user) {
            this.authService.updateEmail(result.value[2])
      this.userInfo = this.afs.doc('users/'+ user.uid);
      this.userInfoSubmit = this.userInfo.update({
       
          firstName: result.value[0],
          lastName: result.value[1],
          email: result.value[2],
          phone: result.value[3],
          state: result.value[4],
          license: result.value[5],
          class: this.str,
          
      
        CESubmitted: false,
        infoSubmitted: true
      }).then();{
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 4000
        })
        Toast.fire({
          type: 'success',
          title: 'Professional Information Submited!'
        })
      }
    }
  })
}
}
  })
}
  }
  // Colorado
  // Florida
  // Michigan
  // South Carolina
  // Texas
