 
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment'
import * as firebase from 'firebase';
import { firestore } from 'firebase';
import { database } from 'firebase';
import * as Rellax from 'rellax';
import Swal from 'sweetalert2';
// import { CountiesService } from "../shared/services/counties.service";
// import Swal from 'sweetalert2'



@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss']
})
export class OnlineComponent implements OnInit {
  
  pdfSrc: string = '/assets/pdf/Mutual-Of-Omaha-Mortgage-Notes.pdf';
  pdfSrcBuy: string = '/assets/pdf/buyersFAQ.pdf';
  pdfSrcSell: string = '/assets/pdf/sellersFAQ.pdf';
  selectedTabId = 'simple';
  zoom: number = 14;
  lat: number = 44.445248;
  lng: number = 26.099672;
  styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    data : Date = new Date();
    focus;
    focus1;

    items: Observable<{ id: string; }[]>;
    voter: any;
    user: string;
    karma: any;
    karmas: Observable<{}>;
    // karmas: Observable<[]>;
   
   
    finalize: any;
    image: string = null;
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
    downloadURL: Observable<string>;
    uploadState: Observable<string>;
    imageString: string;
    imageUrl: string;
    fileRef: any;
    imageLocation: '';
   backgroundURL:'';
   AvatarURL:'';
  soundsDoc: AngularFirestoreCollection<{}>;
  sounds: Observable<{ id: string; }[]>;
 
  street: any;
  num: any;
  // verifiedDoc: AngularFirestoreCollection<{}>;
  verified: Observable<any>;
  verif: any;
  verifDoc: AngularFirestoreDocument<{}>;
  verifiedDoc: Observable<{}>;
  ownerRef: AngularFirestoreDocument<{}>;
  accNum:'';
  ownerDoc: AngularFirestoreDocument<{}>;
  imageChanged: boolean;
  expanded: boolean;
  db: any;
  database: firebase.firestore.CollectionReference;
  openForm: boolean;
  firstName: '';
  lastName: '';
  accountNumber: '';
  streetNumber: '';
  streetName: '';
  phoneNumber: '';
  open11: boolean;
  open22: boolean;
  open33: boolean;
  open44: boolean;
  open55: boolean;
  open: boolean;
  about: any;
  co: any;
  addressNum: any;
  licenceNum: any;
  firstOpen: boolean;
  lastOpen: boolean;
  companyOpen: boolean;
  addressOpen: boolean;
  bioOpen: boolean;
  licenceOpen: boolean;
  itemDoc2:  AngularFirestoreDocument;

 
  requestDoc: AngularFirestoreCollection<{}>;
  req: Promise<firebase.firestore.DocumentReference>;
  eventsDoc: AngularFirestoreCollection<{}>;
  events: Observable<{ id: string; }[]>;
  del: AngularFirestoreDocument<{}>;
  delete: any;
  itemDoc: Observable<{}>;
  item: any;
  i: any;
  uid: any;
  ifirst: any;
  ilast: any;
  iphone: any;
  iemail: any;
  ilicence: any;
  iaddress: any;
  iphone2: any;
  icompany: any;
  icertified: any;
  ibio: any;
  iabout: any;
  certifiedAgentId: any;
  icertifiedAgentId: any;
  itemDocDup: Observable<{}>;
  itemDup: any;
  dphone: any;
  daddress: any;
  demail: any;
  iinstagram: any;
  itwitter: any;
  ifacebook: any;
  d:any;
  buyers: boolean;
  sellers: boolean;
  cliffs: boolean;
  replyId: string;
  str: any;


  
 

  constructor( public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public databases: AngularFireDatabase, 
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private afa: AngularFireAuth,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, 
    // private countiesService: CountiesService,
   ) { 
this.buyers = false;
this.sellers = false;
this.cliffs = true;
   
        this.afAuth.authState.subscribe(user => {
            if(user) {
           
              this.route.paramMap.subscribe(params => {
                this.replyId = params.get("id")
              })
              this.str = this.replyId.substring(1);
              console.log( this.str);
            console.log(user.uid);

    this.eventsDoc = this.afs.collection('event-requests', ref => ref.where('user', '==', user.uid));
    this.events = this.eventsDoc.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id; 
        return { id, ...data };
      }))
    );
    
              this.user = user.uid;
         
              this.itemDoc = this.afs.doc('users/'+ user.uid).valueChanges();
              this.item = this.itemDoc.subscribe((datas) => { 
                this.d = datas,
                this.i = this.d.photoURL,
                this.ifirst = this.d.firstName,
                this.ilast = this.d.lastName,
                this.iphone = this.d.phone,
                this.iphone2 = this.d.phone
                this.iemail = this.d.email,
                this.ilicence = this.d.licence,
                this.iaddress = this.d.address,
                this.icompany = this.d.company,
                this.icertified = this.d.certified,
                this.uid = this.d.uid,
                this.iabout = this.d.about,
                this.icertifiedAgentId = this.d.certifiedAgentId,
                this.iinstagram = this.d.instagram,
                this.itwitter = this.d.twitter,
                this.ifacebook = this.d.facebook
                
                 console.log("datas", datas) 
               },(err)=>{
                  console.log("probleme : ", err) 
                 });

          }

           
          }) 
        
        
    }
    // getCounties(): void {
    //   this.counties = this.countiesService.getCounties();
    // }
    vote(id, user){
      this.afAuth.authState.subscribe(user => {
        if(user) {
      const db = firebase.firestore();
      const increment = firebase.firestore.FieldValue.increment(1);
      const storyRef = db.collection('event-requests/').doc(id);
      storyRef.update({ voteCount: increment, votedBy: firestore.FieldValue.arrayUnion(user.uid) });
      // const userRef = db.collection('users/').doc(user.uid);
      // userRef.update({karma: increment})
        }})
    }
    ngOnInit() {
      var rellaxHeader = new Rellax('.rellax-header');

        var body = document.getElementsByTagName('body')[0];
        body.classList.add('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('profile-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }
    join(uid){
console.log(uid)
this.router.navigate(['online/:'+ uid]); 
    }
    cliff(){
      this.buyers = false;
this.sellers = false;
this.cliffs = true;
    }
    buy(){
      this.buyers = true;
this.sellers = false;
this.cliffs = false;
    }
    sell(){
      this.buyers = false;
this.sellers = true;
this.cliffs = false;
    }
    deleteEvent(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.del = this.afs.doc('event-requests/'+ id)
          this.delete = this.del.delete()
          Swal.fire(
            'Deleted!',
            'Your event has been deleted.',
            'success'
          )
        }
      })
    }
    async request(){
      
      this.afAuth.authState.subscribe(async user => {
        if(user) {
        const {value: item} = await Swal.fire({
          title: 'Input Your Event Name',
          input: 'text',
          inputPlaceholder: 'Enter your Event Name'
        })
        
        if (item) {
          Swal.fire('Event Name: ' + item)

          this.requestDoc = this.afs.collection('event-requests');
      this.req = this.requestDoc.add({
            eventName: item,
            user: user.uid
          })
        }
      }})
    }
   async edit(id,downloadURL,user){
     console.log(downloadURL)
    this.afAuth.authState.subscribe(async user => {
      if(user) {
     console.log(user.uid);
     const {value: file} = await Swal.fire({
      title: 'Select image',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Upload your profile picture'
      }
    })
    
    if (file) {
      const reader = new FileReader
      reader.onload = (e) => {
        Swal.fire({
          title: 'Your uploaded picture',
          imageUrl: e.target["result"] ,
          imageAlt: 'The uploaded picture',
          
        })
        const id = Math.random().toString(36).substring(2);
              this.ref = this.afStorage.ref(id);
              this.task = this.ref.put(file);
              this.task.snapshotChanges().pipe(
                finalize(() => {
                  this.downloadURL = this.ref.getDownloadURL()
                  this.downloadURL.subscribe(url =>{
                    (this.image = url)
                    console.log(url)
                    const foodRef = this.afs.doc('users/'+ user.uid);
                    foodRef.update({
                  
                     photoURL: url,
                    
                    })
                  } );
                
                }),
                
              ).subscribe() 
            
      }
      
      reader.readAsDataURL(file)
    }
  }
})

   }

        addAvatar(downloadURL, user){
          console.log(downloadURL);
          // this.afAuth.authState.subscribe(user => {
          //   if(user) {
              console.log(user.uid);
const foodRef = this.afs.doc('users/'+ user.uid);
              foodRef.update({
            
               photoURL: downloadURL,
              
              }).then();{
               
                alert('Avatar Added');
                setTimeout(function(){ 
                  location.reload(); 
                }, 1000);
               
                
              }


            // }})
          
              
        
            }
           

        upload(event){
              const id = Math.random().toString(36).substring(2);
              this.ref = this.afStorage.ref(id);
              this.task = this.ref.put(event.target.files[0]);
              this.task.snapshotChanges().pipe(
                finalize(() => {
                  this.downloadURL = this.ref.getDownloadURL()
                  this.downloadURL.subscribe(url => (this.image = url));
                })
              )
              .subscribe();
            }
          async first(user){
            const {value: item} = await Swal.fire({
              title: 'Input Your First Name',
              input: 'text',
              inputPlaceholder: 'Enter your First Name'
            })
            
            if (item) {
              Swal.fire('First Name: ' + item)

              this.itemDoc2 = this.afs.doc('users/'+ user.uid);
              this.itemDoc2.update({
                firstName: item
              })
            }
  
           
           }
           async start(){
            Swal.fire('Looks like you havent set up your account yet! Just click the button below marked "Edit Profile" to add your personal information, and the button marked "Change Photo" to set your profile picture.')
           
           }

           click(id){
            this.router.navigate(['request-event/:'+ id]);
          }

           async last(user){
            const {value: item} = await Swal.fire({
              title: 'Input Your Last Name',
              input: 'text',
              inputPlaceholder: 'Enter your Last Name'
            })
            
            if (item) {
              Swal.fire('Last Name: ' + item)

              this.itemDoc2 = this.afs.doc('users/'+ user.uid);
              this.itemDoc2.update({
                lastName: item
              })
            }
           
           }
            async company(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Company',
                input: 'text',
                inputPlaceholder: 'Enter your Company'
              })
              
              if (item) {
                Swal.fire('Company: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  company: item
                })
              }
            }
            async address(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Address',
                input: 'text',
                inputPlaceholder: 'Enter your Address'
              })
              
              if (item) {
                Swal.fire('Address: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  address: item
                })
              }
            }
           async bio(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Bio',
                input: 'textarea',
                inputPlaceholder: 'Enter your Bio'
              })
              
              if (item) {
                Swal.fire('Bio: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  about: item
                })
              }
            }
            
            async licence(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Licence Number',
                input: 'text',
                inputPlaceholder: 'Enter your Licence Number'
              })
              
              if (item) {
                Swal.fire('Licence Number: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  licence: item
                })
              }
            }

            async phone(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Phone Number',
                input: 'text',
                inputPlaceholder: 'Enter your Phone Number'
              })
              
              if (item) {
                Swal.fire('Phone Number: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                 phone: item
                })
              }
             
             }
             async twitter(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Twitter Profile',
                input: 'textarea',
                inputPlaceholder: 'Enter Your Twitter Profile'
              })
              
              if (item) {
                Swal.fire('Twitter: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  twitter: item
                })
              }
            }
            async instagram(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Instagram Profile',
                input: 'textarea',
                inputPlaceholder: 'Enter Your Instagram Profile'
              })
              
              if (item) {
                Swal.fire('Instagram: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  instagram: item
                })
              }
            }
            async facebook(user){
              const {value: item} = await Swal.fire({
                title: 'Input Your Facebook Profile',
                input: 'textarea',
                inputPlaceholder: 'Enter Your Facebook Profile'
              })
              
              if (item) {
                Swal.fire('Facebook: ' + item)
  
                this.itemDoc2 = this.afs.doc('users/'+ user.uid);
                this.itemDoc2.update({
                  facebook: item
                })
              }
            }

            useLogo(){
              Swal.fire(
                '<h4>Using Your Emblem</h4>',
                '<ul style="text-align:left;"><li>1 - Save your Coin (right click-Save Image As)</li> <li>2 - Upload it to your signature in your email</li><li>3 - Copy your Certified VA Agent link</li><li>4 - Paste it as the link for your signature image </li> <li>5 - Done!</li> </ul>'+
               
                "Don't worry, we will post specific instructions for diffirent email clients in the weeks to come so check back!",
                'success'
              )
            }
            keyword = 'County Name';
            // public counties = [
            //   {
            //     id: 1,
            //     name: 'Albania',
            //   },
            //   {
            //     id: 2,
            //     name: 'Belgium',
            //   }
             
            // ];
            // public counties = this.countiesService.data;
              selectEvent(item) {
              // do something with selected item
            }
          
            onChangeSearch(search: string) {
              // fetch remote data from here
              // And reassign the 'data' which is binded to 'data' property.
            }
          
            onFocused(e) {
              // do something
            }

}