import { Component, OnInit } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth,private router: Router, public authService: AuthService ) { }

  ngOnInit() {
  }
  registerLink(){
    this.router.navigate(['register/:NoClass']);
  }

  login(){
    this.router.navigate(['login/']);
}

create(){
    this.router.navigate(['register/']);
}
async passwordReset(){
  const { value: email } = await Swal.fire({
    title: 'Input your email address',
    input: 'email',
    inputPlaceholder: 'John@Doe.com'
  })
  
  if (email) {
 
    this.reset(email);
  }
}
reset(email){
  var auth = firebase.auth();
  // var emailAddress = user.email;
  console.log(email);

  
  auth.sendPasswordResetEmail(email).then(function() {
    // Email sent.
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    })
    
    Toast.fire({
      type: 'success',
      title: 'Check your inbox, password re-set email sent!'
    })
  }).catch(function(error) {
    // An error happened.
    alert(error);
  });
}
}



